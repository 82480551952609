export default (
  state = {
    component: {
      props: {},
    },
    isMobile: window.matchMedia("(max-width: 600px)").matches,
    language: "/fr",
    modules: [],
    linesModes: [],
    lines: [],
    stops: [],
    areas: [],
    languageFile: {},
    modes: {},
    walkingSpeeds: [],
    calculateItineraryNow: true,
    touchscreenSelected: null,
    isLegendOpen: false,
    heavyIds: [],
    datePicker: {
      minDate: undefined, // KEEP undefined instead of null for react-date-and-time-picker
      maxDate: undefined, // KEEP undefined instead of null for react-date-and-time-picker
      defaultDateTime: undefined, // KEEP undefined instead of null for react-date-and-time-picker
    },
    config: {
      scrollWheelZoom: true,
      dragging: true,
    },
  },
  action
) => {
  switch (action.type) {
    case "INIT_APP":
      return {
        ...state,
        ...action.data,
      };

    case "UPDATE_MEDIA_QUERIES":
      return {
        ...state,
        isMobile: action.isMobile,
      };

    case "SET_MAP":
      return {
        ...state,
        map: action.map,
      };

    case "SET_COMPONENT":
      return {
        ...state,
        component: action.component,
      };

    case "SCROLL_END":
      return {
        ...state,
        top: action.top,
      };

    case "SET_USER_TOKEN":
      return {
        ...state,
        token: action.token,
      };

    case "SET_APP_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };

    case "SET_APP_LOCKER":
      return {
        ...state,
        lock: action.lock === "true",
      };

    case "SET_APP_PANEL_SIZE":
      return {
        ...state,
        size: ["small", "large"].includes(action.size) ? action.size : "large",
      };

    case "INIT_MODES":
      return {
        ...state,
        modes: action.modes,
      };

    case "HANDLE_MODES_CHANGED":
      // si on désélectionne pmr (action.mode.id === "pmr" && state.modes[action.mode.id]), on laisse sélectionné
      const modes = {
        ...state.modes,
        [action.mode.id]: action.mode.id === "pmr" && state.modes[action.mode.id] ? true : !state.modes[action.mode.id],
      };

      let walkingSpeeds = state.walkingSpeeds;

      if (action.mode.id === "pmr") {
        walkingSpeeds = walkingSpeeds.map((walkingSpeed) => {
          return { ...walkingSpeed, value: false };
        });
      }

      return {
        ...state,
        walkingSpeeds: walkingSpeeds,
        modes: modes,
      };

    case "INIT_WALKING_SPEEDS":
      return {
        ...state,
        walkingSpeeds: action.walkingSpeeds,
      };

    case "HANDLE_WALKING_SPEEDS_CHANGED":
      const modesToSet = { ...state.modes };

      if (modesToSet.pmr !== undefined) {
        modesToSet.pmr = false;
      }

      return {
        ...state,
        walkingSpeeds: state.walkingSpeeds.map((walkingSpeed) => {
          if (walkingSpeed.type === action.walkingSpeedType) {
            return { ...walkingSpeed, value: true };
          } else {
            return { ...walkingSpeed, value: false };
          }
        }),
        modes: modesToSet,
      };

    case "INIT_DATEPICKER_MINMAX_DATE":
      return {
        ...state,
        datePicker: {
          minDate: action.minDate,
          maxDate: action.maxDate,
        },
      };

    case "INIT_DATE":
      return {
        ...state,
        selectedDate: action.date,
        selectedTime: action.date,
      };

    case "HANDLE_SELECTED_DATE_CHANGED":
      return {
        ...state,
        selectedDate: action.date,
        calculateItineraryNow: action.now,
      };

    case "HANDLE_SELECTED_TIME_CHANGED":
      return {
        ...state,
        selectedTime: action.date,
        calculateItineraryNow: false,
      };

    case "HANDLE_REPRESENTS_CHANGED":
      const modesRepresentChanged = {
        ...state.modes,
        represents: action.represent,
      };

      return {
        ...state,
        modes: modesRepresentChanged,
      };

    case "SET_APP_HASH":
      return {
        ...state,
        hash: action.hash,
      };

    case "SET_APP_HEADER":
      return {
        ...state,
        header: action.header,
      };

    case "SET_CONFIG":
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config,
        },
      };

    case "SET_PROJECTS_MENU_ID":
      return {
        ...state,
        menuID: action.menuID,
      };

    default:
      return state;
  }
};
