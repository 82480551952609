/**
 * Comparator to use on a sort Array function
 * Ex : array.sort(compareValues('key', 'desc'))
 *
 * @param {String} key props to sort by
 * @param {String} order 'asc' or 'desc'
 */
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];
    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
}

/**
 * Split lines in groups of modes
 *
 * @param {Array} lines lines to group
 * @param {Array} types modes
 * @param {String} target property in line used to group
 * @param {Object} filter filter the mode Array created { key: "KEY_TO_FILTER", value: VALUE_TO_USE }
 */
export function groupLinesByMode(lines, modes, target = "mode", filter = null) {
  const groups = {};

  for (const mode of modes) {
    let linesInMode = lines.filter((l) =>
      Array.isArray(mode.modes)
        ? mode.modes.includes(l[target])
        : typeof l[target] === "string"
        ? l[target].includes(mode.modes)
        : mode.modes === l[target]
    );

    // Filter only the value from the specified key
    if (filter) {
      linesInMode = linesInMode.filter((line) => {
        if (typeof line[filter.key] === "string") {
          return line[filter.key].includes(filter.value);
        } else if (typeof line[filter.key] === "number") {
          return line[filter.key] === filter.value;
        } else {
          return line;
        }
      });
    }

    // We only keep groups with lines
    if (linesInMode.length > 0) {
      groups[mode.name] = linesInMode;
    }
  }

  // if we have group to concat
  for (const mode of modes) {
    if (mode.concat_with) {
      groups[mode.concat_with] = groups[mode.concat_with].concat(groups[mode.name]);
      delete groups[mode.name];
      groups[mode.concat_with].sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  }

  return groups;
}

/**
 * Return current Board bounding client rect
 */
export function getBoardBoundingClientRect() {
  return document.querySelector("[data-lc-board]").getBoundingClientRect();
}

export function navitiaDateToDate(navitiadate, seconds = false) {
  return new Date(
    navitiadate.substring(0, 4) +
      "-" +
      navitiadate.substring(4, 6) +
      "-" +
      navitiadate.substring(6, 8) +
      "T" +
      navitiadate.substring(9, 11) +
      ":" +
      navitiadate.substring(11, 13) +
      ":" +
      (seconds ? navitiadate.substring(13, 15) : "00")
  );
}
