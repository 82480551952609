export default (
  state = {
    networkMapRef: null,
    withSlider: false,
    slider: true,
    heavyLines: [],
    networkTownsLines: [],
    modeNetworkLinesAccordeon: false,
    modesGroupPanel: {},
    sliderUrls: {
      left: "before",
      right: "after",
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_NETWORK_SLIDER_MODE":
      return {
        ...state,
        withSlider: action.slider,
      };

    case "SET_NETWORK_MAP_REF":
      return {
        ...state,
        networkMapRef: action.mapRef,
      };

    case "SET_NETWORK_MAP_HEAVY_LINES":
      return {
        ...state,
        heavyLines: action.lines,
      };

    case "SET_NETWORK_TOWNS_LINES":
      return {
        ...state,
        networkTownsLines: action.lines,
      };

    case "SET_NETWORK_SELECTED_LINE":
      return {
        ...state,
        line: action.line,
      };

    case "SET_NETWORK_SELECTED_TOWN":
      return {
        ...state,
        town: action.town,
      };

    case "SET_NETWORK_DISPLAY_SLIDER":
      return {
        ...state,
        slider: action.slider,
      };

    case "SET_MODES_GROUP_PANEL":
      let mgp = state.modesGroupPanel; // {...state.modesGroupPanel} fonctionne pas

      if (state.modeNetworkLinesAccordeon && action.open) {
        Object.keys(state.modesGroupPanel).map((k) => (mgp[k] = false));
      }

      mgp[action.mode] = action.open;

      return {
        ...state,
        modesGroupPanel: mgp,
      };

    case "SET_MODE_LINES_ACCORDEON":
      return {
        ...state,
        modeNetworkLinesAccordeon: action.mode,
      };

    case "SET_SLIDER_URL_LEFT":
      return {
        ...state,
        sliderUrls: {
          ...state.sliderUrls,
          left: action.url,
        },
      };

    case "SET_SLIDER_URL_RIGHT":
      return {
        ...state,
        sliderUrls: {
          ...state.sliderUrls,
          right: action.url,
        },
      };

    default:
      return state;
  }
};
