import Tippy from "@tippy.js/react";
import axios from "../middlewares/axios";
import L from "leaflet";
import { luminance } from "luminance-js";
import React from "react";
import { GeoJSON, Marker, Popup, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { actionSetOpenedCollapse } from "../actions/board";
import {
  actionMarkerClick,
  actionSetBikePaths,
  actionSetCluster,
  actionSetEntranceMapMarkers,
  actionSetEntrancePopup,
  actionSetHeavyLines,
  actionSetCustomLines,
  actionSetMapBikes,
  actionSetCustomMarkerEvent,
} from "../actions/map";
import {
  actionBuildMapPlaces,
  actionBuildTransportPlaces,
  actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
} from "../actions/withRedux";
import history from "../history";
import BikeInterface from "../interfaces/BikeInterface";
import { appStore } from "../store";
import { groupLinesByMode } from "../utils/leaflet/tools";
import {
  buildPlaceIconClassName,
  clickOnPlaceInList,
  envVarToBool,
  flattenObject,
  getLine,
  getRef,
  getURLSearchParams,
  goToRouteCalculation,
  isNotToClusterised,
  isThematics,
  mostImportantGroup,
  unique,
  updatePopupPosition,
  assetsPath,
  handleKeyPress,
  addGetParam,
  translate,
} from "./tools";
import { message } from "./message";

const {
  REACT_APP_LINES_MAIN_TYPE,
  REACT_APP_LINES_TYPE_EXCEPTIONS,
  REACT_APP_SHOW_PMR,
  REACT_APP_SHOW_ADDITIONAL_STOP_TOOL,
  REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION,
  REACT_APP_CONNECTIONS_TEXT,
} = process.env;

export const buildBikePaths = async (files) => {
  const requests = [];

  for (const file of files) {
    const options = {
      color: file.style.color,
      opacity: 1,
      weight: file.style.size,
      dashArray: file.style.dashArray,
      lineJoin: "round",
    };

    requests.push(
      axios.get(`/api/file?name=${file.id}&folder=${"map/bike"}&ext=geojson`).then((response) => {
        return <GeoJSON interactive={false} key={file.id} data={response.data} style={options} />;
      })
    );
  }

  Promise.all(requests).then((paths) => {
    appStore.dispatch(actionSetBikePaths(paths));
  });
};

export const buildEntranceMap = (geojson, map) => {
  if (!geojson || !Object.keys(geojson).length) {
    message({ error: "geojson_entrance_map_not_found" });
    return;
  }

  const markers = [];

  if (map.mapReference.current) {
    map = map.mapReference.current.leafletElement;
  }

  for (const feature of geojson.features) {
    const isTerminus = feature.properties.type === "terminus";

    // TEMPORARY UNTIL TERMINUS ARE FINISHED !
    if (isTerminus) {
      continue;
    }
    // END TEMPORARY

    if (feature.geometry.type.includes("String")) {
      markers.push(
        <GeoJSON
          key={Math.random()}
          data={feature}
          style={{
            opacity: 0,
            weight: 18,
          }}
          onMouseMove={(e) => {
            const storedPopup = appStore.getState().map.entrancePopup;
            const popup = e.target.getPopup();

            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              popup.setLatLng(e.latlng).openOn(map);
            }
          }}
          onMouseOut={(e) => {
            const storedPopup = appStore.getState().map.entrancePopup;
            const popup = e.target.getPopup();

            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              e.target.closePopup();
            }
          }}
          onClick={(e) => {
            map.eachLayer((layer) => layer.closePopup());

            const popup = e.target.getPopup();

            appStore.dispatch(actionSetEntrancePopup(popup));
            popup.setLatLng(e.latlng).openOn(map);
          }}
        >
          <Popup
            className={"lc-popup-leaflet"}
            closeButton={false}
            autoClose={false}
            autoPan={false}
            onClose={() => appStore.dispatch(actionSetEntrancePopup(null))}
          >
            {buildPopup(
              appStore.getState(),
              {
                lines: feature.properties.desserte.split(";").map((line) => ({
                  code: line.split("_")[0],
                  network: line.split("_")[1],
                })),
              },
              true
            )}
          </Popup>
        </GeoJSON>
      );
      markers.push(
        <GeoJSON
          key={Math.random()}
          style={{
            color: "#888",
          }}
          data={feature}
          interactive={false}
        />
      );
    } else {
      markers.push(
        <Marker
          key={(isTerminus ? "terminus-" : "jalon-") + Math.random()}
          interactive={false}
          position={[feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}
          icon={L.icon({
            iconUrl: assetsPath("/assets/images/lines/entrance/") + feature.properties.image + ".svg",
            iconSize: feature.properties.size,
            iconAnchor: feature.properties.anchor,
          })}
          zIndexOffset={isTerminus ? 100 : 50}
          zoom={feature.properties.zoom}
        />
      );
    }
  }

  setTimeout(() => appStore.dispatch(actionSetEntranceMapMarkers(markers)));
};

/**
 * Build & render heavy lines
 * @param state
 */
export const buildHeavyLines = (state) => {
  if (state.map.heavyLines) {
    return;
  }

  const { heavyIds, lines, hash } = state.app;
  const { pathname } = history.location;

  if (!pathname.includes("route-calculation")) {
    const requests = [];

    for (const id of heavyIds) {
      const data = getLine(lines, {
        id,
        direction_id: "f",
      });

      if (data.code) {
        // TODO recode displayLinePath
        requests.push(buildLinePath(data, hash));
      }
    }

    Promise.all(requests).then((polylines) => {
      appStore.dispatch(actionSetHeavyLines(polylines));
    });
  }
};

export function buildCustomLines(state, customMapLines) {
  const { lines, hash } = state.app;
  const requests = [];

  for (const customLine of customMapLines) {
    const data = getLine(lines, {
      customLine,
      direction_id: "f",
    });

    if (data.code) {
      // TODO recode displayLinePath
      requests.push(buildLinePath(data, hash));
    } else {
      message({ error: "custom_line_not_found", id: customLine, message: "Custom line is not found in line list" });
    }
  }

  Promise.all(requests).then((polylines) => {
    appStore.dispatch(actionSetCustomLines(polylines));
  });
}

export const buildLinePath = (data, hash) => {
  // Force direction_id to 'f' by default if not exists
  if (!data.direction_id) {
    data.direction_id = "f";
  }

  const folder = data.type ? (+data.type !== 4 ? "routes/future/lines" : "routes/current/lines") : "routes";

  const name = data.type
    ? `${data.code}~${hash}`
    : `${encodeURIComponent(data.code)}_${data.network}_${data.direction_id}~${hash}`;

  return axios
    .get(`/api/file?folder=${folder}&ext=geojson&name=${name}`)
    .then((response) => {
      const geojson = response.data.length === 0 ? null : response.data;

      const options = {
        color: "#" + data.color,
        opacity: 1,
        weight: 6,
        zIndex: 7,
      };

      if (geojson) {
        if (data.tad && data.tad.zone) {
          // Navitia can't integrate Polygon type... so MultiLineString became a Polygon :D
          geojson.features[0].geometry.type = "Polygon";
          options.weight = 2;
          options.fillColor = "#" + data.color;
          options.fillOpacity = 0.2;
        }

        return (
          <GeoJSON
            interactive={false}
            key={`${data.code}_${data.network}_${data.direction_id}`}
            data={geojson}
            style={options}
          />
        );
      } else {
        message({
          error: "geojson_line_not_found",
          id: data.code,
          message: "Line has no geojson",
        });
      }
    })
    .catch((e) => {
      const error = e.response && e.response.data ? e.response.data.id : e;

      console.warn(error);
    });
};

export const buildCustomMarkers = (customMarkers) => {
  const markers = [];

  try {
    for (const m of customMarkers) {
      if (m.id === undefined) {
        console.warn("Custom marker has no id");
        message({ error: "custom_marker_no_id", message: "Custom marker must have an id" });
      } else {
        markers.push(
          <Marker
            key={"customMarker-" + m.id}
            name={m.title}
            position={m.position}
            icon={L.icon({
              iconUrl: m.icon.url,
              iconSize: m.icon.size || [26, 26],
              iconAnchor: m.icon.anchor || [13, 13],
              className: m.icon.className,
            })}
            zIndexOffset={m.zIndexOffset}
            onMouseOver={(e) => {
              if (!e.target.isPopupOpen()) {
                e.target.openPopup();
                setTimeout(() => {
                  updatePopupPosition(e.target);
                });
                appStore.dispatch(actionSetCustomMarkerEvent(m.id, "mouseover"));
              }
            }}
            onMouseOut={(e) => {
              if (
                appStore.getState().map.customMarkers.find((mf) => mf.key === "customMarker-" + m.id)?.lastEvent ===
                "mouseover"
              ) {
                e.target.closePopup();
                appStore.dispatch(actionSetCustomMarkerEvent(m.id, ""));
              }
            }}
            onClick={(e) => {
              if (
                appStore.getState().map.customMarkers.find((mf) => mf.key === "customMarker-" + m.id)?.lastEvent ===
                "mouseover"
              ) {
                message({ clicked: "custom-marker", id: m.id });
                appStore.dispatch(actionSetCustomMarkerEvent(m.id, "click"));
                setTimeout(() => {
                  e.target.openPopup();
                });
              } else {
                appStore.dispatch(actionSetCustomMarkerEvent(m.id, ""));
              }
            }}
          >
            <Popup
              className={"lc-popup-leaflet"}
              closeButton={false}
              autoClose={false}
              autoPan={false}
              keepInView={true}
            >
              <div className="lc-infobox">
                <div className="lc-infobox-title">{m.title}</div>
                <div className="lc-infobox-content" dangerouslySetInnerHTML={{ __html: m.description }} />
              </div>
            </Popup>
          </Marker>
        );
      }
    }
  } catch (e) {
    console.warn("Something went wrong while building custom markers");
    throw e;
  }

  return markers;
};

export const buildMapBikes = (state, bikes) => {
  const markers = [];

  for (const bike of bikes) {
    markers.push(
      buildMarker(state, bike, {
        icon: L.icon({
          iconUrl: assetsPath("/assets/images/menu/velo.svg"),
          className: buildPlaceIconClassName(bike),
        }),
        bike,
        zIndexOffset: 200,
      })
    );
  }

  appStore.dispatch(actionSetMapBikes(markers));
};

/**
 * Build a marker component from the given data
 * @param state
 * @param data
 * @param options
 * @returns Marker
 */
export const buildMarker = (state, data, options) => {
  return (
    <Marker
      key={data.id}
      ref={(ref) => {
        data.ref = ref;
        // appStore.dispatch(actionAddReduxRef(ref))
      }}
      name={data.name}
      onMouseOver={() => appStore.dispatch(actionOverMarker(data))}
      onMouseOut={(e) => {
        const target = e.originalEvent.target;

        if (!target.classList.contains("leaflet-tooltip")) {
          setTimeout(() => appStore.dispatch(actionOutMarker(data)));
        }
      }}
      onClick={() => {
        message({ clicked: "marker", id: data.id });
        appStore.dispatch(actionOpenMarker(data));
      }}
      position={[+data.coord.lat, +data.coord.lon]}
      {...options}
    >
      {options.terminus && data.terminus && (
        <Tooltip
          key={"terminus_" + data.id}
          direction={"right"}
          onClick={() => appStore.dispatch(actionOpenMarker(data, true))}
          className={"lc-tooltip-leaflet-terminus"}
          opacity={1}
          interactive
          permanent
        >
          {data.name}
        </Tooltip>
      )}
      <Popup
        className={"lc-popup-leaflet"}
        closeButton={false}
        autoClose={false}
        autoPan={false}
        onClose={() => {
          if (data.stand && !isThematics() && data.cat_id !== "poi_type:amenity:park_ride") {
            // ! Avoid loop on "covoiturage" for Artis. data.stand must be dynamic
            appStore.dispatch(actionBuildTransportPlaces(state.app.component.state.pois));
          }
        }}
      >
        {buildPopup(state, data)}
      </Popup>
    </Marker>
  );
};

/**
 * Build all places markers
 * @param state
 * @param places
 * @returns {Array}
 */
export const buildPlaces = (state, places) => {
  if (!places) {
    return;
  }

  const flattenPlaces = Array.isArray(places) ? places : flattenObject(places);
  const markers = [];

  for (const place of flattenPlaces) {
    if (place.coord) {
      markers.push(
        buildMarker(state, place, {
          icon: L.icon({
            iconUrl: place.code
              ? assetsPath(`/assets/images/places/${place.code}.svg`)
              : assetsPath("/assets/images/stop_point.svg"),
            className: buildPlaceIconClassName(place.cat_id),
          }),
          place,
          zIndexOffset: isThematics() ? 200 : 40,
        })
      );
    }
  }

  return markers;
};

/**
 * Triggered when a line is selected.
 * The behavior can be different on each modules
 * @param state
 * @param line
 * @param data
 */
export const onLineSelected = (state, line, data) => {
  const { pathname } = history.location;
  const params = getURLSearchParams(history.location);

  if (pathname.includes("around")) {
    const part =
      `line=${line.id}_${line.direction_id || "f"}` +
      (data ? `&stop=${data.id}` : "") +
      (params.date ? `&date=${params.date}` : "");

    // TODO ! Remove insee from URL and use from
    if (params && (params.from || params.insee || params.place)) {
      history.push({
        pathname,
        search:
          (params.from ? "?from=" + params.from : params.place ? "?place=" + params.place : "?insee=" + params.insee) +
          "&" +
          part,
      });
    } else {
      history.push({
        pathname,
        search: "?" + part,
      });
    }
  } else {
    if (!data || !data.id) {
      // ! Do not comment this : needed to switch direction on Lines component
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}` });

      history.push({
        pathname: "/lines",
        search: searchParam,
      });
    } else {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}`, stop: data.id });

      history.push({
        pathname: "/lines",
        search: searchParam,
      });
    }
  }
};

/**
 * Triggered when user mouse leaves a marker
 * Retrieve the current openedMarker & test if its not equal to the given one.
 * If false, close the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOut = (state, data) => {
  const { openedMarker, reduxMarkers } = state.map;

  if (!openedMarker || (openedMarker && openedMarker.id !== data.id)) {
    const ref = getRef(data, reduxMarkers);

    if (ref) {
      // Force close popup
      ref.leafletElement.closePopup();
    }
  }
};

/**
 * Triggered when user mouse enter on a marker
 * Retrieve the current openedMarker & test if its not equal to the given one or, if openedMarker is defined, if its popup is closed.
 * If one is true, open the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOver = (state, data) => {
  const { openedMarker, reduxMarkers } = state.map;

  if (openedMarker !== data || (openedMarker && openedMarker.ref && !openedMarker.ref.leafletElement.isPopupOpen())) {
    const ref = getRef(data, reduxMarkers);

    if (ref) {
      // Open popup (delays it a bit to avoid position problem)
      const element = ref.leafletElement;

      setTimeout(() => {
        element.openPopup();
        updatePopupPosition(element);
      });
    }
  }
};

/**
 * Open the popup of the given marker
 * @param state
 * @param data
 */
export const onOpenMarker = (state, data) => {
  const { pathname, search } = history.location;
  const { token, component, linesModes, map: reactMap } = state.app;
  const { openedCollapse, thematicPlaces } = state.board;
  const { cluster, mapPlaces } = state.map;
  const { pois, groups, tab } = component.state;
  const params = getURLSearchParams(history.location);
  const map = reactMap.mapReference.current.leafletElement;

  !pathname.includes("/lines") &&
    data.cat_id &&
    openedCollapse !== data.cat_id &&
    appStore.dispatch(actionSetOpenedCollapse(data.cat_id));

  if (data.cat_id) {
    if (state.board.thematicPlaces) {
      history.push({
        pathname,
        search: "?place=" + data.id,
      });

      appStore.dispatch(actionMarkerClick(data));
    } else if (!pois || pois.length === 0) {
      if (data instanceof BikeInterface && pathname.includes("/bike")) {
        if (search !== "?id=" + data.id) {
          history.push({
            ...history.location,
            search: "?id=" + data.id,
          });
        }
      } else {
        const needRequest = [
          "poi_type:amenity:bicycle_rental",
          "poi_type:amenity:bicycle_parking",
          "poi_type:amenity:parking",
        ];

        const places = mapPlaces.map((place) => place.props.place);
        const place = places.find((place) => place.id === data.id);

        if (place && needRequest.includes(place.cat_id)) {
          const type = place.cat_id.includes("bicycle_rental")
            ? "bss"
            : place.cat_id.includes("bicycle_parking")
            ? "bike_parking"
            : "parking";

          axios
            .get(`/api/availability?type=${type}&id=${place.id}`)
            .then((result) => {
              place.stand = result.data;
              appStore.dispatch(actionBuildMapPlaces(places));
            })
            .catch((e) => {
              const error = e.response && e.response.data ? e.response.data.id : e;

              console.warn(error);
            });
        }
      }
    } else {
      clickOnPlaceInList(data, token, pois, thematicPlaces);
    }
  } else if (state.board.thematicPlaces) {
    history.push({
      pathname,
      search:
        "?place=" + data.id + (params.line ? "&line=" + params.line + (params.stop ? "&stop=" + params.stop : "") : ""),
    });
    appStore.dispatch(actionMarkerClick(data));
  }

  if (!data.ref) {
    data.ref = getRef(data, state.map.reduxMarkers);
  }

  // Open popup, even if it's in a cluster
  if (cluster && data.ref && cluster.hasLayer(data.ref.leafletElement)) {
    const element = data.ref.leafletElement;
    const visible = cluster.getVisibleParent(element);

    // If we are on a cluster, let's zoom in
    if (visible.options.pane === "cluster-pane") {
      cluster.zoomToShowLayer(element, () => {
        element.openPopup();
        updatePopupPosition(element);
      });
    } else {
      map.setView(element.getLatLng(), map.getZoom());
      element.openPopup();
      updatePopupPosition(element);
    }
  } else if (data.ref) {
    const element = data.ref.leafletElement;

    setTimeout(() => {
      element.openPopup();
      updatePopupPosition(element);
    });
  }

  if (data.lines && groups) {
    const mainGroup = mostImportantGroup(groups, linesModes);

    if (mainGroup !== openedCollapse && tab === 0) {
      appStore.dispatch(actionSetOpenedCollapse(mainGroup));
    }
  }
};

/**
 * Render all map places at a minimum zoom level of 16, such as TCL places, Vélo'v & SNCF stations
 * @param mapReference
 * @param places
 * @returns {*[]}
 */
export const renderMapPlaces = (mapReference, places) => {
  const map = mapReference && mapReference.current && mapReference.current.leafletElement;

  if (!history.location.pathname.includes("route-calculation") && map && map.getZoom() > 15 && places) {
    return [
      <MarkerClusterGroup
        key="map-places"
        ref={(ref) => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
        removeOutsideVisibleBounds
        showCoverageOnHover={false}
        iconCreateFunction={(cluster) => {
          return L.divIcon({
            html: cluster.getChildCount(),
            className: "lc-cluster",
          });
        }}
      >
        {places.filter((place) => !isNotToClusterised(place.props.place))}
      </MarkerClusterGroup>,
      places.filter((place) => isNotToClusterised(place.props.place)),
    ];
  }
};

/**
 * Build the popup content of a Marker
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopup = (state, data) => {
  const { lock, modules, servicesStations, lines, component } = state.app;
  const { options } = component.props;
  const params = getURLSearchParams(history.location);
  const servicesAtStation = [];
  const showGoToRC = options?.features?.["route-calculation"] === false ? false : true;

  if (servicesStations) {
    const servicesList = servicesStations.find((s) => s.id === data.id);

    if (servicesList && servicesList.services) {
      Object.keys(servicesList.services).map((serviceType) => {
        return servicesAtStation.push({
          id: servicesList.services[serviceType][0].code,
          name: serviceType,
        });
      });
    }
  }

  return (
    <div
      className={"lc-infobox" + (data.id ? "" : " lc-no-arrow")}
      onMouseLeave={() => appStore.dispatch(actionOutMarker(data))}
      onClick={() => data.name && appStore.dispatch(actionOpenMarker(data))} // Avoid crash if there is no "real" data like sncf-ter entrance map popups
      onKeyPress={(e) => handleKeyPress(e, () => data.name && appStore.dispatch(actionOpenMarker(data)))}
      role="button"
      tabIndex="0"
    >
      {data.name && (
        <div className="lc-infobox-title">
          {data.name}
          <div className={"lc-infobox-title-tools" + (data.pmr && REACT_APP_SHOW_PMR ? " lc-with-pmr" : "")}>
            {REACT_APP_SHOW_PMR && data.pmr
              ? REACT_APP_SHOW_PMR === "pmr" && <div className="lc-is-pmr" />
              : REACT_APP_SHOW_PMR === "no-pmr" && <div className="lc-is-no-pmr" />}
            {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
              JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
                if (data[tool] === true) {
                  return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />;
                } else {
                  return false;
                }
              })}
            {modules.find((m) => m.id === "route-calculation") && !lock && showGoToRC && (
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"right"}
                boundary="window"
                content={translate("title-go-to-route-calculation")}
              >
                <div
                  className="lc-tool-route-calculation lc-toolSmall"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToRouteCalculation(data);
                  }}
                  onKeyPress={(e) =>
                    handleKeyPress(e, () => {
                      goToRouteCalculation(data);
                    })
                  }
                  role="button"
                  tabIndex="0"
                />
              </Tippy>
            )}
          </div>
        </div>
      )}
      {data.severity && ["blocking", "delays"].includes(data.severity) && (
        <div className={"lc-severity lc-" + data.severity}>
          <div className="lc-disruptionSeverity lc-white">
            <div className="lc-icon" />
          </div>
          {data.severity === "blocking"
            ? `${translate("severity-blocking-stop")} ${
                getLine(lines, {
                  id: params.current.substring(0, params.current.lastIndexOf("_")),
                }).code
              }`
            : translate("severity-delays-stop")}
        </div>
      )}
      {servicesAtStation.length > 0 && (
        <div className="lc-infobox-services-station">
          <span>{translate("infobox-services-title")}</span>
          <div className="lc-services-list">
            {servicesAtStation.map((service) => {
              return (
                <Tippy
                  key={service.id}
                  theme={"latitude"}
                  touch={["hold", 500]}
                  delay={[15, 0]}
                  placement={"right"}
                  boundary="window"
                  content={translate(service.name)}
                >
                  <img src={assetsPath("/assets/images/places/" + service.id + ".svg")} alt={service.name} />
                </Tippy>
              );
            })}
          </div>
        </div>
      )}
      <div className="lc-infobox-content">
        {data.cat_id || data instanceof BikeInterface
          ? buildPopupContent(state, data)
          : buildLinesLabels(state, data, "infobox")}
      </div>
    </div>
  );
};

/**
 * Display popup content for places with a cat_id
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopupContent = (state, data) => {
  return (
    <div className="lc-place">
      {
        {
          "poi_type:amenity:bicycle_rental": (data.stand || data instanceof BikeInterface) && (
            <div className="lc-bss">
              <span className="lc-bikes">
                {data.stand ? data.stand.available_bikes : data.availablePlaces}
                <img src={assetsPath("/assets/images/modes/bss.svg")} alt={translate("bss-bikes-available")} />
              </span>
              <span className="lc-seats">
                {data.stand ? data.stand.available_places : data.capacity}
                <img src={assetsPath("/assets/images/bss-seat.svg")} alt={translate("available-places")} />
              </span>
            </div>
          ),
          "poi_type:amenity:parking": data.stand && (
            <div className="lc-place-infos" data-lc-place-infos>
              {data.stand.available && data.stand.available !== 0 ? (
                <>
                  <span className="lc-realtime-seats">
                    {data.stand.available} {translate("available-places")}{" "}
                    <Tippy
                      theme={"latitude"}
                      touch={["hold", 500]}
                      placement={"right"}
                      boundary="window"
                      content={translate("realtime-gif-title")}
                    >
                      <img src={assetsPath("/assets/images/realtime.gif")} alt={translate("realtime-gif-alt")} />
                    </Tippy>
                  </span>
                </>
              ) : data.stand.available === 0 ? (
                <span className="lc-realtime-seats">{translate("no-available-places")}</span>
              ) : (
                <span className="lc-realtime-seats">{translate("no-informations-for-available-places")}</span>
              )}
            </div>
          ),
          "poi_type:amenity:park_ride": data.stand && (
            <div className="lc-place-infos" data-lc-place-infos>
              <span className="lc-parcs">
                {data.stand.total_places} {translate("total-places")}{" "}
                {data.stand.occupied_PRM ? "- " + data.stand.occupied_PRM + " " + translate("disabled-spaces") : ""}
              </span>
            </div>
          ),
        }[data.cat_id]
      }
      {data.cat_id !== "poi_type:TCL:BET" && (!data.info || data.cat_id !== "poi_type:amenity:parking") && (
        <div>
          {data.address} <br /> {data.cp} {data.city}
        </div>
      )}
      {(data.cat_id === "poi_type:TCL:AGE" ||
        data.cat_id === "poi_type:TCL:BET" ||
        data.cat_id === "poi_type:TCL:RIS" ||
        data.cat_id === "poi_type:amenity:parking") && <div className="lc-info">{data.info}</div>}
    </div>
  );
};

/**
 * Build lines labels in infobox / board
 * TODO : recode board side
 * @param state
 * @param data
 * @param key
 * @returns HTMLElement
 */
export const buildLinesLabels = (state, data, key) => {
  let lines = data.lines;
  const { lock, linesModes, size, component } = state.app;
  const { options } = component.props;
  const canChangeLine = options?.features?.["change-line"] === false ? false : true;

  // Avoid undefined lines...
  if (!lines) {
    // TODO Add custom info, like addresses for POI (https://latitude-cartagene.atlassian.net/browse/TCL-224)
    return null;
  }

  // SNCF ??
  lines = lines.map((line) => getLine(state.app.lines, line));
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";
  const canClickLine = !lock && canChangeLine;

  const handleClickLine = (e, line, data) => {
    e.stopPropagation();

    if (!lock && canChangeLine) {
      message({ clicked: "line", id: line.id });
      // ! TODO DON'T GO IN LINES TAB
      appStore.dispatch(
        actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : data)
      );
    }
  };

  const div = (lines) => (
    <div
      key={key + Math.random()}
      className={
        (key === "infobox" ? "lc-infobox-" : "lc-") +
        "lines lc-" +
        size +
        (styleLine.includes("WithDirection") ? " lc-line-with-direction" : "")
      }
    >
      {Object.keys(lines).map((m) =>
        unique(lines[m], "id").map((line) => {
          // Retrieve the global line
          line = getLine(state.app.lines, line);

          if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
            const foundExceptedLine = exceptions.find((e) => e.lines.includes(line.id));

            if (foundExceptedLine) {
              styleLine = foundExceptedLine.type;
            }
          }

          switch (styleLine) {
            case "modeWithDirection":
              const lineMode = linesModes.find((mode) => mode.modes.includes(line.mode));

              return (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                >
                  <div
                    className="lc-line lc-mode"
                    style={{
                      background: "#" + line.color,
                      color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {lineMode.name}
                  </div>
                  <div className="lc-name">{line.name}</div>
                </div>
              );
            case "codeWithDirection":
              return (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                >
                  <div
                    className="lc-line lc-code"
                    style={{
                      background: "#" + line.color,
                      color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {line.code}
                  </div>
                  <div className="lc-name">{line.name}</div>
                </div>
              );
            case "image":
              return (
                <div
                  className="lc-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                >
                  <img src={assetsPath("/assets/images/lines/" + line.code + ".svg")} alt={line.code} />
                </div>
              );
            case "color":
              return (
                <div
                  key={line.id}
                  className="lc-line"
                  onClick={(e) => handleClickLine(e, line, data)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                >
                  <div className="lc-tools-at-line">
                    {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
                      JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
                        if (data[tool] && data[tool].length && data[tool].includes(`${line.code}_${line.network}`)) {
                          return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />;
                        } else {
                          return false;
                        }
                      })}
                  </div>
                  <div
                    className="lc-line-code"
                    style={{
                      background: "#" + line.color,
                      color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {line.code}
                  </div>
                </div>
              );
            default:
              return "";
          }
        })
      )}
    </div>
  );

  return (
    <>
      {envVarToBool(REACT_APP_CONNECTIONS_TEXT) && (
        <div className="lc-connections-at-stop">{translate("connections-at-stop")}</div>
      )}
      {div(groupLinesByMode(lines, linesModes))}
    </>
  );
};
