import React from "react";
import styled from "styled-components";
import { useMedia } from "../../hooks/useMedia";
import { primarycolor } from "../../scss/app.scss";

const InformationContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  font-size: 1.25em;
  padding: 10px;
  border-radius: 4px;
  background: ${primarycolor};
  color: white;
  text-align: center;
  width: 70%;

  ul {
    margin: 0;
    padding-left: 20px;
  }

  @media (max-width: 600px) {
    font-size: 1em;
    top: 10px;
    left: 10px;
    right: 45px;
    transform: initial;
  }
`;

const Message = (props) => {
  return <div dangerouslySetInnerHTML={{ __html: props.message }} />;
};

export function Information(props) {
  const { message } = props;
  const isDesktop = useMedia();

  return (
    <InformationContainer isDesktop={isDesktop}>
      <Message message={message} />
    </InformationContainer>
  );
}
