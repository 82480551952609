import L from "leaflet";
import React from "react";
import { GeoJSON, Tooltip } from "react-leaflet";
import styled from "styled-components";
import { useSelector } from "react-redux";

const TooltipWrapper = styled(Tooltip)`
  background: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: ${(props) =>
    props.line.text ? "#" + props.line.text : (props.term || (!props.label && !props.pr)) && "white"};
  padding: ${(props) => (props.label && !props.term ? "0 3px" : "3px")};
  font-size: ${(props) => (props.label && !props.term ? "0.9em" : props.term && "1.1em")};
  font-weight: ${(props) => props.term && "bold"};
  z-index: ${(props) => props.term && "-1"};

  &.leaflet-tooltip-left {
    &:before {
      border-left-color: ${(props) => props.color};
    }
  }
  &.leaflet-tooltip-right {
    &:before {
      border-right-color: ${(props) => props.color};
    }
  }
`;

const StyledTooltip = (props) => {
  const { children, current, noFuture, line, data } = props;
  const { term, label, "p+r": pr } = data.properties;
  const { linesModesNetwork } = useSelector((state) => state.app);
  const lineMode = linesModesNetwork.find((mode) => mode.modes === line.type);
  const modeName = lineMode.show_like ? lineMode.show_like : lineMode.name;

  const color =
    (current && !noFuture) || modeName === "deleted" ? "#888" : (label && !term) || pr ? "white" : "#" + line.color;

  return (
    <TooltipWrapper
      {...props}
      color={color}
      term={term}
      label={label}
      pr={pr}
      direction="right"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export const Stop = (props) => {
  const { data, current, noFuture, line } = props;
  const { term, anchor, name, label, "p+r": pr, places } = data.properties;
  const { linesModesNetwork } = useSelector((state) => state.app);
  const modeName = linesModesNetwork.find((mode) => mode.modes === line.type).name;

  const pointToLayer = (_feature, latlng) => {
    return pr
      ? new L.marker(latlng, {
          icon: L.icon({
            iconUrl: "assets/images/p+r.svg",
            iconSize: [30, 30],
            iconAnchor: [15, 15],
          }),
        })
      : new L.circleMarker(
          latlng,
          (current && !noFuture) || modeName === "deleted"
            ? {
                radius: 3,
                fillColor: "white",
                fillOpacity: 1,
                color: "#888",
                weight: 2,
              }
            : {
                radius: 5,
                fillColor: "white",
                fillOpacity: 1,
                color: "#" + line.color,
                weight: 3,
              }
        );
  };

  return (
    <GeoJSON {...props} pointToLayer={pointToLayer}>
      <StyledTooltip
        opacity={1}
        offset={[5 * (anchor === "left" || pr ? -1 : 1), 0]}
        permanent={term || label}
        // direction={anchor || pr ? 'left' : 'right'}
        {...props}
      >
        {name + (pr ? ` (${places} places)` : "")}
      </StyledTooltip>
    </GeoJSON>
  );
};

Stop.defaultProps = {
  // default react-leaflet + :
  line: null,
};
