import bbox from "@turf/bbox";
import L from "leaflet";
import React, { useEffect } from "react";
import { GeoJSON, Tooltip } from "react-leaflet";
import { useLeafletMap } from "use-leaflet";
import { useMedia } from "../../hooks/useMedia";
import { getBoardBoundingClientRect } from "../../utils/tools";
import { PolylineOffset } from "./PolylineOffset";

export const Line = (props) => {
  const map = useLeafletMap();
  const isDesktop = useMedia();
  const { data } = props;

  // Fit bounds function to an element bbox
  const fitBounds = (board, element) => {
    const [minx, miny, maxx, maxy] = bbox(element);

    if (
      Math.abs(minx) !== Infinity &&
      Math.abs(miny) !== Infinity &&
      Math.abs(maxx) !== Infinity &&
      Math.abs(maxy) !== Infinity
    ) {
      // Fit map bounds from polyline bbox
      setTimeout(() => {
        map.fitBounds(
          [
            [miny, minx],
            [maxy, maxx],
          ],
          {
            paddingTopLeft: L.point(isDesktop ? 450 : 0, 50),
            paddingBottomRight: L.point(0, 50),
          }
        );
      });
    }
  };

  useEffect(() => {
    const board = getBoardBoundingClientRect();

    if (props.centerOnDraw) {
      fitBounds(board, props.data);
    }

    // eslint-disable-next-line
  }, [isDesktop]);

  const polylines = [];

  // ! TODO handle all Geojson types with correct keys
  if (data.features) {
    for (const [key, feature] of Object.entries(data.features)) {
      const offset = feature.properties.offset;
      const isOdt = feature.properties.hasOwnProperty("tad");
      const odt = isOdt ? feature.properties.tad : null;

      // Rewrite some props if the feature is a ODT
      if (isOdt) {
        props = {
          ...props,
          style: {
            ...props.style,
            weight: 0,
            interactive: true,
          },
        };
      }

      polylines.push(
        offset ? (
          <PolylineOffset key={"line-offset-" + key} {...props} data={feature} />
        ) : (
          <GeoJSON key={"line-" + key} {...props} data={feature}>
            {odt && odt !== "" && <Tooltip sticky>{odt}</Tooltip>}
          </GeoJSON>
        )
      );
    }
  } else {
    polylines.push(<GeoJSON key="geojson" {...props} />);
  }

  return polylines;
};

Line.defaultProps = {
  // default react-leaflet + :
  offset: false,
  centerOnDraw: false,
};
