import axios from "../middlewares/axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionSetNetworkSlider, actionSetNetwotkSelectedLine } from "../actions/network";
import { Line } from "../components/map/Line";
import { Stop } from "../components/map/Stop";
import OldLinesHover from "../components/map/OldLinesHover";
import { useRouter } from "./useRouter";
import { message } from "../services/message";

export const useNetworkLineSelected = (draw = false, dontCenterOnLine = false) => {
  const router = useRouter();
  const { linesNetwork, hash, linesModesNetwork } = useSelector((state) => state.app);
  const { withSlider } = useSelector((state) => state.network);
  const [selected, setSelected] = useState(null);
  const { query } = router;
  const dispatch = useDispatch();

  /**
   * Router effect
   */
  useEffect(() => {
    dispatch(actionSetNetwotkSelectedLine(null));

    /**
     * Draw a given line on the map
     * ! TODO : Refactor future & current display
     * ! Move the for loops inside Line & Stop components
     * @param {String} line Line code
     */
    const drawLine = async (line, from) => {
      const polylines = [];
      const polylinesOffset = [];
      const stops = [];
      const stopsOffset = [];

      const draw = async (line, from) => {
        const lineMode = linesModesNetwork.find((mode) => mode.modes === line.type);
        const modeName = lineMode.show_like ? lineMode.show_like : lineMode.name;

        // Only future on modified lines and new lines (type = 1 or type = 4)
        // if (line.type === 1 || line.type === 2) {
        if (modeName === "new" || modeName === "modified" || modeName === "modified_line") {
          try {
            const future = await axios.get(
              `/api/file?folder=routes/future/lines&ext=geojson&name=${line.code}~${hash}`
            );

            const futureStops = await axios.get(
              `/api/file?folder=routes/future/stops&ext=geojson&name=${line.code}~${hash}`
            );

            // Try first if we have data in the loaded future geojson
            if (future.data && future.data.features) {
              polylines.push(
                <Line
                  key={"line" + (line && from ? "-base" : "")}
                  data={future.data}
                  centerOnDraw={!(dontCenterOnLine && query.town)}
                  style={{
                    color: "#" + line.color,
                    weight: 6,
                    interactive: false,
                  }}
                />
              );
            } else {
              message({
                error: "geojson_line_not_found",
                id: line.id,
                message: "Line has no geojson",
              });
            }

            if (futureStops.data && futureStops.data.features) {
              for (const [key, feature] of Object.entries(futureStops.data.features)) {
                stops.push(<Stop key={"stop-" + key + (line && from ? "-base" : "")} data={feature} line={line} />);
              }
            } else {
              message({
                error: "geojson_stops_line_not_found",
                id: line.id,
                message: "Stops of line has no geojson",
              });
            }
          } catch (e) {
            console.warn("no future ?");
          }
        }

        // No current on new lines (type = 4)
        // if (line.type !== 1) {
        if (modeName !== "new") {
          try {
            const current = await axios.get(
              `/api/file?folder=routes/current/lines&ext=geojson&name=${line.code}~${hash}`
            );

            const currentStops = await axios.get(
              `/api/file?folder=routes/current/stops&ext=geojson&name=${line.code}~${hash}`
            );

            const noFuture = polylines.length === 0;

            if (current.data && current.data.features) {
              polylinesOffset.push(
                <Line
                  key={"line-offset" + (line && from ? "-base" : "")}
                  data={current.data}
                  style={{
                    color: noFuture && modeName !== "deleted" ? "#" + line.color : "#888",
                    weight: noFuture && modeName !== "deleted" ? 6 : 4,
                    interactive: false,
                  }}
                  centerOnDraw={noFuture && !(dontCenterOnLine && query.town)}
                />
              );
            } else {
              message({
                error: "geosjon_line_not_found",
                id: line.id,
                message: "Geojson of line not found",
              });
            }

            if (currentStops.data && currentStops.data.features) {
              for (const [key, feature] of Object.entries(currentStops.data.features)) {
                stopsOffset.push(
                  <Stop
                    key={"stop-offset-" + key + (line && from ? "-base" : "")}
                    data={feature}
                    line={line}
                    current
                    noFuture={noFuture}
                  />
                );
              }
            } else {
              message({
                error: "geojson_stops_line_not_found",
                id: line.id,
                message: "Stops of line has no geojson",
              });
            }
          } catch (e) {
            console.warn("no geojson ?");
          }
        }

        // check if there is old lines geojson
        const oldLines = await axios.get(`/api/file?folder=routes/old/lines&ext=geojson&name=${line.code}~${hash}`);

        if (oldLines && oldLines.data && oldLines.data.features) {
          polylinesOffset.push(<OldLinesHover data={oldLines.data} key={"oldlinesover-" + line.code} />);
        }
      };

      // If a from param exist, draw the old line too
      if (from) {
        const lineFrom = linesNetwork.find((l) => String(l.id) === from);

        await draw(lineFrom);
      }

      await draw(line, from);

      // Dispatch the created polyline to display them on map
      dispatch(actionSetNetwotkSelectedLine([polylinesOffset, polylines, stopsOffset, stops]));
    };

    // Location switch
    if (query.line && linesNetwork) {
      let line = linesNetwork.find((l) => String(l.id) === String(query.line));

      // If line is replaced ...
      if (line && line.replaced_by) {
        line = linesNetwork.find((l) => String(l.id) === String(line.replaced_by));
      }

      // Draw the line on map
      draw && drawLine(line, query.from);

      // Retrieve the corresponding line a set it as active
      setSelected(line);

      // Disable slider
      withSlider && dispatch(actionSetNetworkSlider(false));

      if (query.town) {
        // Disable slider
        withSlider && dispatch(actionSetNetworkSlider(false));
      } else {
        // Enable slider
        // withSlider && setTimeout(() => {
        //   dispatch(actionSetNetworkSlider(true))
        // })
      }
    } else {
      setSelected(null);
      dispatch(actionSetNetwotkSelectedLine(null));

      if (query.town) {
        // Disable slider
        withSlider && dispatch(actionSetNetworkSlider(false));
      } else {
        // Enable slider
        withSlider && setTimeout(() => dispatch(actionSetNetworkSlider(true)));
      }
    }

    // eslint-disable-next-line
  }, [router]);

  return selected;
};
