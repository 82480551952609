import axios from "../middlewares/axios";
import L from "leaflet";
import { luminance } from "luminance-js";
import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { actionScrollEnd, actionSetComponent, actionSetProjectsMenuID } from "../actions/app";
import { actionSetGeolocationError, actionSetLineInformation } from "../actions/board";
import { actionSetReduxMarkers, actionSetCustomMarkers } from "../actions/map";
import {
  actionBuildHeavyLines,
  actionBuildCustomLines,
  actionBuildMapPlaces,
  actionBuildMarker,
  actionOnLineSelected,
} from "../actions/withRedux";
import history from "../history";
import {
  addPolesExchanges,
  addResizeEvent,
  getURLSearchParams,
  assetsPath,
  handleKeyPress,
  translate,
  envVarToBool,
} from "../services/tools";
import { appStore } from "../store";
import { updateDataLayer } from "../tracking";
import { removeMapEvents, updateMapEvents, zoomOnTerritoryOutline } from "../utils/leaflet/map";
import { substringCoords, updateURLState } from "../utils/leaflet/tools";
import { buildEntranceMap, buildCustomMarkers } from "../services/map";
import { message } from "../services/message";

const TourismPartners = lazy(() => import("./Modules/TourismPartners"));
const NetworkMap = lazy(() => import("./Modules/NetworkMap"));
const NetworkTowns = lazy(() => import("./Modules/NetworkTowns"));
const Around = lazy(() => import("./Modules/Around"));
const Lines = lazy(() => import("./Modules/Lines"));
const RouteCalculation = lazy(() => import("./Modules/RouteCalculation"));
const Thematic = lazy(() => import("./Modules/Thematic"));
const TextBoard = lazy(() => import("./Modules/TextBoard"));
const Bike = lazy(() => import("./Modules/Bike"));
const NetworkLines = lazy(() => import("./Modules/NetworkLines"));
const Projects = lazy(() => import("./Modules/Projects"));

const {
  REACT_APP_START_POINT,
  REACT_APP_ZOOM,
  REACT_APP_HEAVY_LINES,
  REACT_APP_AREAS_ZOOM_LEVEL,
  REACT_APP_POLES,
  REACT_APP_TERRITORY_OUTLINE,
  REACT_APP_FORCE_ENTRANCE_MAP,
  REACT_APP_TYPE,
} = process.env;

class Board extends Component {
  state = {};

  isScrolling = null;

  renderModule = () => {
    const {
      module,
      map,
      lines,
      linesModes,
      placesRef,
      stops,
      areas,
      places,
      towns,
      touchscreenSelected,
      hash,
      stations,
      servicesStations,
      domElement,
      options,
    } = this.props;

    if (!module) {
      console.warn("An error has occured while loading module ...");
      return;
    }

    const props = {
      lines,
      linesModes,
      placesRef,
      map,
      stops,
      areas,
      places,
      towns,
      stations,
      servicesStations,
      touchscreenSelected,
      hash,
      domElement,
      options,
    };

    const Fallback = () => <div className="lc-fallback">{translate("loading")}</div>;

    switch (module.id) {
      case "tourism-partners":
        return (
          <Suspense fallback={<Fallback />}>
            <TourismPartners moduleData={module} {...props} />
          </Suspense>
        );
      case "network-map":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkMap moduleData={module} {...props} />
          </Suspense>
        );
      case "network-towns":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkTowns moduleData={module} {...props} />
          </Suspense>
        );
      case "around":
        return (
          <Suspense fallback={<Fallback />}>
            <Around radius={module.radius} {...props} />
          </Suspense>
        );
      case "lines":
        return (
          <Suspense fallback={<Fallback />}>
            <Lines {...props} />
          </Suspense>
        );
      case "route-calculation":
        return (
          <Suspense fallback={<Fallback />}>
            <RouteCalculation moduleData={module} {...props} />
          </Suspense>
        );
      case "thematic":
        return (
          <Suspense fallback={<Fallback />}>
            <Thematic moduleData={module} {...props} />
          </Suspense>
        );
      case "text-board":
        return (
          <Suspense fallback={<Fallback />}>
            <TextBoard moduleData={module} {...props} />
          </Suspense>
        );
      case "bike":
        return (
          <Suspense fallback={<Fallback />}>
            <Bike moduleData={module} {...props} />
          </Suspense>
        );
      case "network-lines":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkLines moduleData={module} {...props} />
          </Suspense>
        );
      case "projects":
        return (
          <Suspense fallback={<Fallback />}>
            <Projects moduleData={module} {...props} />
          </Suspense>
        );
      default:
        break;
    }
  };

  resizeBoardContent = () => {
    const { top, isMobile, isMobileBoot } = this.props;

    if (isMobile || isMobileBoot) {
      window.scrollTo(0, top);

      window.addEventListener("scroll", () => {
        // Clear our timeout throughout the scroll
        clearTimeout(this.isScrolling);

        // Set a timeout to run after scrolling ends
        this.isScrolling = setTimeout(() => {
          // Run the callback
          appStore.dispatch(actionScrollEnd(window.pageYOffset));
        }, 50);
      });
    }
  };

  showBackButton = (modulesToRender) => {
    let { lock, options } = this.props;
    const { pathname } = history.location;
    const params = getURLSearchParams(history.location);
    const getParamsLength = Object.keys(params).filter((param) => ["lock", "tab"].indexOf(param) === -1).length;
    const hasParams = getParamsLength > 0;

    // Handle "change-line" feature on options, but only if we don't have "date" params which require a back anyways
    if (options?.features?.["change-line"] === false && !params.date) {
      return false;
    }

    const nbModulesToRender = Object.keys(modulesToRender)
      .map((renderType) => modulesToRender[renderType].length)
      .reduce((a, b) => a + b);

    if (nbModulesToRender <= 1) {
      lock = true;
    }

    if (lock) {
      if (pathname.includes("/lines")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/around")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/route-calculation")) {
        if ((params.from && !params.to) || (!params.from && params.to)) {
          if (getParamsLength > 1) {
            return true;
          } else {
            return false;
          }
        } else if (params.from && params.to) {
          if (getParamsLength > 2) {
            return true;
          } else {
            return false;
          }
        }
      } else if (pathname.includes("/towns")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/hiking-routes")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/mentions")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("places-interest")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-map")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-towns")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-lines")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("tourism-partners")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  // TODO optimize return with various
  back = () => {
    const { pathname, search } = history.location;
    const params = getURLSearchParams(history.location);

    delete params.lock;

    if (pathname.includes("/lines")) {
      // Remove any current line information message
      appStore.dispatch(actionSetLineInformation(null));

      if (params.current) {
        if (params.date) {
          const searchParam = search.split("&date=")[0];

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        } else {
          const searchParam = "?" + (params.stop_area ? `stop_area=${params.stop_area}&` : "");

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: "?" + (params.stop_area ? `stop_area=${params.stop_area}&` : ""),
          });
        }
      } else if (params.stop_area) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({ pathname });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (pathname.includes("/around")) {
      if (params.line) {
        if (params.from) {
          if (params.date) {
            const searchParam = search.split("&date=")[0];

            message({
              clicked: "back",
              from: pathname,
              from_params: params,
              to: pathname,
              to_params: getURLSearchParams({ search: searchParam }),
            });
            history.push({ pathname, search: searchParam });
          } else {
            const searchParam = "?from=" + params.from;

            message({
              clicked: "back",
              from: pathname,
              from_params: params,
              to: pathname,
              to_params: getURLSearchParams({ search: searchParam }),
            });
            history.push({ pathname, search: searchParam });
          }
        } else {
          const searchParam = params.date ? search.split("&date=")[0] : "";

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        }
      } else if (params.from && !params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (pathname.includes("/route-calculation")) {
      if (params.to && params.from) {
        if (params.journey) {
          const searchParam =
            "?from=" + params.from + "&to=" + params.to + "&date=" + params.date + "&modes=" + params.modes;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else if (params.date) {
          const searchParam = "?from=" + params.from + "&to=" + params.to;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else {
          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: "/",
            to_params: {},
          });
          history.push("/");
        }
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/towns")) {
      if (params.insee && params.line) {
        if (params.date) {
          const searchParam = search.split("&date=")[0];

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        } else {
          const searchParam = "?insee=" + params.insee;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        }
      } else if (params.insee && !params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (pathname.includes("/hiking-routes")) {
      let searchArgs = search.split("&");

      if (searchArgs.length > 1) {
        const toRemove = searchArgs[searchArgs.length - 1];
        const searchParam = search.replace("&" + toRemove, "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({ pathname, search: searchParam });
      } else {
        if (search) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        } else {
          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: "/",
            to_params: {},
          });
          history.push("/");
        }
      }
    } else if (pathname.includes("/mentions")) {
      message({
        clicked: "back",
        from: pathname,
        from_params: params,
        to: "/",
        to_params: {},
      });
      history.push("/");
      this.setState({ mentions: false });
    } else if (pathname.includes("places-interest")) {
      let searchArgs = search.split("&");

      // TODO FIX FOR PLACES-INTEREST TO THINK BETTER
      if (searchArgs.length > 1) {
        let toRemove = searchArgs[searchArgs.length - 1];
        const searchParam = search.replace("&" + toRemove, "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({ pathname, search: searchParam });
      } else {
        if (search) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        } else {
          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: "/",
            to_params: {},
          });
          history.push("/");

          // Dispatch the selected line action
          appStore.dispatch(actionOnLineSelected(null));
        }
      }
    } else if (pathname.includes("/network-map")) {
      if (params.line && params.from) {
        const searchParam = `?line=${params.from}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/network-towns")) {
      if (params.town && params.line && params.from) {
        const searchParam = `?town=${params.town}&line=${params.from}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town && params.line) {
        const searchParam = `?town=${params.town}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/network-lines")) {
      if (params.town && params.line && params.from) {
        const searchParam = `?town=${params.town}&line=${params.from}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town && params.line) {
        const searchParam = `?town=${params.town}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname: pathname,
          search: params.tab ? "?tab=" + params.tab : "",
        });
      } else if (params.line && params.from) {
        const searchParam = `?line=${params.from}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
          search: params.tab ? "?tab=" + params.tab : "",
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("tourism-partners")) {
      if (params.partner) {
        if (params.type) {
          const searchParam = `?type=${params.type}`;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        }
      } else if (params.type) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else {
      if (params.place && params.line) {
        const searchParam = "?place=" + params.place;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.place) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else if (params.line) {
        if (params.tiles) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push({
            pathname,
            search: "?tiles=" + params.tiles,
          });
        } else if (params.line) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        }
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        if (REACT_APP_TYPE === "projects") {
          appStore.dispatch(actionSetProjectsMenuID(1));
        }
      }

      // Dispatch the selected line action
      appStore.dispatch(actionOnLineSelected(null));
    }
  };

  getPage = (page) => {
    return (
      <>
        <div className="lc-board-header">
          <div
            className="back"
            onClick={() => this.back()}
            onKeyPress={(e) => handleKeyPress(e, () => this.back())}
            role="button"
            tabIndex="0"
            title={translate("title-back")}
          >
            <img src={assetsPath("/assets/images/back.svg")} alt={translate("back")} />
          </div>
          <div className="board-title">{page === "mentions" ? "mentions légales" : page}</div>
        </div>
        <div className="content page" dangerouslySetInnerHTML={{ __html: this.state.content }} />
      </>
    );
  };

  onLineSelected = async (line, marker) => {
    this.props.history.push(`/lines?current=${line.id}_${line.direction_id}&stop=${marker.id}`);
  };

  onClickInfobox(marker, url) {
    const { history, map } = this.props;
    const path = `/${url}?from=${substringCoords(marker.coord)}`;

    history.push(path);

    map.setState({ infoboxs: [] });
  }

  onClickItemPage = (page) => {
    this.props.history.push("/" + page);
    this.setState({ [page]: true });
  };

  componentDidUpdate(prevProps) {
    !prevProps.isMobile && this.props.isMobile && this.resizeBoardContent();
  }

  async componentDidMount() {
    const {
      lines,
      map,
      history,
      places = [],
      stations: dataStations = [],
      modules,
      touchscreenSelected,
      placesRef,
      isMobile,
      hash,
      heavyIds,
      customMapLines,
      customMapMarkers,
    } = this.props;

    if (history.location.pathname === "/") {
      appStore.dispatch(actionSetComponent(this));
    }

    // Send a component loaded message
    message({ loaded: "board" });

    // Remove previous geolocation errors
    appStore.dispatch(actionSetGeolocationError(null));

    // TODO USE TEXT-BOARD MODULE FOR MENTIONS LEGALES/...
    let content = null;

    const pageModule = modules.find(
      (m) => !m.hide && m.id !== "admin" && (touchscreenSelected ? m.touchscreen : m) && m && m.id === "page"
    );

    pageModule &&
      (await axios
        .get("/api/file?folder=pages&ext=html&name=" + pageModule.file)
        .then((response) => {
          content = response.data;
        })
        .catch((e) => {
          const error = e.response && e.response.data ? e.response.data.id : e;

          console.warn(error);
        }));
    this.setState({ content });

    if (history.location.pathname === "/mentions") {
      this.setState({ mentions: true });
    }

    // Dispatch action to build entrance map
    if (map) {
      if (heavyIds.length > 0 || envVarToBool(REACT_APP_FORCE_ENTRANCE_MAP)) {
        axios
          .get(`/api/file?name=entrance_map&folder=map&ext=geojson`)
          .then((response) => buildEntranceMap(response.data, map));
      }

      if (REACT_APP_TERRITORY_OUTLINE && JSON.parse(REACT_APP_ZOOM).where === "territory_outline") {
        zoomOnTerritoryOutline(map);
      } else if (JSON.parse(REACT_APP_ZOOM).where === "start_point") {
        const mapElement = map.mapReference.current.leafletElement;

        mapElement &&
          mapElement.setView(
            JSON.parse(REACT_APP_START_POINT)[isMobile ? "mobile" : "desktop"],
            JSON.parse(REACT_APP_ZOOM)[isMobile ? "mobile" : "desktop"]
          );
      }
    }

    // Retrieve places to display them in the map
    const data = places.concat(dataStations);
    const placesToDisplay = placesRef ? placesRef.find((p) => p.name === "map-background") : [];

    const mapPlaces = data.filter((place) => {
      return placesToDisplay && placesToDisplay.places ? placesToDisplay.places.includes(place.cat_id) : [];
    });

    // Display all places cat defined in map-background
    appStore.dispatch(actionBuildMapPlaces(mapPlaces));

    // No lines, no mount
    if (!lines.length) {
      return;
    }

    // Fix iOS touchmove problem (map and board moving without touching theme
    //document.addEventListener('touchmove', () => { })

    if (map && REACT_APP_TYPE !== "network") {
      // Build, display heavy lines
      if (heavyIds.length > 0) {
        appStore.dispatch(actionBuildHeavyLines());
      }

      if (customMapLines.length > 0) {
        appStore.dispatch(actionBuildCustomLines(customMapLines));
      }

      if (customMapMarkers.length > 0) {
        appStore.dispatch(actionSetCustomMarkers(buildCustomMarkers(customMapMarkers)));
      }

      // Reset markers on board load
      appStore.dispatch(actionSetReduxMarkers([]));

      updateMapEvents(map, "onMoveEnd", async (e) => {
        // TODO Do not display markers on Route Calculation
        if (
          history.location.pathname.includes("route-calculation") ||
          history.location.pathname.includes("bike") ||
          (history.location.pathname.includes("lines") && history.location.search.includes("current="))
        ) {
          return;
        }

        const { reactAreas, reactStops } = this.props;
        const url = updateURLState(history.location);
        const lineId = url.line && url.line.substring(0, url.line.lastIndexOf("_"));
        const zoom = map.mapReference.current.leafletElement.getZoom();
        const bounds = e.sourceTarget.getBounds();
        const markers = [];

        if (reactAreas) {
          for (const area of reactAreas) {
            // Retrieve the marker only if it's inside the map bounds
            if (bounds.contains(area.props.position)) {
              // Below REACT_APP_AREAS_ZOOM_LEVEL, display only heavy lines or cluster if there is no heavy lines
              if (
                zoom >= 14 &&
                zoom < +REACT_APP_AREAS_ZOOM_LEVEL &&
                REACT_APP_HEAVY_LINES &&
                REACT_APP_HEAVY_LINES.length
              ) {
                for (const line of area.props.area.lines) {
                  if ((heavyIds.includes(line.id) || (lineId && line.id === lineId)) && markers.indexOf(area) < 0) {
                    markers.push(area);
                  }
                }
              } else if (zoom >= 17) {
                // 17 or above, display stops
                // Retrieve all physical stops for this stop area
                markers.push(
                  ...reactStops.filter((stop) => {
                    return stop.props.stop.stop_area === area.props.area.id;
                  })
                );
              } else if (zoom >= +REACT_APP_AREAS_ZOOM_LEVEL && zoom < 17) {
                // Display areas between REACT_APP_AREAS_ZOOM_LEVEL & 17 §17 is stops points, no matter what)
                markers.push(area);
              }
            }
          }

          if (
            REACT_APP_POLES &&
            ["around", "lines"].some((condition) => history.location.pathname.includes(condition))
          ) {
            addPolesExchanges(reactAreas, markers, zoom);
          }
        }

        if (lineId) {
          const line = lines.find((l) => l.id === lineId);
          const direction = url.line.split("_")[1] ? url.line.split("_")[1] : "f";

          if (!line.stops) {
            if (this.props.module.id !== "thematic" && this.props.module.type !== "searchOnly") {
              try {
                const response = await axios.get(
                  `/api/file?folder=stops&name=${encodeURIComponent(line.code)}_${line.network}_${direction}~${hash}`
                );

                line.stops = response.data;
              } catch (e) {
                const error = e.response && e.response.data ? e.response.data.id : e;

                console.warn(error);
              }
            }
          }

          const terminusLine = line.stops.filter((s) => s.terminus);

          for (const terminus of terminusLine) {
            // ? Todo check if there is no regression on this
            if (!terminus.lines) {
              continue;
            }

            markers.push(
              appStore.dispatch(
                actionBuildMarker(terminus, {
                  key: line.code + "_" + terminus.index,
                  icon: new L.DivIcon({
                    className: "lc-circle-icon-marker",
                    iconSize: [10, 10],
                    tooltipAnchor: new L.Point(5, 0),
                    html: `<span style="border: 3px solid #${line.color}" />`,
                  }),
                  stop: terminus,
                  zIndexOffset: 200,
                  terminus: true,
                })
              )
            );
          }

          appStore.dispatch(actionSetReduxMarkers(markers));

          setTimeout(() => {
            // TODO find a better way :-)
            document.querySelectorAll(".lc-tooltip-leaflet-terminus").forEach((div) => {
              div.style.backgroundColor = "#" + line.color;
              div.style.borderColor = "#" + line.color;
              div.style.color = luminance(line.color) > 0.5 ? "#333" : "#fff";
            });
          });
        } else {
          // Dispatch the new created markers
          appStore.dispatch(
            actionSetReduxMarkers(
              markers.filter((m) => {
                const type = Object.keys(m.props).includes("area") ? "area" : "stop";

                return m.props[type].lines.length;
              })
            )
          );
        }
      });

      this.removeEventListener = addResizeEvent(isMobile);
    }
  }

  componentWillUnmount() {
    const { map } = this.props;

    if (map && REACT_APP_TYPE !== "network") {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        pin: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: [],
      });
      removeMapEvents(map);
      this.removeEventListener && this.removeEventListener();

      if (map.mapReference.current && this.onStreetViewChanged) {
        this.onStreetViewChanged.remove();
      }
    }
  }

  handleProjectsLinkClick = (event, module) => {
    const target = event.target;
    const projectsLinkSelected = document.querySelector(".lc-projects-link-selected");

    if (!module) {
      appStore.dispatch(actionSetProjectsMenuID(1));
    }

    // If we click on the same link again, just back to / & remove the class
    if (target.classList.contains("lc-projects-link-selected")) {
      target.classList.remove("lc-projects-link-selected");

      history.push({
        pathname: "/",
      });
      appStore.dispatch(actionSetProjectsMenuID(1));

      event.preventDefault();
      return false;
    }

    if (projectsLinkSelected) {
      projectsLinkSelected.classList.remove("lc-projects-link-selected");
    }

    if (module) {
      appStore.dispatch(actionSetProjectsMenuID(module.position));
    }

    if (target.classList.contains("lc-menu-title")) {
      target.classList.add("lc-projects-link-selected");
    } else if (target.classList.contains("lc-menu-item")) {
      target.querySelector(".lc-menu-title").classList.add("lc-projects-link-selected");
    }
  };

  render() {
    const {
      modules,
      module,
      size,
      language,
      touchscreenSelected,
      selectedTown,
      selectedPartner,
      selectedPartnerType,
      options,
      showBoard,
    } = this.props;

    const params = getURLSearchParams(history.location);
    const regex = /(http[s]?:\/\/[^/\s]+)/g;
    const matches = document.referrer.match(regex);
    const origin = matches && document.referrer.match(regex).shift();

    const modulesToRender = modules
      .filter((m) => {
        if (options && options.menu && options.menu.links) {
          if (m.id === "thematic") {
            return (
              Object.keys(options.menu.links)
                .filter((link) => !options.menu.links[link])
                .indexOf(m.data) === -1
            );
          } else {
            return (
              Object.keys(options.menu.links)
                .filter((link) => !options.menu.links[link])
                .indexOf(m.id) === -1
            );
          }
        } else {
          return !m.hide && m.id !== "admin" && (touchscreenSelected ? m.touchscreen : m) && m;
        }
      })
      .sort((a, b) => +(a.position > b.position) || +(a.position === b.position) - 1)
      .reduce(
        (accumulator, currentModule) => {
          const type =
            currentModule.id === "pdf-download"
              ? "pdf"
              : currentModule.id === "page"
              ? "page"
              : currentModule.submodule
              ? "subs"
              : "mains";

          if (currentModule.id === "pdf-download") {
            switch (currentModule.type) {
              case "local":
                currentModule.url = assetsPath("/assets") + currentModule.link[language];
                break;
              case "extern":
                currentModule.url = origin + currentModule.link[language];
                break;
              default:
                break;
            }
          }

          accumulator[type].push(currentModule);

          return { ...accumulator };
        },
        {
          mains: [],
          subs: [],
          page: [],
          pdf: [],
        }
      );

    let blockBackMenuUnique = false;
    let showBackButton = this.showBackButton(modulesToRender);
    // Si on a qu'un module à render, on enlève le back button

    if (Object.keys(modules).length === 1 && module) {
      for (const p of ["lock", "size", "touchscreen", "stop"]) {
        delete params[p];
      }

      if (Object.keys(params).length === 0) {
        blockBackMenuUnique = true;
      }
    }

    const boardHead = modules.find((module) => module.id === "board-head");

    return (
      <section
        className={"lc-board" + (!module ? " lc-no-module" : "") + ` lc-${size}` + (!showBoard ? " lc-no-board" : "")}
        data-lc-board
      >
        {module && (
          <div className="lc-content">
            {boardHead && (
              <div key={`${boardHead.id}`} className="lc-board-head">
                <Link to="/" onClick={this.handleProjectsLinkClick}>
                  <img src={boardHead.image} alt={boardHead.title} />
                </Link>
              </div>
            )}
            <div className="lc-board-header">
              {showBackButton && !blockBackMenuUnique && (
                <button className="lc-back" onClick={() => this.back()}>
                  <img src={assetsPath("/assets/images/back.svg")} alt={translate("back")} />
                </button>
              )}
              <h1
                className={
                  "lc-board-title" +
                  (!showBackButton ? " lc-board-title-lock" : "") +
                  (selectedTown || selectedPartnerType || selectedPartner ? " lc-with-subtitle" : "")
                }
              >
                {selectedPartner ? (
                  <div className="lc-board-title-uppercase">
                    <div className="lc-board-title-subtitle">{translate(module.title)}</div>
                    {selectedPartner}
                  </div>
                ) : selectedPartnerType ? (
                  <div className="lc-board-title-uppercase">
                    <div className="lc-board-title-subtitle">{translate(module.title)}</div>
                    {selectedPartnerType}
                  </div>
                ) : (
                  translate(module.title)
                )}
                {selectedTown && (
                  <div className="lc-board-title-town" dangerouslySetInnerHTML={{ __html: selectedTown.name }} />
                )}
              </h1>
            </div>
            {this.renderModule()}
          </div>
        )}
        {!module &&
          (this.state.mentions ? (
            this.getPage("mentions")
          ) : (
            <div className="lc-scrolling">
              {modules.length ? (
                <>
                  <div className={"lc-content lc-main lc-home"}>
                    {Object.keys(modulesToRender)
                      .filter((m) => m !== "pdf")
                      .map((groupModule) => {
                        if (groupModule === "page" && modulesToRender[groupModule].length) {
                          return (
                            <div
                              key={groupModule}
                              onClick={() => this.onClickItemPage(modulesToRender[groupModule][0].file)}
                              onKeyPress={(e) =>
                                handleKeyPress(e, () => this.onClickItemPage(modulesToRender[groupModule][0].file))
                              }
                              role="button"
                              tabIndex="0"
                              className="lc-menu-item-page"
                            >
                              {modulesToRender[groupModule][0].title}
                            </div>
                          );
                        } else {
                          return (
                            <div key={groupModule} className={groupModule === "subs" ? "lc-sub-module" : ""}>
                              {modulesToRender[groupModule].map((module, index) => {
                                if (module.id === "board-head") {
                                  return (
                                    <div key={`${module.id}_${index}`} className="lc-board-head">
                                      <Link to="/" onClick={this.handleProjectsLinkClick}>
                                        <img src={module.image} alt={module.title} />
                                      </Link>
                                    </div>
                                  );
                                } else {
                                  if (module.id === "projects" && module.noLink) {
                                    return (
                                      <Link
                                        to={`?tiles=${module.tiles}`}
                                        key={`${module.id}_${index}`}
                                        className="lc-menu-item"
                                        onClick={(e) => this.handleProjectsLinkClick(e, module)}
                                      >
                                        <div className={!module.submodule ? "lc-menu-item-content" : ""}>
                                          <div
                                            className="lc-menu-title"
                                            dangerouslySetInnerHTML={{
                                              __html: translate(!module.submodule ? module.title : module.text),
                                            }}
                                          />
                                        </div>
                                      </Link>
                                    );
                                  }

                                  return (
                                    <Link
                                      key={`${module.id}_${index}`}
                                      className="lc-menu-item"
                                      to={`/${
                                        ["thematic", "text-board", "projects"].includes(module.id)
                                          ? module.data
                                          : module.id
                                      }${
                                        ["projects"].includes(module.id) && module.tiles ? `?tiles=${module.tiles}` : ""
                                      }`}
                                    >
                                      {module.image && (
                                        <img
                                          className="lc-images"
                                          src={assetsPath(module.image)}
                                          alt={translate(module.title)}
                                        />
                                      )}
                                      <div className={!module.submodule ? "lc-menu-item-content" : ""}>
                                        <div
                                          className="lc-menu-title"
                                          dangerouslySetInnerHTML={{
                                            __html: translate(!module.submodule ? module.title : module.text),
                                          }}
                                        />
                                        {!this.props.isMobile && !module.submodule && module.description && (
                                          <div
                                            className="lc-menu-item-description"
                                            dangerouslySetInnerHTML={{
                                              __html: translate(module.description),
                                            }}
                                          />
                                        )}
                                      </div>
                                      {!module.submodule && <div className="lc-menu-item-arrow" />}
                                    </Link>
                                  );
                                }
                              })}
                            </div>
                          );
                        }
                      })}
                  </div>
                  {modulesToRender.pdf.map((pdfModule, index) => {
                    return (
                      <div key={`${pdfModule.id}_${index}`} className="lc-sub-content lc-main">
                        <a
                          className="lc-menu-item"
                          href={pdfModule.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            updateDataLayer({
                              event: "map-consultationPDFPlans",
                            })
                          }
                        >
                          <img
                            className="lc-images"
                            src={assetsPath("/assets/images/menu/pdf.svg")}
                            alt={translate(pdfModule.text)}
                          />
                          <div className="lc-menu-item-content">
                            <div className="lc-menu-title">{translate(pdfModule.text)}</div>
                          </div>
                          <div className="lc-menu-item-arrow" />
                        </a>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="lc-loading" data-lc-loading>
                  <img src={assetsPath("/assets/images/loading.gif")} width={30} alt={translate("loading")} />
                </div>
              )}
            </div>
          ))}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    top: state.app.top,
    isMobile: state.app.isMobile,
    modules: state.app.modules,
    map: state.app.map,
    lines: state.app.lines,
    linesModes: state.app.linesModes,
    stops: state.app.stops,
    areas: state.app.areas,
    places: state.app.places,
    placesRef: state.app.placesRef,
    towns: state.app.towns,
    stations: state.app.stations,
    servicesStations: state.app.servicesStations,
    heavyIds: state.app.heavyIds,
    customMapLines: state.app.customMapLines,
    customMapMarkers: state.app.customMapMarkers,
    language: state.app.language,
    lock: state.app.lock,
    size: state.app.size,
    touchscreenSelected: state.app.touchscreenSelected,
    hash: state.app.hash,
    domElement: state.app.domElement,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    selectedTown: state.network.town,
    selectedPartner: state.tourismPartners.partner,
    selectedPartnerType: state.tourismPartners.typePartner,
  };
};

export default withRouter(connect(mapStateToProps)(Board));
