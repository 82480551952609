export default class BikeInterface {
  constructor(bike, project) {
    switch (project) {
      case "arras-artis":
        this.id = bike.name;
        this.cat_id = "poi_type:amenity:bicycle_rental";
        this.name = bike.name;
        this.address = bike.streetAddress + "\n" + bike.postalCode + " " + bike.addressLocality;
        this.capacity = bike.capacity;
        this.availablePlaces = bike.availablePlaces;
        this.coord = {
          lat: bike.latitude,
          lon: bike.longitude,
        };
        break;
      default:
        break;
    }
  }
}
