import { debug } from "./tools";
import { appStore } from "../store";
import { buildCustomMarkers } from "./map";
import { actionSetCustomMarkers, actionSetCenter, actionSetZoom } from "../actions/map";
import { actionSetSliderUrlLeft, actionSetSliderUrlRight } from "../actions/network";
import { actionBuildCustomLines } from "../actions/withRedux";
import history from "../history";
import { actionSetConfig } from "../actions/app";

/**
 * Use postMessage API to pass data through the iframe / library direclty to the main website
 * @see https://developer.mozilla.org/fr/docs/Web/API/Window/postMessage
 * @param {Object} data All data we wanna pass through the main website for this message
 */
export const message = (data) => {
  // Retreive the correct DOM Element to post message
  const element = isInFrame() ? window.parent : window;
  const documentReferer = appStore.getState().app.documentReferer;

  // Retrieve the correct target origin for our message
  const targetOrigin =
    process.env.REACT_APP_ENV === "local"
      ? "*"
      : process.env.REACT_APP_LIBRARY_URL
      ? process.env.REACT_APP_LIBRARY_URL
      : isInFrame()
      ? documentReferer // document.referrer // document.location.ancestorOrigins[0]
      : process.env.REACT_APP_API_PROXY_URL;

  // Post message, or warn in console if something went wrong
  try {
    element.postMessage({ source: "lcmap", ...data }, targetOrigin);
    // Debug for dev & local
    debug({ message: "message sent with", data }, "info", "post message");
  } catch (e) {
    debug({ message: "Error while sending ", data }, "error", "error post message");
    throw new Error("Oops, une erreur est survenue sur l'envoi de données postMessage !");
  }
};

export const receivedMessage = () => {
  window.addEventListener("message", (event) => {
    if (event.data.source === "lcmap-in") {
      if (event.data.route) {
        history.push((!event.data.route.startsWith("/") ? "/" : "") + event.data.route);
      }

      if (event.data.lines !== undefined) {
        appStore.dispatch(actionBuildCustomLines(event.data.lines));
      }

      if (event.data.markers !== undefined) {
        appStore.dispatch(actionSetCustomMarkers(buildCustomMarkers(event.data.markers)));
      }

      if (event.data.zoom) {
        appStore.dispatch(actionSetZoom(event.data.zoom));
      }

      if (event.data.center) {
        appStore.dispatch(actionSetCenter(event.data.center));
      }

      if (event.data.sliderUrls) {
        if (event.data.sliderUrls.right) {
          appStore.dispatch(actionSetSliderUrlRight(event.data.sliderUrls.right));
        }

        if (event.data.sliderUrls.left) {
          appStore.dispatch(actionSetSliderUrlLeft(event.data.sliderUrls.left));
        }
      }

      if (event.data.config) {
        appStore.dispatch(actionSetConfig(event.data.config));
      }
    }
  });
};

/**
 * Detect if the app is loaded in an iframe or not
 */
const isInFrame = () => window.self !== window.top;
