import axios from "../../middlewares/axios";
import { luminance } from "luminance-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import history from "../../history";
import Collapse from "@kunukn/react-collapse";
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import Moment from "react-moment";
import { DateCalendar } from "@latitude-cartagene/react-picker-date-and-time";
import PolylineDecorator from "./PolylineDecorator";
import Tippy from "@tippy.js/react";
import { getColorGroup, groupLinesByMode, sortBy, substringCoords, timetableDataIsEmpty, unique } from "./tools";
import { appStore } from "../../store";
import {
  actionBuildPlacesByCatInList,
  actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
} from "../../actions/withRedux";
import {
  addHistoricItem,
  getURLSearchParams,
  isSystemUS,
  navitiaDateToHoursMin,
  disruptionsDatetime,
  resize,
  envVarToBool,
  storageAvailable,
  assetsPath,
  handleKeyPress,
  translate,
} from "../../services/tools";
import {
  actionSetLineInformation,
  actionSetOpenedCollapse,
  actionSetPlaceClicked,
  actionSetDisruptionInLine,
} from "../../actions/board";
import { updateDataLayer } from "../../tracking";
import domtoimage from "dom-to-image-lc";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import { detect } from "detect-browser";
import colors from "../../scss/app.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { message } from "./../../services/message";

const {
  REACT_APP_LINES_MAIN_TYPE,
  REACT_APP_LINES_TYPE_EXCEPTIONS,
  REACT_APP_MODES_LINES_STYLE,
  REACT_APP_TIMETABLES,
  REACT_APP_NEXT_SCHEDULES_RESULTS,
  REACT_APP_NIGHT_LINES,
  REACT_APP_SHOW_PMR,
  REACT_APP_SHOW_ADDITIONAL_STOP_TOOL,
  REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION,
  REACT_APP_DISRUPTION,
} = process.env;

moment.relativeTimeThreshold("m", 59);
moment.updateLocale("fr", {
  relativeTime: {
    mm: "%d min",
    m: "1 min",
    ss: "1 min",
    s: "1 min",
  },
});

const nextSchedules = REACT_APP_NEXT_SCHEDULES_RESULTS ? REACT_APP_NEXT_SCHEDULES_RESULTS : 2;

const Timetable = (props) => {
  let step = "morning";
  const now = new Date().getHours();
  const { data, line, timetableStop } = props;
  const nightLines = REACT_APP_NIGHT_LINES ? JSON.parse(REACT_APP_NIGHT_LINES) : { departure: "00", lines: [] };
  const isNightline = nightLines.lines.includes(line.id);
  const departure = +nightLines.departure;

  const hours = {
    morning: Array.from(new Array(8), (_, i) => i + (isNightline ? departure : 4)).map((x) => (x >= 24 ? x - 24 : x)),
    afternoon: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 8 : 12)).map((x) =>
      x >= 24 ? x - 24 : x
    ),
    evening: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 16 : 20)).map((x) =>
      x >= 24 ? x - 24 : x
    ),
  };

  for (const key of Object.keys(hours)) {
    if (hours[key].includes(now)) {
      step = key;
    }
  }

  const Arrow = ({ className, style, onClick, arrow }) => {
    const next = arrow === "next";

    const label =
      currentStep === "morning"
        ? next
          ? "12h - 03h"
          : ""
        : currentStep === "afternoon"
        ? next
          ? "20h - 03h"
          : "04h - 11h"
        : next
        ? ""
        : "04h - 19h";

    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
        onKeyPress={(e) => handleKeyPress(e, onClick)}
        role="button"
        tabIndex="0"
        aria-label={translate("aria-lines-timetable-see-hours", false, { key: "hours", value: label })}
      >
        <span>{label}</span>
      </div>
    );
  };

  const [body, setBody] = useState([]);
  const [currentStep, setCurrentStep] = useState(String(step));
  const [, setDirections] = useState([]);
  let minutes = [];
  let otherDirections = [];

  const settings = {
    adaptiveHeight: true,
    className: "lc-timetable",
    dots: true,
    infinite: false,
    initialSlide: Object.keys(hours).indexOf(step),
    speed: 200,
    beforeChange: (_, next) =>
      setTimeout(() => setCurrentStep(next === 0 ? "morning" : next === 1 ? "afternoon" : "evening")),
    nextArrow: <Arrow arrow="next" />,
    prevArrow: <Arrow />,
  };

  const Slide = ({ data, step }) => {
    minutes = [];

    useEffect(() => {
      // Build other directions
      for (const item of data) {
        if (item) {
          for (const direction of item.directions) {
            if (otherDirections.indexOf(direction) < 0) {
              otherDirections.push(direction);
            }
          }
        }
      }

      setDirections(otherDirections);
    }, [data]);

    return (
      <>
        <table key={step}>
          <thead>
            <tr>
              {hours[step].map((hour) => (
                <th key={hour} tabIndex="0">
                  {hour}h
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{buildMinutesLine(step, data, [])}</tbody>
        </table>
        {otherDirections.length > 0 && (
          <div className="lc-otherDirections" data-lc-other-directions>
            <div className="lc-otherdirectionsContent">{translate("timetable-others-destinations")} :</div>
            {otherDirections.map((direction, i) => (
              <div key={direction} className="lc-otherdirectionsContent">
                <span className="lc-otherdirectionsContentLetter">{String.fromCharCode(i + 97)}</span> : {direction}
              </div>
            ))}
          </div>
        )}
        {translate("notes-timetable") && (
          <div className="lc-notes-timetable" data-lc-notes-timetable>
            <img src={assetsPath("assets/images/disruptions/notice.svg")} alt={translate("notice")} />
            <div
              dangerouslySetInnerHTML={{
                __html: translate("notes-timetable"),
              }}
            />
          </div>
        )}
      </>
    );
  };

  // Data is already stepped
  const buildMinutesLine = (step, data, acc, line = []) => {
    for (const i of hours[step]) {
      const time = data.find((d) => +d.date_time.split("T")[1].substring(0, 2) === i && acc.indexOf(d) < 0);

      acc.push(time);
      line.push(time);
    }

    minutes.push(
      <tr key={step + "-" + minutes.length}>
        {line.map((a, i) => {
          const dirs = [];

          if (a) {
            for (const direction of a.directions) {
              if (dirs.indexOf(direction) < 0) {
                dirs.push(direction);
              }
            }
          }

          return (
            <td key={i}>
              <div tabIndex="0" role="button">
                {a && a.date_time.split("T")[1].substring(2, 4)}{" "}
                {dirs.map((dir, i) => {
                  const realDir = otherDirections.indexOf(dir);

                  if (realDir >= 0) {
                    return <span key={realDir}>{String.fromCharCode(realDir + 97)}</span>;
                  } else {
                    return null;
                  }
                })}
              </div>
            </td>
          );
        })}
      </tr>
    );

    while (acc.filter((a) => a).length < data.length) {
      buildMinutesLine(step, data, acc);
    }

    return minutes;
  };

  const buildNightlineData = (data) => {
    let times = [];

    // Flatten all times into one array
    for (const key of Object.keys(data)) {
      times = times.concat(...data[key]);
    }

    // Rebuild the correct array of times depeding on each day step
    return {
      morning: times.filter((time) => hours["morning"].includes(+time.date_time.split("T")[1].substring(0, 2))),
      afternoon: times.filter((time) => hours["afternoon"].includes(+time.date_time.split("T")[1].substring(0, 2))),
      evening: times.filter((time) => hours["evening"].includes(+time.date_time.split("T")[1].substring(0, 2))),
    };
  };

  useEffect(() => {
    const times = isNightline ? buildNightlineData(data) : data;
    // Try if there is no data
    let isEmpty = true;

    for (const step of Object.keys(times)) {
      if (times[step].length > 0) {
        isEmpty = false;
      }
    }

    setBody(isEmpty ? [] : Object.keys(times).map((step) => <Slide key={step} data={times[step]} step={step} />));

    // eslint-disable-next-line
  }, [data]);

  return body.length > 0 ? (
    <>
      <Slider {...settings}>{body}</Slider>
    </>
  ) : (
    <div className="lc-no-schedules">{translate("stop-timetable-no-schedules") + " " + timetableStop}</div>
  );
};

/**
 * Retrieve all transport information around a position
 * @param component
 * @param position
 * @area stop_area id
 */
export const around = async (component, position, area = null) => {
  const { map, openedMarker, touchscreenSelected, linesModes, radius } = component.props;
  const { pathname } = history.location;
  const params = getURLSearchParams(history.location);
  let refCircle;

  try {
    const response = await axios({
      url: "/api/around",
      params: {
        lat: position[0],
        lon: position[1],
        radius: +radius,
        area: area,
      },
    });

    let pinIcon = {
      iconUrl: assetsPath("/assets/images/pin.svg"),
      iconSize: [50, 50],
      iconAnchor: [25, 25],
    };
    const testBorne = touchscreenSelected ? [...position].reverse().join(";") : null;

    if (testBorne && touchscreenSelected.coords === testBorne) {
      pinIcon = {
        iconUrl: assetsPath("/assets/images/you_are_here.svg"),
        iconSize: [70, 70],
        iconAnchor: [35, 70],
      };
    }

    // Pin marker
    const pin = (
      <Marker
        draggable
        onDragstart={component.removePinCircle}
        onDragend={(event) => {
          history.push({
            pathname,
            search: "?from=" + substringCoords(event.target.getLatLng()),
          });
        }}
        options={{ zIndex: 999 }}
        icon={L.icon(pinIcon)}
        position={position}
      />
    );

    const circle = (
      <Circle
        center={position}
        ref={(r) => (refCircle = r)}
        radius={+radius}
        fillColor="rgba(0, 0, 0)"
        fillOpacity="0.10"
        color="transparent"
      />
    );

    // Retrieve lines
    const lines = response.data.shift();
    // Retrieve transport pois
    const pois = lines.pop();
    const groups = groupLinesByMode(unique(lines, "id"), linesModes, "mode"); // Make the array unique by the lines ID and order them by group
    const dataPlaces = response.data.shift();

    const places = Object.keys(dataPlaces).reduce(function (item, key) {
      item[key] = dataPlaces[key];
      return item;
    }, {});

    // Google Tag Manager
    // TODO Optimize
    if (Object.keys(params).length === 1) {
      const type = params.from.includes("stop_area:") ? "Arrêt" : params.from.includes("poi:") ? "Lieu" : "Adresse";

      updateDataLayer({
        event: "map-searchMap",
        searchType: type,
        searchTerm: component.props.inputValue,
      });
    }

    component.setState(
      {
        groups,
        pois,
        places,
      },
      () => {
        const markers = map.state.markers;
        const path = history.location.pathname;

        const state = {
          pin: path.includes("around") ? pin : null,
          circle,
          markers: [...markers],
          selectedInfobox: null,
          infoboxs: [],
        };

        map.setState({ ...state }, async () => {
          refCircle &&
            (!openedMarker || !params.line) &&
            !params.stop &&
            fitBounds(map, null, -1, refCircle.leafletElement.getBounds());
          resize(map.props.isMobile);

          // Scroll to the top of the content if we are on mobile
          if (map.props.isMobile) {
            setTimeout(() => {
              const element = document.querySelector("[data-lc-board]");

              element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop);
            }, 250);
          }
        });
      }
    );
  } catch (e) {
    console.warn("Error : ", e.response && e.response.data && e.response.data.id);
    component.setState({
      error:
        e.response && e.response.data.id === "no-places"
          ? translate("around-error-no-places")
          : translate("around-error-unknow"),
    });
  }
};

export const displayLineDecorator = (polyline, section, pmr) => {
  const lineLink = section.links.find((link) => link.type === "line");
  let lineStyle = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";

  if (REACT_APP_LINES_TYPE_EXCEPTIONS && lineLink) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
    const foundExceptedLine = exceptions.find((e) => e.lines.includes(lineLink.id));

    if (foundExceptedLine) {
      lineStyle = foundExceptedLine.type;
    }
  }

  return (
    <PolylineDecorator
      key={section.id + Math.random() + "_decorator"}
      pmr={pmr}
      section={section}
      path={polyline.props.positions}
      lineStyle={lineStyle}
    />
  );
};

/**
 * Display polylines for a line
 * @param line
 * @param map
 * @param args
 */
export const displayLinePath = async (line, map, ...args) => {
  const { pathname, search } = history.location;
  const [isSection, main, index, component] = args;
  const infos = isSection ? line.display_informations : null;

  // TODO waiting sections
  if (isSection && line.type === "waiting") {
    return;
  }

  try {
    if (!isSection) {
      await axios
        .get(`/api/file?folder=routes&ext=geojson&name=${line.code}_${line.network}_${line.direction_id}`)
        .then((result) => {
          line.geojson = result.data;
        })
        .catch((e) => {
          const error = e.response && e.response.data ? e.response.data.id : e;

          console.warn(error);
          line.geojson = null;
        });
    }

    // Save others polylines not from the line selected
    let oldPolylines = pathname.includes("lines")
      ? map.state.polylines.filter((oldPolyline) => line.code !== oldPolyline.key.split("_").shift())
      : [];
    const newPolylines = [];

    const lineOptions = {
      color: "#" + (infos ? infos.color : line.color),
      opacity: 1,
      weight: 6,
      zIndex: 7,
      main,
    };

    // Street network or transfer, black dashed
    if (isSection && (line.type === "street_network" || line.type === "transfer" || line.type === "crow_fly")) {
      lineOptions.color = "#333";
      lineOptions.opacity = 1;
      lineOptions.weight = 4;
      lineOptions.dashArray = "5 12";
    }

    if (isSection && !main) {
      lineOptions.color = colors.secondaryjourney;
      lineOptions.opacity = 0.6;
      lineOptions.zIndex = 5;
      lineOptions.weight = 4;
    }

    let polyline;

    if (line.geojson) {
      if (!isSection) {
        let index = 0;

        for (const feature of line.geojson.features) {
          let zoom = feature.properties.zoom;
          const length = feature.geometry.coordinates.length;
          const path = length === 1 ? getCoordinates(feature).pop() : getCoordinates(feature);

          // Push line
          polyline = (
            <Polyline
              key={(isSection ? line.id + "_aaa" : line.code) + (infos ? "_" + infos.code : "") + "_" + index}
              positions={path}
              zoom={zoom}
              {...lineOptions}
            />
          );
          newPolylines.push(polyline);

          index++;
        }
      } else {
        const path = getCoordinates(line.geojson).pop();

        // Push line
        polyline = (
          <Polyline
            ref={(poly) => !main && poly && poly.leafletElement.bringToBack()}
            key={line.id + (infos ? "_" + infos.code : "") + Math.random()}
            positions={path}
            {...lineOptions}
            onMouseOver={() => {
              component.displayJourneys(component.state.journeys, map, index, true);
            }}
            onClick={() => {
              !search.includes("journey") &&
                history.push({
                  pathname,
                  search: search + "&journey=" + index,
                });
            }}
          />
        );

        newPolylines.push(polyline);
      }
    }

    if (isSection) {
      return newPolylines;
    }

    // Concat selected lines with old lines
    oldPolylines = oldPolylines.concat(newPolylines);

    return oldPolylines;
  } catch (error) {
    throw error;
  }
};

/**
 * Display places in toDisplay array in base map
 * @param component
 * @param toDisplay
 */
export const displayBaseMapPlaces = (component, toDisplay) => {
  const { map } = component.props;
  const markers = [];

  axios
    .get("/api/file?name=places")
    .then((result) => {
      for (const place of result.data) {
        if (toDisplay.includes(place.cat_id.split("poi_type:")[0])) {
          const code = place.code.split("_")[0];

          const marker = {
            id: place.id,
            coord: place.coord,
            name: place.name,
            code,
          };

          const markerRendered = renderMarker(component, marker, {
            icon: L.icon({
              iconUrl: assetsPath("/assets/images/places/") + code + ".svg",
              iconSize: [50, 50],
              iconAnchor: [25, 18],
            }),
            marker,
            zIndex: 30,
          });

          markers.push(markerRendered);
        }
      }

      map.setState({ markersMap: markers });
    })
    .catch((e) => {
      const error = e.response && e.response.data ? e.response.data.id : e;

      console.warn(error);
    });
};

/**
 * Fit the bound of map with given objects (Polylines or Markers)
 * @param map
 * @param objects
 * @param pad // extra padding on each side
 * @param bounds
 */
export const fitBounds = (map, objects, pad = 0, bounds = L.latLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (!object.props) {
        bounds.extend(object);
      } else if (object.props.data) {
        // GeoJSON
        // ! TODO need to be tested on all case posssible
        if (object.props.data.features.length > 1) {
          for (const feature of object.props.data.features) {
            if (feature.geometry.type === "LineString") {
              feature.geometry.coordinates.map((c) => bounds.extend([c[1], c[0]]));
            } else if (feature.geometry.type === "MultiLineString") {
              for (const coords of feature.geometry.coordinates) {
                bounds.extend(coords.map((c) => [c[1], c[0]]));
              }
            }
          }
        } else {
          const trace = object.props.data.features[0].geometry;

          if (trace.type === "LineString") {
            trace.coordinates.map((c) => bounds.extend([c[1], c[0]]));
          } else if (trace.type === "MultiLineString") {
            for (const coords of trace.coordinates) {
              bounds.extend(coords.map((c) => [c[1], c[0]]));
            }
          }
        }
      } else if (object.props.positions) {
        // Polylines or Polygon
        bounds.extend(object.props.positions);
      } else if (object.props.position) {
        // Marker
        bounds.extend(object.props.position);
      } else if (object.props.center) {
        // Circle
        bounds.extend(object.props.center);
      } else {
        console.warn("You shall not pass");
      }
    }
  }

  // Detect if we have no board, if no, no left offset
  const noBoard = document.querySelector(".lc-no-board");
  // ! TODO Change for not large
  let topLeft = L.point(noBoard ? 0 : 460, pad < 0 ? 0 : 50);
  let bottomRight = L.point(0, pad < 0 ? 0 : 50);

  if (map.props.isMobile) {
    const popup = document.querySelector(".leaflet-popup");

    topLeft = L.point(popup ? -popup.offsetWidth : 0, popup ? -popup.offsetHeight / 2 : 0);
    //bottomRight = L.point(0, document.querySelector(appStore.getState().app.domElement).scrollTop);
  }

  if (pad > 0) {
    bounds.extend(bounds.pad(pad));
  }

  // Fit the map component with the calculated bounds
  if (bounds.isValid()) {
    map.setState({
      bounds,
      boundsOptions: {
        paddingTopLeft: topLeft,
        paddingBottomRight: bottomRight,
        animate: false,
      },
    });
  }
};

/**
 *
 * @param feature
 * @returns {Array}
 */
export const getCoordinates = (feature) => {
  const paths = [];
  let type = feature.type;

  if (!type || type.includes("Feature")) {
    feature = feature.geometry;
    type = feature.type;
  }

  switch (type) {
    case "LineString":
      const linePath = [];

      for (const coord of feature.coordinates) {
        linePath.push([coord[1], coord[0]]);
      }

      paths.push(linePath);
      break;

    case "MultiLineString":
      for (const line of feature.coordinates) {
        const linePath = [];

        for (const coord of line) {
          linePath.push([coord[1], coord[0]]);
        }

        paths.push(linePath);
      }

      break;

    default:
      console.warn("Geometry type not found");
  }

  return paths;
};

/**
 * Retrieve a line object from it's id
 * @returns Object
 * @param component
 * @param object
 */
export const getLine = (component, object) => {
  const { lines } = component.props;

  return {
    ...lines.find((line) => line.id === object.id),
    ...object,
  };
};

export const goRouteCalculation = (component, object) => {
  const { map } = component.props;

  history.push(`/route-calculation?to=${object}`);
  map.setState({
    markers: [],
    markersPlaces: [],
    infoboxs: [],
  });
};

/**
 * Retrieve a stop object from it's id
 * @returns Object
 * @param component
 * @param object
 * @param line
 */
export const getStop = (component, object, line) => {
  const { areas, stops } = component.props;
  let res = object.id;

  if (object.id.includes("stop_area")) {
    res = areas
      .find((a) => a.id === object.id.replace("-pole-exchange", ""))
      .lines.find((l) => l.id === line.id && l.direction_id === line.direction_id).stop_id;
  }

  // TODO if id is switch with stop_point instead of stop_area, look at here :D
  return {
    ...object,
    ...stops.find((s) => s.id === res),
  };
};

/**
 * Invert coord
 * @param array
 * @returns {*}
 */
export const invertCoords = (array) => {
  return array.geometry.coordinates[0].map((coord) => [coord[1], coord[0]]);
};

/**
 * Remove the current line from a given component
 * @param component
 * @param line
 * @param lineList
 */
export const removeLine = (component, line = null, lineList = false) => {
  const { map } = component.props;
  const { pathname, search } = history.location;
  const params = getURLSearchParams(history.location);

  map.setState({
    terminus: false,
    infoboxsTerminus: [],
    polylineDecorators: [],
  });

  if (!line) {
    map.setState({
      selectedInfobox: null,
      infoboxs: [],
      polylines: [],
    });

    component.setState({
      currentLine: null,
    });
  } else {
    if (pathname !== "/lines") {
      history.push({
        pathname,
        search: search.replace("&line=" + params.line, ""),
      });
    } else {
      const { currentLine, selectedLines } = component.state;

      if (params.current === line.id + "_" + line.direction_id) {
        if (params.selected) {
          const newSelectedLines = selectedLines.filter((selectLine) => selectLine.id !== line.id);
          const newCurrent = newSelectedLines[0];
          const current = newCurrent.id + "_" + newCurrent.direction_id;
          let selection = "";

          for (const stateLine of newSelectedLines) {
            if (stateLine.id !== newCurrent.id || lineList) {
              selection += stateLine.id + "_" + stateLine.direction_id + ",";
            }
          }

          if (lineList) {
            history.push({ pathname, search: "?selected=" + selection });
          } else {
            history.push({
              pathname,
              search: "?current=" + current + "&selected=" + selection,
            });
          }
        } else {
          history.push({ pathname });
        }
      } else {
        let current = null;

        if (currentLine) {
          current = currentLine.id + "_" + currentLine.direction_id;
        }

        let selection = current ? "&selected=" : "?selected=";
        const linesSelectedToKeep = selectedLines.filter((selectLine) => selectLine.id !== line.id);

        if ((linesSelectedToKeep.length === 1 && current) || linesSelectedToKeep.length === 0) {
          selection = "";
        } else {
          for (const lineToKeep of linesSelectedToKeep) {
            if (current) {
              if (lineToKeep.id !== currentLine.id) {
                selection += lineToKeep.id + "_" + lineToKeep.direction_id + ",";
              }
            } else {
              selection += lineToKeep.id + "_" + lineToKeep.direction_id + ",";
            }
          }
        }

        if (!lineList && current) {
          history.push({ pathname, search: "?current=" + current + selection });
        } else {
          history.push({ pathname, search: selection });
        }
      }
    }
  }
};

/**
 * Remove map state event
 * @param map
 */
export const removeMapEvents = (map) => {
  if (!map) {
    return;
  }

  map.setState({ events: {} });
};

/**
 * Render an infobox on a specific marker
 * If onLineSelected, the infobox will stay open onClick
 * @param component   used in board
 * @param stop
 * @param selected
 */
// TODO bug fitbounds
export const renderInfobox = (component, stop, selected) => {
  // Get lines by stop_area
  const area = stop.id.includes("stop_area") ? stop : getStop(component, { id: stop.id });

  // Display tooltip
  if (selected) {
    stop.ref && (stop.ref.clicked = true);

    /* if (component.state.markerRef && component.state.markerRef !== stop.ref) {
      component.state.markerRef.leafletElement.setTooltipContent(component.state.selectedMarker.name)
    } */

    component.setState({
      selectedMarker: stop,
    });
  }

  return (
    <div className="lc-infobox lc-no-arrow">
      <div className="lc-infobox-title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {stop.name}
        </div>
        <>
          {REACT_APP_SHOW_PMR && stop.pmr
            ? REACT_APP_SHOW_PMR === "pmr" && <div className="lc-is-pmr" />
            : REACT_APP_SHOW_PMR === "no-pmr" && <div className="lc-is-no-pmr" />}
          {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
            JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
              if (stop[tool]) {
                return <div key={`${stop.id}_${tool}`} className={`lc-is-${tool}`} />;
              } else {
                return false;
              }
            })}
          {selected && !stop.id.includes("TAD") && (
            <Tippy
              theme={"latitude"}
              touch={["hold", 500]}
              placement={"right"}
              boundary="window"
              content={translate("title-go-to-route-calculation")}
            >
              <span
                className="lc-is-tool-route-calculation lc-is-toolSmall"
                onClick={(e) => {
                  e.stopPropagation();
                  const coord = stop.coord.lon + ";" + stop.coord.lat;

                  stop.id.includes("stop")
                    ? goRouteCalculation(component, stop.id)
                    : goRouteCalculation(component, coord);
                }}
                onKeyPress={(e) =>
                  handleKeyPress(e, () => {
                    const coord = stop.coord.lon + ";" + stop.coord.lat;

                    stop.id.includes("stop")
                      ? goRouteCalculation(component, stop.id)
                      : goRouteCalculation(component, coord);
                  })
                }
                role="button"
                tabIndex="0"
              >
                <img
                  src={assetsPath("/assets/images/menu/route-calculation.svg")}
                  alt={translate("route-calculation-board-title")}
                />
              </span>
            </Tippy>
          )}
        </>
      </div>
      {area && (
        <div className="lc-infobox-content">
          {stop.stand ? (
            <div className="lc-place">
              <div>{stop.address.label}</div>
              {stop.data && stop.data.stand && (
                <div className="lc-bss">
                  <span className="lc-bikes">
                    {stop.stand.available_bikes}
                    <img src={assetsPath("/assets/images/modes/bss.svg")} alt={translate("bss-bikes-available")} />
                  </span>
                  <span className="lc-seats">
                    {stop.stand.available_places}
                    <img src={assetsPath("/assets/images/bss-seat.svg")} alt={translate("available-places")} />
                  </span>
                </div>
              )}
            </div>
          ) : stop.poi_type && stop.poi_type.id === "poi_type:amenity:parking" ? (
            <div className="lc-place">
              <div>{stop.address.label}</div>
            </div>
          ) : (
            renderLinesLabels(component, area.lines, "infobox", stop)
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Render a line on a given coponent
 * @param component
 * @param line
 */
export const renderLine = (component, line) => {
  !line && (line = component.state.currentLine);
  const { pathname, search } = history.location;
  const params = getURLSearchParams(history.location);
  const { stopsList, timetable, loadingTimetable } = component.state;
  const { options } = component.props;
  const showSchedules = options?.features?.["schedules"] === false ? false : true;
  const showTimetable = options?.features?.["timetable-button"] === false ? false : true;

  const {
    disruptionsInLine,
    map,
    language,
    touchscreenSelected,
    linesModes,
    openedCollapse,
    reduxMarkers,
  } = component.props;

  const { isMobile } = map.props;

  if (line.cat === "TAD-markers-only") {
    sortBy(stopsList, "name");
  }

  const pickerStyle = {
    calendar: {
      top: map && !map.props.isMobile ? "30px" : "",
      bottom: map && map.props.isMobile ? "28%" : "",
      left: map && map.props.isMobile ? "0" : "",
      right: map && map.props.isMobile ? "0" : "",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
    },
    colon: {
      padding: "0 5px 0 0 !important",
    },
    control: {
      boxShadow: "none",
      cursor: "pointer",
    },
    first: "#005e86",
    menu: {
      marginLeft: -5,
      position: "fixed",
      bottom: map && map.props.isMobile ? "25%" : "",
      top: "",
    },
    weekDays: {
      padding: "5px 0",
    },
    monthSelected: {
      fontWeight: 600,
    },
    calendarButtonStyle: {
      fontSize: ".875em",
    },
    inputsHours: {
      fontSize: "1em",
    },
    today: {
      background: "#f4f4f4",
      color: "#333",
      fontWeight: "500",
    },
  };

  pickerStyle.first = colors.primarycolor;
  pickerStyle.calendarButtonStyle = {
    ...pickerStyle.calendarButtonStyle,
    margin: 10,
    border: "2px solid rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    marginRight: 10,
    padding: 8,
  };
  pickerStyle.calendar = {
    ...pickerStyle.calendar,
    padding: 15,
    background: "#f1f5f5",
  };
  pickerStyle.week = {
    ...pickerStyle.week,
    background: "#fff",
  };

  // Color terminus tooltip
  // TODO find a better way :-)
  document.querySelectorAll(".lc-tooltip-leaflet-terminus").forEach((div) => {
    div.style.backgroundColor = "#" + line.color;
    div.style.borderColor = "#" + line.color;
    div.style.color = luminance(line.color) > 0.5 ? "#333" : "#fff";
  });
  const lineMode = linesModes.find((mode) => mode.modes.includes(line.mode));
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";

  if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
    const foundExceptedLine = exceptions.find((e) => e.lines.includes(line.id));

    if (foundExceptedLine) {
      styleLine = foundExceptedLine.type;
    }
  }

  // Filter all disruptions and get only the one impacted by this route
  const disruptionsInLineForThatRoute = [];
  const route = line.routes.find((r) => r.direction_id === line.direction_id);
  const impacted_objects = [];

  // Do we have disruptions ?
  if (disruptionsInLine) {
    for (const disruption of disruptionsInLine) {
      for (const impacted of disruption.impacted_objects) {
        // If the disruption has already been stored, skip it
        if (disruptionsInLineForThatRoute.indexOf(disruption) === -1) {
          if (impacted.routes && impacted.routes.find((r) => r.id === route.route_id)) {
            // Section disruptions
            disruptionsInLineForThatRoute.push(disruption);
            impacted_objects.push(...disruption.impacted_objects.filter((impacted) => impacted.line === line.id));
          } else if (impacted.id) {
            // Stop disrutpions
            if (stopsList.find((s) => s[impacted.type === "stop_area" ? "stop_area" : "id"] === impacted.id)) {
              disruptionsInLineForThatRoute.push(disruption);
              impacted_objects.push(impacted);
            }
          } else if (impacted.type === "line") {
            // Line disruptions
            disruptionsInLineForThatRoute.push(disruption);
          }
        }
      }
    }
  }

  // Retrieve disruptions in line sorted by begin date
  const disruptions = disruptionsInLineForThatRoute
    ? sortBy(disruptionsInLineForThatRoute, "begin").map((disruption) => (
        <div key={disruption.id} className={"lc-" + disruption.severity}>
          <div className="lc-disruptionSeverity lc-white">
            <div className="lc-icon" />
            {disruption.severity === "blocking"
              ? "Perturbation majeure"
              : disruption.severity === "delays"
              ? "Perturbation"
              : "Information"}
          </div>
          <div
            className="lc-disruption"
            tabIndex="0"
            aria-label={
              disruptionsDatetime(disruption.begin, disruption.end, language) +
              " : " +
              disruption.message.replace(/<[^>]+>/g, " ")
            }
          >
            {disruption.title && <div className="lc-disruptionTitle">{disruption.title}</div>}
            {disruption.message ? (
              <div className="lc-disruptionContent" dangerouslySetInnerHTML={{ __html: disruption.message }} />
            ) : (
              <div className="lc-disruptionContent empty">Aucun motif renseigné</div>
            )}
            <div className="lc-disruption-dates">
              {disruptionsDatetime(disruption.begin, disruption.end, language)}
              {disruption.periods.length > 1 && (
                <>
                  <div
                    className="lc-disruption-dates-more"
                    onClick={() => {
                      const disruptions = Array.from(disruptionsInLine);
                      const current = disruptions.find((d) => d.id === disruption.id);

                      current.opened = !current.opened;

                      appStore.dispatch(actionSetDisruptionInLine(disruptions));
                    }}
                    onKeyPress={(e) =>
                      handleKeyPress(e, () => {
                        const disruptions = Array.from(disruptionsInLine);
                        const current = disruptions.find((d) => d.id === disruption.id);

                        current.opened = !current.opened;

                        appStore.dispatch(actionSetDisruptionInLine(disruptions));
                      })
                    }
                    role="button"
                    tabIndex="0"
                  >
                    {translate("display-disruptions-more-dates")}
                  </div>
                  <Collapse isOpen={!!disruption.opened}>
                    <div className="lc-collapsed">
                      {Array.from(disruption.periods)
                        .splice(1)
                        .map((period) => (
                          <div key={period.begin}>{disruptionsDatetime(period.begin, period.end, language)}</div>
                        ))}
                    </div>
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </div>
      ))
    : [];

  const clickSwap = (line) => {
    const { areas, stops } = component.props;
    let { openedMarker } = component.props;

    line.direction_id = line.direction_id === "f" ? "b" : "f";

    if (openedMarker) {
      const area = areas.find((area) => area.id === openedMarker.stop_area);

      const found = area.lines.find(
        (itemLine) =>
          itemLine.id === line.id &&
          (itemLine.stop_id !== openedMarker.id || line.direction_id === itemLine.direction_id)
      );

      if (!found) {
        appStore.dispatch(
          actionSetLineInformation(
            `${translate("stop-timetable-direction-not-deserved")} (<strong>${openedMarker.name}</strong>)`
          )
        );
        appStore.dispatch(actionOnLineSelected(line));
        return;
      }

      const stop = stops.find((item) => item.id === found.stop_id);

      appStore.dispatch(
        actionOnLineSelected(line, envVarToBool(REACT_APP_DONT_DISPLAY_STOP_AT_LINE_SELECTION) ? [] : stop)
      );
    } else {
      appStore.dispatch(actionOnLineSelected(line));
    }
  };

  const clickDownloadTimetable = (timetable) => {
    // Google Tag Manager
    updateDataLayer({
      event: "map-downloadTimetable",
      line: component.state.currentLine.code,
    });

    if (REACT_APP_TIMETABLES === "local") {
      window.open("/" + timetable.file);
    } else if (REACT_APP_TIMETABLES === "api") {
      window.open(timetable.file);
    }
  };

  const clickPrintTimetable = () => {
    component.setState({
      printing: true,
    });

    const timetable = document.querySelector(".slick-track");
    const scale = 2;
    const currentLine = component.state.currentLine;
    const date = params.date && params.date.substring(0, 4) + params.date.substring(4, 6) + params.date.substring(6, 8);
    const dateFormat = isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY";

    // Hide the otherDirections on both 2nd & 3rd slide
    Array.from(timetable.querySelectorAll("[data-lc-other-directions]")).map(
      (item, index) => index > 0 && (item.style.display = "none")
    );
    Array.from(timetable.querySelectorAll("[data-lc-notes-timetable")).map(
      (item, index) => index > 0 && (item.style.display = "none")
    );

    const hours = Array.from(document.querySelectorAll(".lc-timetable th"));

    for (const hour of hours) {
      hour.style.top = 0;
    }

    domtoimage
      .toPng(timetable, {
        scale,
        style: {
          transform: "none",
          background: "white",
        },
      })
      .then(async (dataurl) => {
        // Create PDF
        const jspdf = new jsPDF({ // eslint-disable-line
          orientation: "portrait",
          unit: "mm",
          format: "A4",
          compress: true,
        });

        const pdfWidth = jspdf.internal.pageSize.width - 20;

        // Add all needed text
        jspdf.setTextColor(100);
        jspdf.setFontSize(11);
        jspdf.setFontType("bold");
        jspdf.text(
          translate("timetable-print-date") + " " + moment(date).format(dateFormat),
          jspdf.internal.pageSize.width / 2,
          10,
          "center"
        );

        const lineImg = new Image();
        const lineSelected = document.querySelector("[data-lc-line-header] .lc-line");

        await domtoimage
          .toPng(lineSelected, {
            scale: 1,
            style: {
              transform: "none",
            },
          })
          .then((lineSelectedImg) => {
            lineImg.src = assetsPath(lineSelectedImg);
          });

        lineImg.onload = () => {
          // TODO multi page print
          jspdf.addImage(lineImg, "PNG", 10, 18.5, lineImg.width / 5, lineImg.height / 5);

          jspdf.setFontSize(9);

          if (currentLine.routes.length > 1) {
            jspdf.text(
              currentLine.routes.find((r) => r.direction_id !== currentLine.direction_id).name,
              lineImg.width / 5 + 13,
              currentLine.routes.length > 1 ? 22 : 24.5
            );
            jspdf.text(
              currentLine.routes && currentLine.routes.find((r) => r.direction_id === currentLine.direction_id).name,
              lineImg.width / 5 + 13,
              26.5
            );
          } else {
            jspdf.text(
              currentLine.routes[currentLine.direction_id === "f" && currentLine.routes.length > 1 ? 1 : 0].name,
              lineImg.width / 5 + 13,
              currentLine.routes.length > 1 ? 22 : 24.5
            );
          }

          jspdf.setFontType("normal");
          jspdf.text(translate("stop") + " : " + component.state.timetableStop, 10, 37);
          jspdf.text(
            translate("line-direction") +
              " : " +
              currentLine.routes.find((r) => r.direction_id === currentLine.direction_id).name,
            10,
            42
          );

          const img = new Image();

          img.src = dataurl;

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            if (width > pdfWidth) {
              width = pdfWidth;
              height = height / (img.width / pdfWidth);
            }

            // TODO multi page print
            jspdf.addImage(dataurl, "PNG", 10, 50, width, height);

            const browser = detect();

            if (!isMobile && browser.name !== "edge") {
              jspdf.output("dataurlnewwindow");
            }

            saveAs(jspdf.output("blob"), "grille-horaire.pdf");

            component.setState({
              printing: false,
            });
          };
        };
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);

        component.setState({
          printing: false,
        });
      });

    // Show the otherDirections on both 2nd & 3rd slide
    setTimeout(() => {
      Array.from(timetable.querySelectorAll("[data-lc-other-directions]")).map(
        (item, index) => index > 0 && (item.style.display = "block")
      );
      Array.from(timetable.querySelectorAll("[data-lc-notes-timetable]")).map(
        (item, index) => index > 0 && (item.style.display = "flex")
      );
    }, 200);
  };

  return (
    <div className="lc-elevation">
      <div className="lc-active-line">
        <div className="lc-line-header" data-lc-line-header key={line.id}>
          {
            {
              modeWithDirection: (
                <>
                  <div
                    className="lc-line lc-mode lc-with-direction"
                    style={{
                      background: "#" + line.color,
                      color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {lineMode.name}
                  </div>
                  <span className="lc-direction">{line.name}</span>
                </>
              ),
              codeWithDirection: (
                <>
                  <div
                    className="lc-line lc-code lc-with-direction"
                    style={{
                      background: "#" + line.color,
                      color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {line.code}
                  </div>
                  <span className="lc-direction">{line.name}</span>
                </>
              ),
              image: (
                <>
                  <div className="lc-line" style={{ padding: "10px" }}>
                    <img src={assetsPath("/assets/images/lines/" + line.code + ".svg")} alt={line.code} />
                  </div>
                  <span className="lc-direction">
                    {translate("line-direction")} :<br />
                    <strong>{line.routes.find((r) => r.direction_id === line.direction_id).name}</strong>
                  </span>
                </>
              ),
              color: (
                <>
                  <div className="lc-line">
                    <span
                      className={"lc-line-code"}
                      style={{
                        background: "#" + line.color,
                        color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {line.code}
                    </span>
                  </div>
                  <span className="lc-direction">
                    {translate("line-direction")} :<br />
                    <strong>{line.routes.find((r) => r.direction_id === line.direction_id).name}</strong>
                  </span>
                </>
              ),
            }[styleLine]
          }
          <div className="lc-tools">
            <>
              {line.routes.length > 1 && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={translate("title-swap")}
                >
                  <span
                    className="lc-tool-swap"
                    onClick={() => clickSwap(line)}
                    onKeyPress={(e) => handleKeyPress(e, () => clickSwap(line))}
                    role="button"
                    tabIndex="0"
                  />
                </Tippy>
              )}
              {!component.state.timetable &&
                !touchscreenSelected &&
                (REACT_APP_TIMETABLES === "api" || REACT_APP_TIMETABLES === "local") && (
                  <Tippy
                    theme={"latitude"}
                    touch={["hold", 500]}
                    placement={"right"}
                    boundary="window"
                    content={translate("title-download-timetable")}
                  >
                    <span
                      className={"lc-tool-timetable"}
                      onClick={() => collapseTimetableOptions(component)}
                      onKeyPress={(e) => handleKeyPress(e, () => collapseTimetableOptions(component))}
                      role="button"
                      tabIndex="0"
                    />
                  </Tippy>
                )}
            </>
          </div>
          <div className={"lc-timetableOptions"} data-lc-timetable-options>
            <Collapse isOpen={!!component.state.timetableOptions}>
              {component.state.timetableLineData ? (
                component.state.timetableLineData.length > 0 ? (
                  <>
                    {component.state.timetableLineData.map((timetable) => (
                      <div
                        key={timetable.id}
                        className="lc-timetableOptionsItem"
                        target="_blank"
                        onClick={() => clickDownloadTimetable(timetable)}
                        onKeyPress={(e) => handleKeyPress(e, () => clickDownloadTimetable(timetable))}
                        role="button"
                        tabIndex="0"
                      >
                        {timetable.name}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="lc-timetableOptionsItem">{translate("no-timetable")}</div>
                )
              ) : (
                !component.state.directDownload && (
                  <div className="lc-loading" data-lc-loading>
                    <img src={assetsPath("/assets/images/loading.gif")} width={15} alt={translate("loading")} />
                  </div>
                )
              )}
            </Collapse>
          </div>
        </div>
        {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL && (
          <div className="lc-line-stop-informations">
            {JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
              if (stopsList.find((s) => s[tool] && s[tool].includes(`${line.code}_${line.network}`))) {
                const phoneReg = /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0[\s.-]|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/gm;
                const linkReg = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gim;
                let text = translate(`stop-info-${tool}`);

                text = text.replace(phoneReg, `<a href="tel:$&">$&</a>`);
                text = text.replace(linkReg, `<a href="$&" target="_blank">$&</a>`);

                return (
                  <div key={`${tool}`} className="lc-line-stop-information">
                    <div className={`lc-is-${tool}`} />
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                );
              }

              return null;
            })}
          </div>
        )}
        {line.tad && line.tad.booking && (
          <>
            <div className="lc-line-tad-header">{translate("tad-booking")}</div>
            <div className="lc-line-tad-informations">
              {line.tad.booking.phone && (
                <a className="lc-line-tad-phone" href={"tel:" + line.tad.booking.phone}>
                  <img src={assetsPath("/assets/images/phone.svg")} alt={translate("tad-phone-booking")} />
                  {line.tad.booking.phone}
                </a>
              )}
              {line.tad.booking.website && (
                <a
                  className="lc-line-tad-website"
                  href={line.tad.booking.website.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={assetsPath("/assets/images/website.svg")} alt={translate("tad-web-booking")} />
                  {line.tad.booking.website.name}
                </a>
              )}
            </div>
          </>
        )}
        {line.errorPath && (
          <div className="lc-error">
            <img src={assetsPath("/assets/images/error.svg")} alt={translate("severity-error")} />
            {translate("error-cant-open-line-geojson")} {line.code}
          </div>
        )}

        {timetable ? (
          <>
            <div className="lc-timetable-stop">
              <span>
                {translate("stop")} : <strong>{component.state.timetableStop}</strong>
              </span>
              {!timetableDataIsEmpty(component.state.timetableData) && !touchscreenSelected && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={translate("title-print")}
                >
                  <div
                    className="lc-print"
                    onClick={() => clickPrintTimetable()}
                    onKeyPress={(e) => handleKeyPress(e, () => clickPrintTimetable())}
                    role="button"
                    tabIndex="0"
                    title={translate("title-print")}
                    aria-label={translate("aria-lines-print-timetable", false, {
                      key: "stop",
                      value: component.state.timetableStop,
                    })}
                  />
                </Tippy>
              )}
            </div>
            <div
              className="lc-scroll"
              data-lc-scroll="scroll"
              onScroll={(e) => {
                const hours = Array.from(document.querySelectorAll(".lc-timetable th"));

                for (const hour of hours) {
                  hour.style.top = e.target.scrollTop - 95 + "px";
                }
              }}
            >
              <DateCalendar
                lang={language}
                systemUS={isSystemUS(language)}
                style={pickerStyle}
                todayTxt={translate("calendar-today-word")}
                image={assetsPath("/assets/images/calendar.svg")}
                arrow={assetsPath("/assets/images/arrow-calendar.svg")}
                setDate={params.date}
                getSelectedDate={(getSelectedDate) => {
                  const date = getSelectedDate.format("YYYYMMDD");

                  if (!params.date || params.date !== date) {
                    history.push({
                      pathname,
                      search: search.split("&date=")[0] + "&date=" + date,
                    });
                  }
                }}
              />
              {loadingTimetable ? (
                <img src={assetsPath("/assets/images/loading.gif")} width={30} alt={translate("loading")} />
              ) : (
                <Timetable
                  data={component.state.timetableData}
                  line={component.state.currentLine}
                  timetableStop={component.state.timetableStop}
                />
              )}
            </div>
          </>
        ) : (
          <div
            className={"lc-stops lc-scroll" + (line.tad && !line.tad.thermo ? " lc-tad-no-thermo" : "")}
            data-lc-stops
            data-lc-scroll="scroll"
          >
            {component.props.lineInformation && (
              <div className="lc-delays">
                <div
                  className="lc-disruption"
                  dangerouslySetInnerHTML={{
                    __html: component.props.lineInformation,
                  }}
                />
              </div>
            )}
            {REACT_APP_DISRUPTION && JSON.parse(REACT_APP_DISRUPTION).collapse && disruptions.length > 0 ? (
              <div className="lc-disruptions" data-lc-disruptions>
                <div
                  className="lc-disruptions-head"
                  onClick={() => {
                    appStore.dispatch(actionSetOpenedCollapse("disruptions-line"));
                  }}
                  onKeyPress={(e) =>
                    handleKeyPress(e, () => {
                      appStore.dispatch(actionSetOpenedCollapse("disruptions-line"));
                    })
                  }
                  role="button"
                  tabIndex="0"
                  aria-expanded={openedCollapse === "disruptions-line" ? "true" : "false"}
                  aria-controls="lc-disruptions-line"
                  aria-label={translate("display-disruptions")}
                >
                  <span>{translate("display-disruptions")}</span>
                  <div className="lc-arrow">
                    <img
                      className={openedCollapse !== "disruptions-line" ? "lc-closed" : ""}
                      src={assetsPath("/assets/images/v.svg")}
                      alt={translate("collapse-arrow")}
                    />
                  </div>
                </div>
                <Collapse isOpen={openedCollapse === "disruptions-line"} id="lc-disruptions-line">
                  {disruptions}
                </Collapse>
              </div>
            ) : (
              disruptions.length > 0 && disruptions
            )}
            {stopsList.map((stop, index) => (
              <div key={stop.index} className={"lc-stop" + (stop.opened && touchscreenSelected ? " selected" : "")}>
                {line.cat !== "TAD-markers-only" && (
                  <>
                    {(!line.tad || (line.tad && line.tad.thermo)) && (
                      <div
                        className={"lc-border" + (index === 0 ? " lc-first-border" : "")}
                        style={{ borderLeftColor: "#" + line.color }}
                      />
                    )}
                    {REACT_APP_DISRUPTION &&
                    reduxMarkers &&
                    reduxMarkers.find((m) => m.key === line.code + "_" + stop.index + "_disrupted") ? (
                      <div
                        style={{
                          border: "2px solid #" + line.color,
                          background: stop.severity === "blocking" ? "red" : "orange",
                        }}
                        className={"lc-point" + (stop.terminus ? " lc-stop-terminus" : "")}
                      >
                        <div
                          className={
                            stop.severity === "blocking"
                              ? "lc-" + REACT_APP_DISRUPTION
                                ? "lc-" + JSON.parse(REACT_APP_DISRUPTION).map
                                : ""
                              : ""
                          }
                          style={{ borderColor: "#" + line.color }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        style={{ border: "2px solid #" + line.color }}
                        className={"lc-point" + (stop.terminus ? " lc-stop-terminus" : "")}
                      />
                    )}
                  </>
                )}
                <div
                  className={
                    "lc-stop-name" +
                    (stop.opened ? " lc-selected" : "") +
                    (line.tad && !line.tad.thermo ? " lc-tad-no-thermo" : "")
                  }
                  role="button"
                  tabIndex="0"
                  aria-expanded={stop.opened ? "true" : "false"}
                  aria-controls="lc-schedules"
                  aria-label={translate(
                    "aria-lines-stop",
                    false,
                    { key: "stop", value: stop.name },
                    {
                      key: "pmr",
                      value:
                        REACT_APP_SHOW_PMR && stop.pmr && REACT_APP_SHOW_PMR === "pmr"
                          ? "(" + translate("aria-lines-stop-pmr") + ")"
                          : "",
                    }
                  )}
                  onClick={() => {
                    if (stop.opened) {
                      history.push({
                        pathname,
                        search: search.split("&stop=")[0],
                      });
                    } else {
                      message({ clicked: "stop", id: stop.id });
                      history.push({
                        pathname,
                        search: search.split("&stop=")[0] + "&stop=" + stop.id,
                      });
                    }
                  }}
                  onKeyPress={(e) =>
                    handleKeyPress(e, () => {
                      if (stop.opened) {
                        history.push({
                          pathname,
                          search: search.split("&stop=")[0],
                        });
                      } else {
                        history.push({
                          pathname,
                          search: search.split("&stop=")[0] + "&stop=" + stop.id,
                        });
                      }
                    })
                  }
                  onMouseEnter={() => {
                    // onMarkerMouseOver(component, stop)
                    appStore.dispatch(actionOverMarker(stop));
                  }}
                  onMouseLeave={() => {
                    // onMarkerMouseOut(component, stop)
                    setTimeout(() => appStore.dispatch(actionOutMarker(stop)));
                  }}
                >
                  <div className={stop.opened ? "lc-selectedStop" : ""}>
                    <div className="lc-stop-and-tools">
                      {stop.opened ? <span>{stop.name}</span> : stop.name}
                      {REACT_APP_SHOW_PMR && stop.pmr
                        ? REACT_APP_SHOW_PMR === "pmr" && <div className="lc-is-pmr" />
                        : REACT_APP_SHOW_PMR === "no-pmr" && <div className="lc-is-no-pmr" />}
                      {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
                        JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
                          if (
                            stop[tool] &&
                            (stop[tool] === true ||
                              (stop[tool].length && stop[tool].includes(`${line.code}_${line.network}`)))
                          ) {
                            return <div key={`${stop.id}_${tool}`} className={`lc-is-${tool}`} />;
                          } else {
                            return false;
                          }
                        })}
                    </div>
                  </div>
                  {nextSchedules > 0 && (!line.tad || (line.tad && line.tad.schedules)) && (
                    <Collapse isOpen={!!stop.opened} id="lc-schedules">
                      {stop.severity && stop.severity === "blocking" ? (
                        <div className={"lc-severity lc-schedules lc-blocking"}>
                          <div className="lc-disruptionSeverity">
                            <div className="lc-icon" />
                          </div>
                          {translate("severity-blocking-stop")} {line.code}
                        </div>
                      ) : stop.opened ? (
                        !stop.rer ? (
                          <div className={"lc-selectedContent" + (!showSchedules ? " lc-no-schedules" : "")}>
                            {showSchedules &&
                              (stop.schedules ? (
                                <div
                                  key={stop.index + "_schedules"}
                                  className="lc-schedules"
                                  tabIndex="0"
                                  aria-label={translate(
                                    "aria-lines-next-schedules",
                                    false,
                                    { key: "code", value: line.code },
                                    { key: "direction", value: line.name },
                                    {
                                      key: "schedules",
                                      value: stop.schedules
                                        .map((schedule) => moment(schedule.time).fromNow(true))
                                        .join(", "),
                                    }
                                  )}
                                >
                                  {stop.schedules.length > 0 ? (
                                    stop.schedules.map((schedule, index) => (
                                      <div key={"schedule-" + index} className="lc-schedule">
                                        {moment().diff(schedule.time, "hours") < 1 &&
                                        moment(schedule.time).fromNow(true).includes("min") ? (
                                          <>
                                            <Moment
                                              interval={0}
                                              locale="fr"
                                              fromNow
                                              ago
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {schedule.time}
                                            </Moment>
                                            {schedule.realtime && (
                                              <Tippy
                                                theme={"latitude"}
                                                touch={["hold", 500]}
                                                placement={"right"}
                                                boundary="window"
                                                content={translate("realtime-gif-title")}
                                              >
                                                <img
                                                  src={assetsPath("/assets/images/realtime.gif")}
                                                  alt={translate("realtime-gif-alt")}
                                                />
                                              </Tippy>
                                            )}
                                          </>
                                        ) : (
                                          navitiaDateToHoursMin(schedule.time, language)
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div>{translate("no-schedules")}</div>
                                  )}
                                </div>
                              ) : (
                                <img
                                  src={assetsPath("/assets/images/loading.gif")}
                                  width={30}
                                  alt={translate("loading")}
                                />
                              ))}
                            {showTimetable && (
                              <div
                                className="lc-seeTimetable"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const date = moment().format("YYYYMMDD");

                                  history.push({
                                    pathname,
                                    search: search + "&date=" + date,
                                  });
                                }}
                                onKeyPress={(e) =>
                                  handleKeyPress(e, () => {
                                    const date = moment().format("YYYYMMDD");

                                    history.push({
                                      pathname,
                                      search: search + "&date=" + date,
                                    });
                                  })
                                }
                                role="button"
                                tabIndex="0"
                                aria-label={translate("aria-lines-see-timetable", false)}
                              >
                                <img
                                  src={assetsPath("/assets/images/timetable.svg")}
                                  alt={translate("timetable-button")}
                                />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: translate("timetable-button"),
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div style={{ paddingLeft: 10 }}>
                            Pour afficher les horaires, se référer au calcul d'itinéraire
                          </div>
                        )
                      ) : (
                        <div />
                      )}
                    </Collapse>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Render lines passing by an area
 * @param component
 */
export const renderLinesByArea = (component) => {
  return (
    <div className="lc-group">
      <div className="lc-group-name">
        <div className="lc-group-mode">
          {component.state.linesList.length > 0 ? translate("lines-by-stoparea") : translate("no-line-by-stoparea")}{" "}
          {translate("lines-going-through-stoparea")} {component.state.linesListStop}
        </div>
      </div>
      <div className="lc-group-offset-bottom">{renderLinesLabels(component, component.state.linesList, "line")} </div>
    </div>
  );
};

/**
 * Display lines sorted by group (collapsable)
 * @param component
 * @returns {any[]}
 */
export const renderLinesGroup = (component) => {
  const { openedCollapse, linesModes } = component.props;
  const { groups, pois } = component.state;

  if ((!groups || Object.keys(groups).length === 0) && (!pois || Object.keys(pois).length === 0)) {
    return <div className="lc-empty">{translate("no-lines-around")}</div>;
  }

  const jsx = Object.keys(groups).map((group, index) => (
    <div
      key={group}
      className="lc-group"
      id={"lc-group-" + index}
      onClick={() => appStore.dispatch(actionSetOpenedCollapse(group))}
      onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionSetOpenedCollapse(group)))}
      role="button"
      tabIndex="0"
      aria-expanded={group === openedCollapse ? "true" : "false"}
      aria-controls={"lc-group-section-" + index}
      aria-label={translate(group)}
    >
      <div className="lc-group-name">
        {
          {
            modesTitles: <div className="lc-group-mode">{translate(group)}</div>,
            modesTitlesIconsAndText: (
              <>
                <img className={"lc-group-mode-logo"} src={assetsPath(`/assets/images/${group}.svg`)} alt={group} />
                <div className="lc-mode">
                  <div className={"lc-" + group} style={getColorGroup(linesModes, group)}>
                    {translate(group)}
                  </div>
                  <div className="lc-text">{translate("mode-" + group + "-text")}</div>
                </div>
              </>
            ),
          }[REACT_APP_MODES_LINES_STYLE ? REACT_APP_MODES_LINES_STYLE : "modesTitles"]
        }
        <div className="lc-arrow-group">
          <img
            className={group !== component.props.openedCollapse ? "lc-closed" : ""}
            src={assetsPath("/assets/images/v.svg")}
            alt={translate("collapse-arrow")}
          />
        </div>
      </div>
      <div id={"lc-group-section" + index}>
        <Collapse isOpen={group === openedCollapse}>{renderLinesLabels(component, groups[group], group)}</Collapse>
      </div>
    </div>
  ));

  if (pois && Object.keys(pois).length > 0) {
    const pois = renderPlacesLabels(component);

    return [jsx, pois];
  } else {
    return jsx;
  }
};

export const renderPlacesGroup = (component) => {
  const { openedCollapse } = component.props;
  const { places } = component.state;

  if (!places) {
    return;
  }

  if (Object.keys(places).length === 0) {
    return <div className="lc-empty">{translate("no-places-around")}</div>;
  }

  return Object.keys(places).map((place, index) => (
    <div
      key={place}
      className="lc-group"
      onClick={() => appStore.dispatch(actionSetOpenedCollapse(place))}
      onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionSetOpenedCollapse(place)))}
      role="button"
      tabIndex="0"
    >
      <div className="lc-group-name">
        <div className="lc-group-header">
          <img src={assetsPath(`/assets/images/places/${places[place][0].code}.svg`)} alt={place} />
          <span>{translate(place)}</span>
        </div>
        <div className="lc-arrow-group">
          <img
            className={place !== openedCollapse ? "lc-closed" : ""}
            src={assetsPath("/assets/images/v.svg")}
            alt={translate("collapse-arrow")}
          />
        </div>
      </div>
      <Collapse isOpen={place === openedCollapse}>
        {places[place].map((item) => (
          <div
            key={item.id}
            className="lc-place"
            onMouseEnter={() => {
              appStore.dispatch(actionOverMarker(item));
            }}
            onMouseLeave={() => {
              appStore.dispatch(actionOutMarker(item));
            }}
            onClick={() => appStore.dispatch(actionOpenMarker(item, false))}
            onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionOpenMarker(item, false)))}
            role="button"
            tabIndex="0"
          >
            &bull;
            <div>{item.name}</div>
          </div>
        ))}
      </Collapse>
    </div>
  ));
};

/**
 * Render labels for given lines
 * @param component
 * @param lines
 * @param key
 * @param marker
 */
export const renderLinesLabels = (component, lines, key, marker) => {
  const { linesModes, size } = component.props;
  const onLineSelected = component.onLineSelected;

  // Avoid undefined lines...
  if (!lines) {
    return;
  }

  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";

  const div = (data) => (
    <div
      key={key + Math.random()}
      className={
        (key === "infobox" ? "lc-infobox-" : "lc-") +
        "lines lc-" +
        size +
        (styleLine.includes("WithDirection") ? " lc-line-with-direction" : "")
      }
    >
      {data.map((line) => {
        // Retrieve the global line
        line = getLine(component, line);

        if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
          const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
          const foundExceptedLine = exceptions.find((e) => e.lines.includes(line.id));

          if (foundExceptedLine) {
            styleLine = foundExceptedLine.type;
          }
        }

        switch (styleLine) {
          case "modeWithDirection":
            const lineMode = linesModes.find((mode) => mode.modes.includes(line.mode));

            return (
              <div
                className="lc-attribute-line"
                key={line.id}
                onClick={(e) => {
                  e.stopPropagation();

                  // Add line to historic
                  if (storageAvailable("localStorage")) {
                    addHistoricItem(line);
                  }

                  !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                }}
                onKeyPress={(e) =>
                  handleKeyPress(e, () => {
                    // Add line to historic
                    if (storageAvailable("localStorage")) {
                      addHistoricItem(line);
                    }

                    !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                  })
                }
                role="button"
                tabIndex="0"
              >
                <div
                  className="lc-line lc-mode"
                  style={{
                    background: "#" + line.color,
                    color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                  }}
                >
                  {lineMode.name}
                </div>
                <div className="lc-name">{line.name}</div>
              </div>
            );
          case "codeWithDirection":
            return (
              <div
                className="lc-attribute-line"
                key={line.id}
                onClick={(e) => {
                  e.stopPropagation();

                  // Add line to historic
                  if (storageAvailable("localStorage")) {
                    addHistoricItem(line);
                  }

                  !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                }}
                onKeyPress={(e) =>
                  handleKeyPress(e, () => {
                    // Add line to historic
                    if (storageAvailable("localStorage")) {
                      addHistoricItem(line);
                    }

                    !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                  })
                }
                role="button"
                tabIndex="0"
              >
                <div
                  className="lc-line lc-code"
                  style={{
                    background: "#" + line.color,
                    color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                  }}
                >
                  {line.code}
                </div>
                <div className="lc-name">{line.name}</div>
              </div>
            );
          case "image":
            return (
              <div
                className="lc-line"
                key={line.id}
                onClick={(e) => {
                  e.stopPropagation();
                  message({ clicked: "line", id: line.id });

                  // Add line to historic
                  if (storageAvailable("localStorage")) {
                    addHistoricItem(line);
                  }

                  !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                }}
                onKeyPress={(e) =>
                  handleKeyPress(e, () => {
                    // Add line to historic
                    if (storageAvailable("localStorage")) {
                      addHistoricItem(line);
                    }

                    !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                  })
                }
                role="button"
                tabIndex="0"
              >
                <img src={assetsPath("/assets/images/lines/" + line.code + ".svg")} alt={line.code} />
              </div>
            );
          case "color":
            return (
              <div
                key={line.id}
                className="lc-line"
                onClick={(e) => {
                  e.stopPropagation();
                  message({ clicked: "line", id: line.id });

                  if (storageAvailable("localStorage")) {
                    addHistoricItem(line);
                  }

                  !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                }}
                onKeyPress={(e) =>
                  handleKeyPress(e, () => {
                    if (storageAvailable("localStorage")) {
                      addHistoricItem(line);
                    }

                    !history.location.pathname.includes("route-calculation") && onLineSelected(line, marker);
                  })
                }
                role="button"
                tabIndex="0"
              >
                <div
                  className="lc-line-code"
                  style={{
                    background: "#" + line.color,
                    color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                  }}
                >
                  {line.code}
                </div>
              </div>
            );
          default:
            return "";
        }
      })}
    </div>
  );

  if (!lines) {
    return;
  }

  return div(lines);
};

export const renderPlacesLabels = (component) => {
  const { openedCollapse } = component.props;
  const { pois } = component.state;

  if (!pois) {
    return;
  }

  if (Object.keys(pois).length === 0) {
    return <div className="lc-empty">{translate("no-places-around")}</div>;
  }

  return Object.keys(pois).map((poi, index) => (
    <div
      key={poi}
      className="lc-group"
      onClick={() => {
        if (poi === openedCollapse && poi === "poi_type:stations") {
          appStore.dispatch(actionSetPlaceClicked(null));
        }

        appStore.dispatch(actionSetOpenedCollapse(poi));
      }}
      onKeyPress={(e) =>
        handleKeyPress(e, () => {
          if (poi === openedCollapse && poi === "poi_type:stations") {
            appStore.dispatch(actionSetPlaceClicked(null));
          }

          appStore.dispatch(actionSetOpenedCollapse(poi));
        })
      }
      role="button"
      tabIndex="0"
    >
      <div className="lc-group-name">
        <div className="lc-group-mode">{translate(poi)}</div>
        <div className="lc-arrow-group">
          <img
            className={poi !== openedCollapse ? "lc-closed" : ""}
            src={assetsPath("/assets/images/v.svg")}
            alt={translate("collapse-arrow")}
          />
        </div>
      </div>
      <Collapse isOpen={poi === openedCollapse}>
        {Array.isArray(pois[poi])
          ? appStore.dispatch(actionBuildPlacesByCatInList({ [poi]: pois[poi] }))
          : appStore.dispatch(actionBuildPlacesByCatInList(pois[poi]))}
      </Collapse>
    </div>
  ));
};

/**
 * Render a marker on the map
 * @param stop
 * @param options
 * @param component
 * @param place
 * @returns Marker
 */
export const renderMarker = (component, stop, options) => {
  return (
    <Marker
      key={stop.id}
      ref={(r) => {
        stop.ref = r;
      }}
      name={stop.name}
      position={[+stop.coord.lat, +stop.coord.lon]}
      icon={L.icon({
        iconUrl: assetsPath("/assets/images/pin.svg"),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      })}
      {...options}
    >
      {stop.terminus && (
        <Tooltip
          key={"terminus_" + stop.id}
          direction={"right"}
          className={"lc-tooltip-leaflet-terminus"}
          opacity={1}
          permanent
        >
          {stop.name}
        </Tooltip>
      )}
      <Popup className={"lc-popup-leaflet"} closeButton={false} autoClose={false} autoPan={false}>
        {renderInfobox(component, stop, null)}
      </Popup>
    </Marker>
  );
};

export const renderMarkerRouteCalculation = (key, component, position) => {
  const { map, language, moduleData } = component.props;
  const { localizeMarkers, markersConfig } = moduleData;

  return (
    <Marker
      key={key}
      draggable={!component.state.loading}
      onDragStart={component.onBackToParams}
      onDragEnd={(event) => {
        const { pathname } = history.location;
        const params = getURLSearchParams(history.location);
        const latlng = event.target.getLatLng();

        if (key === "inputStart-pin") {
          if (map.state.inputStartPin && !map.state.inputEndPin) {
            history.push({
              pathname,
              search: "?from=" + substringCoords(latlng),
            });
          } else if (map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: "?from=" + substringCoords(latlng) + "&to=" + params.to,
            });
          }
        } else {
          if (!map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: "?to=" + substringCoords(latlng),
            });
          } else if (map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: "?from=" + params.from + "&to=" + substringCoords(latlng),
            });
          }
        }
      }}
      zIndexOffset={999999}
      icon={L.icon({
        iconUrl: assetsPath(
          `/assets/images/route-calculation/${key === "inputStart-pin" ? "flag-start" : "flag-end"}${
            localizeMarkers ? "-" + language : ""
          }.svg`
        ),
        iconSize: markersConfig ? markersConfig.size : key === "inputStart-pin" ? [35, 35] : [40, 40],
        iconAnchor: markersConfig ? markersConfig.anchor : key === "inputStart-pin" ? [17.5, 17.5] : [20, 20],
      })}
      position={position}
    />
  );
};

// TODO RENAME
export const renderPlaces = async (component, pois) => {
  const { map } = component.props;
  const places = pois ? component.state.pois : component.state.places;

  // TODO THROW ERROR
  if (!places) {
    console.warn("No places");
    return;
  }

  const allPlaces = [];

  for (const place of Object.keys(places)) {
    const items = Array.isArray(places[place])
      ? places[place]
      : Object.keys(places[place]).reduce((acc, item) => {
          acc.push(...places[place][item]);
          return acc;
        }, []);

    allPlaces.push(...items);
  }

  const markers = [];

  for (const place of allPlaces) {
    markers.push(
      renderMarker(component, place, {
        icon: L.icon({
          iconUrl: assetsPath("/assets/images/places/") + place.code + ".svg",
          iconSize: [25, 25],
          iconAnchor: [12, 12],
        }),
      })
    );
  }

  map.setState(
    {
      clusters: null,
      status: null,
      markers,
    },
    () => resize(map.props.isMobile)
  );
};

export const renderPolygon = (path, options, key, props) => {
  return <Polygon {...options} key={key} positions={path} {...props} />;
};

export const renderPolyline = (path, options, key) => {
  return <Polyline key={key} positions={path} options={options} />;
};

/**
 * Retrieve schedules for a given stop in a given component
 * @param component
 * @param stop
 * @param line
 * @param date
 * @param fromMarkerClick
 */
export const schedules = (component, stop, line, date = "") => {
  const { stopsList } = component.state;

  if (!stopsList) {
    return;
  }

  const isTerminus = stop.terminus && stop.id === stopsList[stopsList.length - 1].id; // TCL style

  for (const s of stopsList) {
    s.schedules = null;
    s.opened = false;
  }

  // Remove any current line information message
  appStore.dispatch(actionSetLineInformation(null));

  // TODO real timetable fix
  if (component.state.timetable) {
    return;
  }

  // TODO DANS AROUND ARRET LE PLUS PROCHE PAS VISIBLE DU COUP...
  if (line.cat === "tad" || line.cat === "rer") {
    return;
  }

  // Avoid load schedules for a nonsense data
  let markerContainsLine = false;

  for (const data of stop.lines) {
    if (data.id === line.id) {
      markerContainsLine = true;
      break;
    }
  }

  if (!markerContainsLine) {
    return;
  }

  for (const s of stopsList) {
    s.schedules = null;
    s.opened = false;

    if (stop.id.includes("stop_area")) {
      s.opened = s.stop_area === stop.id && !isTerminus;
    } else {
      s.opened = s.id === stop.id && !isTerminus;
    }
  }

  component.setState(
    {
      // timetable: false,
      stopsList,
    },
    async () => {
      // Retrieve element and scroll to it
      const selected = stop.id.includes("stop_area")
        ? stopsList.filter((s) => s.stop_area === stop.id)
        : stopsList.filter((s) => s.stop_area === stop.stop_area && s.id === stop.id);

      if (selected.length > 0) {
        // Select the stop
        setTimeout(() => displayStopOnScrollToIt(component, selected[0]), 150);

        if (!isTerminus && (!line.tad || (line.tad && line.tad.schedules))) {
          const schedules = [];
          const route = line.routes.find((r) => r.direction_id === line.direction_id).route_id;

          axios
            .get("/api/schedules", {
              params: {
                stop: selected[0].id,
                route,
                date,
                count: nextSchedules,
              },
            })
            .then((response) => {
              for (const resp of response.data.slice(0, nextSchedules)) {
                schedules.push({
                  time: resp.date_time,
                  realtime: resp.data_freshness === "realtime",
                });
              }

              for (const select of selected) {
                select.schedules = schedules;
              }
            })
            .catch((e) => {
              for (const select of selected) {
                select.schedules = [];
              }

              const error = e.response && e.response.data ? e.response.data.id : e;

              console.warn(error);
            })
            .finally(() => {
              component.setState({ stopsList });
            });
        }
      }
    }
  );
};

/**
 *
 * @param component
 * @param stop
 * @param line
 * @param selected
 */
const displayStopOnScrollToIt = (component, selected) => {
  const { stopsList } = component.state;
  // The Elder Scrooooooooll
  let index = 0;

  for (const s of stopsList) {
    if (s.id === selected.id) {
      break;
    }

    index++;
  }

  const element = document.querySelector(`.lc-stops > :nth-child(${index + 1})`);
  const disruptionsElement = document.querySelector("[data-lc-disruptions]");

  // Scroll board to the selected element
  setTimeout(() => {
    element &&
      (element.parentNode.scrollTop =
        element.offsetTop - (element.parentNode.offsetTop + (disruptionsElement ? 10 : 5)));
  });
};

/**
 * Display line paths and info
 * @param component
 * @param line
 * @param linesTab
 * @param selectedLines
 */
export const selectLine = (component, line) => {
  const { stops } = component.props;

  if (!line.direction_id) {
    line.direction_id = "f";
  }

  const selectedRoute = line.routes.find((r) => r.id[r.id.length - 1] === line.direction_id);

  if (!selectedRoute.stops) {
    return axios
      .get(
        `/api/file?folder=stops&name=${encodeURIComponent(line.code)}_${line.network}_${line.direction_id}~${
          component.props.hash
        }`
      )
      .then(async (response) => {
        // TODO Fix retrive lines at generate
        for (const stop of response.data) {
          const infos = stops.find((s) => s.id === stop.id);

          stop.lines = infos.lines;

          // Define line as PMR only if ONE stop is PMR
          if (stop.pmr) {
            line.pmr = true;
          }
        }

        line.stops = response.data;
        selectedRoute.stops = response.data;
        return {
          currentLine: line,
          stopsList: response.data,
        };
      })
      .catch((error) => {
        console.warn(
          "Unable to find stops/" + line.code + "_" + line.network + "_" + line.direction_id + ".json",
          error
        );
      });
  } else {
    // Close all saved stops
    for (const stop of selectedRoute.stops) {
      stop.opened = false;
    }

    line.stops = selectedRoute.stops;

    return {
      currentLine: line,
      stopsList: selectedRoute.stops,
    };
  }
};

/**
 * Update map state events
 * @param map
 * @param event
 * @param callback
 */
export const updateMapEvents = (map, event, callback) => {
  if (!map) {
    return;
  }

  map.setState((state) => ({
    events: {
      ...state.events,
      [event]: callback,
    },
  }));
};

export const zoomOnTerritoryOutline = (map) => {
  const outline = map.props.territoryOutline;
  const outlineToRender = [];
  const bounds = [];

  if (outline) {
    for (const feature of outline.features) {
      let featureCoords = feature.geometry.coordinates;

      if (["Polygon", "MultiLineString"].includes(feature.geometry.type)) {
        featureCoords = featureCoords[0];
      }

      for (const coords of featureCoords) {
        bounds.push([coords[1], coords[0]]);
        outlineToRender.push(...coords);
      }
    }

    setTimeout(() => {
      fitBounds(map, bounds);
    });
  }
};

// --------------------------- PRIVATE --------------------------- //
const collapseTimetableOptions = (component) => {
  try {
    component.setState(
      {
        timetableData: null,
        timetableOptions: !component.state.timetableOptions,
      },
      async () => {
        if (component.state.timetableOptions) {
          const element = document.querySelector("[data-lc-timetable-options]");

          if (element) {
            element.style.top = document.querySelector("[data-lc-line-header]").offsetHeight + "px";
          }
        }

        const timetables = [];

        if (REACT_APP_TIMETABLES === "local") {
          const response = await axios("/api/file?name=timetables");
          const timetable = response.data.find((data) => data.ligne === component.state.currentLine.code);

          if (timetable && timetable.fiche_horaire) {
            timetable.fiche_horaire.split("~").map((fh, index) => {
              return timetables.push({
                id: index,
                name: fh,
                file: "assets/timetables/" + fh + ".pdf",
                starting_validity_date: "",
                ending_validity_date: "",
              });
            });
          }
        } else if (REACT_APP_TIMETABLES === "api") {
          const response = await axios("/api/timetable?line_id=" + component.state.currentLine.id);

          for (const timetable of response.data.data) {
            timetables.push({
              id: timetable.id[0].value,
              name: timetable.name[0].value,
              file: timetable.file_timetable[0].url,
              starting_validity_date: timetable.starting_validity_date[0].value,
              ending_validity_date: timetable.ending_validity_date[0].value,
            });
          }
        }

        if (timetables.length === 1 && REACT_APP_TIMETABLES === "local") {
          updateDataLayer({
            event: "map-downloadTimetable",
            line: component.state.currentLine.code,
          });

          component.setState(
            {
              directDownload: true,
            },
            () => {
              window.open("/" + timetables[0].file);
            }
          );
        } else {
          component.setState({
            timetableLineData: timetables,
          });
        }
      }
    );
  } catch (e) {
    console.warn(e);
  }
};

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(this, arguments);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, arguments);
    }
  };
};

/**
 * Display a timetable for a stop and a specific line
 * @param component
 * @param stop
 * @param line
 * @param date
 */
export const displayTimeTable = (component, stop, line, date) => {
  // 0: morning, 1: afternoon, 2: evening selected by defaut with the hour if today
  const isToday = moment().format("YYYYMMDD") === date;
  const nowHours = Math.floor(new Date().getHours());
  let slideIndex = isToday ? (nowHours >= 4 && nowHours <= 11 ? 0 : nowHours >= 12 && nowHours <= 19 ? 1 : 2) : 0;

  // get stop id of line if we have a stop_area
  if (stop.id.includes("stop_area")) {
    stop.stop_id = stop.lines.find((l) => l.id === line.id).stop_id;
  }

  component.setState(
    {
      timetable: true,
      loadingTimetable: true,
      timetableStop: stop.name,
    },
    async () => {
      const route = line.routes.find((r) => r.direction_id === line.direction_id).route_id;

      const params = {
        stop: stop.stop_id || stop.id,
        route,
        timetable: true,
      };

      const nightLines = REACT_APP_NIGHT_LINES ? JSON.parse(REACT_APP_NIGHT_LINES) : { departure: "00", lines: [] };

      if (date) {
        if (nightLines.lines.includes(component.state.currentLine.id)) {
          params.date = `${date}T${nightLines.departure}0000`;
          slideIndex = 0; // There is only one slide for the night lines
        } else {
          params.date = date + "T040000";
        }
      }

      await axios({
        url: "/api/schedules",
        params,
      })
        .then((response) => {
          const timetableData = {
            morning: [],
            afternoon: [],
            evening: [],
          };

          for (const time of response.data) {
            const departure = parseInt(time.date_time.substring(9, 11));

            if (departure >= 4 && departure <= 11) {
              timetableData.morning.push(time);
            } else if (departure > 11 && departure <= 19) {
              timetableData.afternoon.push(time);
            } else if (departure >= 20 || departure < 4) {
              timetableData.evening.push(time);
            }
          }

          if (timetableDataIsEmpty(timetableData)) {
            // fix crash
            slideIndex = 0;
          }

          component.setState(
            {
              slideIndex,
              timetableData,
              loadingTimetable: false,
              timetableError: false,
            },
            () => {
              const divs = Array.from(document.querySelectorAll("[data-lc-scroll='scroll']"));

              resize(component.props.map.props.isMobile, divs[component.state.slideIndex]);
            }
          );
        })
        .catch((e) => {
          console.warn("error", e);
          component.setState({
            loadingTimetable: false,
            timetableError: e.response,
          });
        });
    }
  );
};
