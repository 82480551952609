import { useEffect, useState } from "react";
import { useClick } from "./useClick";
import { useMedia } from "./useMedia";
import { useWindowSize } from "./useWindowSize";
import { debug } from "../services/tools";
import { headerHeight, headerDirection } from "../scss/app.scss";
const { REACT_APP_SLIDER } = process.env;

export function useSlider(sliderRef, overlayLeft, overlayRight, mapRef) {
  const size = useWindowSize();
  const isDesktop = useMedia();
  const [left, setLeft] = useState(0);
  const [offset, setOffset] = useState(0);
  const sliderParsed = JSON.parse(REACT_APP_SLIDER);
  const baseWidth = isDesktop && headerDirection === "row" ? size.width - parseInt(headerHeight) : size.width;
  const [width, setWidth] = useState(baseWidth);

  const clip = (range) => {
    if (sliderRef.current) {
      const nw = mapRef.current.leafletElement.containerPointToLayerPoint([0, 0]);
      const se = mapRef.current.leafletElement.containerPointToLayerPoint(mapRef.current.leafletElement.getSize());
      const clipX = nw.x + (se.x - nw.x) * range;

      overlayLeft.current.leafletElement.getContainer().style.clip =
        "rect(" + [nw.y, clipX, se.y, nw.x].join("px,") + "px)";

      overlayRight.current.leafletElement.getContainer().style.clip =
        "rect(" + [nw.y, se.x, se.y, clipX].join("px,") + "px)";
    }
  };

  const slide = (event) => {
    let pageX = event ? (!isDesktop ? (event.touches ? event.touches[0].pageX : event.pageX) : event.pageX) : left;
    const range = (pageX - offset) / width;
    let pos = parseInt(pageX - offset);

    if (pos < 0) {
      pos = 0;
    } else if (pos > width) {
      pos = width;
    }

    sliderRef.current.style.left = pos + "px";
    setLeft(pos);
    clip(range);
  };

  const [isClicked, clickTarget] = useClick(sliderRef, slide);

  useEffect(() => {
    setLeft(width / 2);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const width = baseWidth;

    setWidth(width);
    // eslint-disable-next-line
  }, [size.width]);

  // before / after
  useEffect(() => {
    const map = mapRef.current;

    overlayLeft.current && map.leafletElement.on("move", () => clip(left / width));

    return () => {
      map.leafletElement.off("move", () => clip(left / width));
    };

    // eslint-disable-next-line
  }, [sliderRef.current, isDesktop, left, width]);

  useEffect(() => {
    overlayLeft.current && clip(left / width);

    if (isDesktop && sliderParsed && sliderParsed.titles) {
      document.getElementById("lc-titleLeft").style.display = left / width < 0.15 ? "none" : "block";
      document.getElementById("lc-titleRight").style.display = left / width > 0.8 ? "none" : "block";
    }

    // eslint-disable-next-line
  }, [sliderRef.current, left, width]);

  useEffect(() => {
    if (sliderRef.current) {
      const rects = sliderRef.current.getBoundingClientRect();
      let x = rects.x ? rects.x : rects.left;

      if (isDesktop && headerDirection === "row") {
        x = x - parseInt(headerHeight);
      }

      setLeft(x);
    }

    // eslint-disable-next-line
  }, [sliderRef.current, width]);

  useEffect(() => {
    if (sliderRef.current) {
      const rects = sliderRef.current.getBoundingClientRect();
      let x = rects.x ? rects.x : rects.left;

      if (isDesktop && headerDirection === "row") {
        x = x - parseInt(headerHeight);
      }

      setOffset(clickTarget - x);
    }

    // eslint-disable-next-line
  }, [sliderRef.current, clickTarget]);

  useEffect(() => {
    debug({ message: isClicked ? "Can drag !" : "Cant drag :( !" }, "warning", "Slider");
  }, [isClicked]);
}
