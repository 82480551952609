import React from "react";
import { GeoJSON, Tooltip, LayerGroup } from "react-leaflet";
import styled from "styled-components";
import { assetsPath } from "../../services/tools";

const WrappedLines = styled.div`
  display: flex;
  gap: 10px;
  div {
    width: 30px !important;
    height: 30px !important;
  }
`;

const Image = styled.div`
  background-image: url(${(props) => assetsPath("/assets/images/lines/olds/" + props.code + ".svg")});
  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 30px;
`;

const OldLinesHover = (props) => {
  const { data } = props;
  const geojsons = [];

  if (data.features) {
    for (const [key, feature] of Object.entries(data.features)) {
      const hasDesserte = feature.properties.hasOwnProperty("desserte");

      if (hasDesserte) {
        const lines = feature.properties.desserte.split(";");

        geojsons.push(
          <GeoJSON
            key={"old-lines-offset-" + key}
            data={feature}
            style={{
              color: "#ffffff",
              opacity: 0,
              fillOpacity: 0,
              weight: 14,
              interactive: true,
            }}
          >
            <Tooltip direction="right" sticky key={"old-lines-tooltip-" + key} interactive={true}>
              <WrappedLines key={"old-lines-wrapped-" + key}>
                {lines.map((code) => (
                  <Image code={code} key={"old-lines-image-" + key + "-" + code} />
                ))}
              </WrappedLines>
            </Tooltip>
          </GeoJSON>
        );
      }
    }
  }

  return <LayerGroup>{geojsons}</LayerGroup>;
};

export default OldLinesHover;
