import { MapLayer, withLeaflet } from "react-leaflet";
import L from "leaflet";
import "leaflet-polylinedecorator";
import utils from "leaflet-geometryutil";
import { luminance } from "luminance-js";
import { assetsPath, getURLSearchParams } from "../../services/tools";
import history from "../../history";

// TODO Must be a better way to handle those fuckin plugins ...
class PolylineDecorator extends MapLayer {
  componentWillUnmount() {
    const { map } = this.props.leaflet;

    map.removeLayer(this.leafletElement);
  }

  createLeafletElement({ path, section, lineStyle, pmr, leaflet }) {
    const code = section.display_informations ? section.display_informations.code : section.mode;
    const length = utils.length(path.map((p) => new L.LatLng(p[0], p[1])));
    const params = getURLSearchParams(history.location);

    const bss =
      (section.from &&
        section.from.embedded_type === "poi" &&
        section.from["poi"].properties.amenity === "bicycle_rental") ||
      params.journey === "bss";

    const mode = section.display_informations
      ? "bus"
      : section.mode === "car"
      ? "car"
      : section.mode === "taxi"
      ? "taxi"
      : section.mode === "bike"
      ? bss
        ? "bss"
        : "bike"
      : pmr
      ? "pmr"
      : "walk";

    return L.polylineDecorator(path, {
      interactive: false,
      patterns: [
        {
          offset: "50%",
          repeat: 100 - length / 400 + "%",
          symbol: L.Symbol.marker({
            rotate: false,
            markerOptions: {
              interactive: false,
              icon:
                lineStyle === "color" && section.display_informations
                  ? L.divIcon({
                      className: "lc-polyline-decorator-color",
                      html:
                        '<div class="lc-decorator" style="background: #' +
                        section.display_informations.color +
                        "; color: #" +
                        (luminance(section.display_informations.color) > 0.5 ? "333" : "fff") +
                        '">' +
                        code +
                        "</div>",
                    })
                  : L.icon({
                      className: `lc-polyline-decorator ${
                        mode === "bus"
                          ? "lc-decorator-bus"
                          : section.mode === "bike" || mode === "car"
                          ? "lc-decorator-bike-or-car"
                          : "lc-decorator-other"
                      }`,
                      iconUrl:
                        mode === "bus"
                          ? assetsPath(`/assets/images/lines/${code}.svg`)
                          : assetsPath(`/assets/images/modes/${mode}.svg`),
                    }),
            },
          }),
        },
      ],
    });
  }

  updateLeafletElement(fromProps, { path, code, leaflet }) {
    this.leafletElement.setPaths(path);
  }
}

export default withLeaflet(PolylineDecorator);
