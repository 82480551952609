import React from "react";
import moment from "moment";
import Autocomplete from "@latitude-cartagene/react-autocomplete";
import { luminance } from "luminance-js";
import history from "../history";
import { DateCalendar } from "@latitude-cartagene/react-picker-date-and-time";
import TimePicker from "rc-time-picker";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  clickOnPlaceInList,
  focusInput,
  getCoordsFromUrlObject,
  getURLSearchParams,
  goToRouteCalculation,
  humanReadableOpeningHours,
  isSystemUS,
  onChangeAutocompleteInput,
  onSelectAutocompleteValue,
  unique,
  onTabSelected,
  resize,
  assetsPath,
  handleKeyPress,
  translate,
} from "./tools";
import Collapse from "@kunukn/react-collapse";
import { appStore } from "../store";
import {
  actionSetOpenedCollapse,
  actionSetRouteCalcDatesPanel,
  actionSetRouteCalcModesPanel,
  actionSetRouteCalcWalkingSpeedsPanel,
  actionDecrementStationIndex,
  actionIncrementStationIndex,
} from "../actions/board";
import {
  actionHandleModesChanged,
  actionHandleWalkingSpeedsChanged,
  actionHandleRepresentsChanged,
  actionHandleSelectedDateChanged,
  actionHandleSelectedTimeChanged,
} from "../actions/app";
import {
  actionOutMarker,
  actionOverMarker,
  actionOpenMarker,
  actionBuildPlacesByCatInList,
  actionBuildPublicPlaces,
  actionBuildTransportPlaces,
} from "../actions/withRedux";
import colors from "../scss/app.scss";
import Tippy from "@tippy.js/react";
import { renderLinesGroup } from "../utils/leaflet/map";
import { message } from "./message";
import { updateDataLayer } from "../tracking";

const { REACT_APP_LINES_MAIN_TYPE, REACT_APP_LINES_TYPE_EXCEPTIONS } = process.env;

export const buildAutocomplete = (state, inputProps, isModal) => {
  const { pathname } = history.location;
  const { lock, size, modules, touchscreenSelected, linesModes, component } = state.app;
  const { options } = component.props;
  const showGoToRC = options?.features?.["route-calculation"] === false ? false : true;
  let valueInput =
    inputProps === "inputStart"
      ? state.board.inputStartValue
      : inputProps === "inputEnd"
      ? state.board.inputEndValue
      : state.board.inputValue;

  const placeholder =
    inputProps === "inputSchedules"
      ? translate("placeholder-inputSchedules")
      : pathname.includes("/route-calculation")
      ? inputProps === "inputStart"
        ? translate("placeholder-inputStart")
        : translate("placeholder-inputEnd")
      : inputProps === "inputNetworkSearch"
      ? translate("placeholder-inputNetworkSearch")
      : inputProps.includes("inputThematic-") && pathname.includes("pt-vente")
      ? translate("placeholder-inputPtVente")
      : inputProps.includes("inputThematic-") && pathname.includes("e-tecely")
      ? translate("placeholder-inputeTecely")
      : inputProps.includes("inputThematic-") && pathname.includes("gab")
      ? translate("placeholder-inputGAB")
      : inputProps.includes("inputThematic-") && pathname.includes("p+r")
      ? translate("placeholder-inputPR")
      : inputProps.includes("inputThematic-") && pathname.includes("park-ride")
      ? translate("placeholder-park-ride")
      : inputProps.includes("inputThematic-") && pathname.includes("agence")
      ? translate("placeholder-inputHideAgence")
      : inputProps.includes("inputThematic-") && pathname.includes("pt-service")
      ? translate("placeholder-inputHidePtService")
      : translate("placeholder-inputAround");

  return (
    <form
      className="lc-formAutocomplete"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Autocomplete
        inputProps={{ id: inputProps }}
        autoHighlight
        wrapperStyle={{
          display: "flex",
          flex: 1,
          position: "relative",
        }}
        value={valueInput}
        items={
          inputProps === "inputStart"
            ? state.board.inputStartItems
            : inputProps === "inputEnd"
            ? state.board.inputEndItems
            : state.board.inputItems
        }
        getItemValue={(item) => item.name}
        onSelect={(valueSelected, itemSelected) => {
          message({ clicked: "autocomplete-item", id: itemSelected.id });
          onSelectAutocompleteValue(valueSelected, itemSelected, inputProps, state, isModal);
        }}
        onChange={(event) => onChangeAutocompleteInput(event, inputProps, state)}
        onMenuVisibilityChange={(isOpen) => {
          // Edit the global overlow properties to avoid bad renders
          if (isOpen) {
            document.querySelector(".lc-board").style.overflow = "inherit";
          } else {
            document.querySelector(".lc-board").style.overflow = "hidden";
          }
        }}
        renderMenu={(children) => {
          const geoloc = children.filter((c) => c.key === "geoloc");
          const historyItems = children.filter((c) => c.key.includes("history-"));
          const isEmpty = children.filter((c) => c.key === "no_result").length === 1;
          const partners = children.filter((c) => c.key.includes("partner-"));
          const partnersByRegions = {};

          if (partners.length >= 1) {
            for (const partner of partners) {
              const region = partner.props.region;

              partnersByRegions[region] = partnersByRegions[region] || [];
              partnersByRegions[region].push(partner);
            }
          }

          if (isEmpty) {
            children = children.filter((c) => c.key !== "no_result");
          }

          return (
            <ul
              className={
                `lc-autocomplete lc-${size}` + (pathname.includes("route-calculation") ? " lc-offsetRoute" : "")
              }
              id={"lc-autocomplete-" + inputProps}
              role="listbox"
              aria-label={translate("aria-autocomplete-input", false, { key: "search", value: placeholder })}
            >
              {geoloc}
              {historyItems.length >= 1 && !touchscreenSelected && (
                <li className="lc-autocompleteHistory">
                  <div className="lc-autocompleteHeader">{translate("autocomplete-historic")}</div>
                  <div>{historyItems}</div>
                </li>
              )}
              {partners.length >= 1 &&
                Object.keys(partnersByRegions)
                  .sort()
                  .map((region) => {
                    return (
                      <div className="lc-autocompleteFavorites" key={region}>
                        <div className="lc-autocompleteHeader">{region}</div>
                        <div>
                          {partnersByRegions[region].sort((a, b) => a.props.children.localeCompare(b.props.children))}
                        </div>
                      </div>
                    );
                  })}
              {isEmpty && (
                <li className="lc-item lc-no-result" key={"emptyResults"}>
                  {translate("autocomplete-no-result")}
                </li>
              )}
              {historyItems.length === 0 && partners.length === 0 && children.filter((c) => c.key !== "geoloc")}
            </ul>
          );
        }}
        renderInput={(props) => (
          <>
            {/* <div
            // role="combobox" // add with {...props}
            // aria-expanded="false" // add with {...props}
            style={{ display: "flex", flex: "1 1 0%", position: "relative" }}
            aria-owns={"lc-autocomplete-" + inputProps}
            aria-haspopup="listbox"
            {...props}
          > */}
            <input
              className="lc-input"
              data-lc-input
              tabIndex="0"
              {...props}
              onFocus={(e) => !isModal && focusInput(e, inputProps, state)}
              placeholder={placeholder}
              aria-label={translate("aria-autocomplete-input", false, { key: "search", value: placeholder })}
              aria-autocomplete="list"
              aria-controls={"lc-autocomplete-" + inputProps}
            />

            {modules.find((m) => m.id === "route-calculation") &&
              !isModal &&
              !lock &&
              state.board.goToValid &&
              !pathname.includes("/route-calculation") &&
              showGoToRC && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"top"}
                  boundary="window"
                  content={translate("title-go-to-route-calculation")}
                >
                  <div
                    className="lc-go-to-route lc-around"
                    tabIndex="0"
                    role="button"
                    onClick={async () => {
                      const params = getURLSearchParams(history.location);

                      if (pathname.includes("/lines")) {
                        goToRouteCalculation(params.stop_area);
                      } else if (pathname.includes("/around") && !params.from.includes(";")) {
                        goToRouteCalculation(params.from);
                      } else if (params.place) {
                        goToRouteCalculation(params.place);
                      } else {
                        const coords = await getCoordsFromUrlObject(
                          getURLSearchParams(history.location).from,
                          null,
                          component
                        );

                        goToRouteCalculation({
                          address: {
                            lon: coords[1],
                            lat: coords[0],
                          },
                        });
                      }
                    }}
                    onKeyPress={async (e) =>
                      await handleKeyPress(e, async () => {
                        const params = getURLSearchParams(history.location);

                        if (pathname.includes("/lines")) {
                          goToRouteCalculation(params.stop_area);
                        } else if (pathname.includes("/around") && !params.from.includes(";")) {
                          goToRouteCalculation(params.from);
                        } else if (params.place) {
                          goToRouteCalculation(params.place);
                        } else {
                          const coords = await getCoordsFromUrlObject(
                            getURLSearchParams(history.location).from,
                            null,
                            component
                          );

                          goToRouteCalculation({
                            address: {
                              lon: coords[1],
                              lat: coords[0],
                            },
                          });
                        }
                      })
                    }
                  >
                    <img
                      src={assetsPath("/assets/images/menu/route-calculation.svg")}
                      alt={translate("route-calculation-board-title")}
                    />
                  </div>
                </Tippy>
              )}
          </>
        )}
        renderItem={(item, isHighlighted) => {
          const lineMode = linesModes && linesModes.find((mode) => mode.modes.includes(item.mode));

          item.styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";

          if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
            const foundExceptedLine = exceptions.find((e) => e.lines.includes(item.id.replace("history-", "")));

            if (foundExceptedLine) {
              item.styleLine = foundExceptedLine.type;
            }
          }

          return item.embedded_type ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img
                width={30}
                src={assetsPath("/assets/images/autocomplete/" + item.embedded_type + ".svg")}
                alt={item.embedded_type}
              />
              {item.name}
            </li>
          ) : item.isPartner ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={`partner-${item.id}`}
              region={item.region}
            >
              {item.name}
            </li>
          ) : item.id === "geoloc" ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img
                width={30}
                src={assetsPath("/assets/images/autocomplete/position.svg")}
                alt={translate("autocomplete-geoloc")}
              />
              {item.name}
            </li>
          ) : item.id.includes("history-") && item.type && item.type !== "line" ? (
            <div
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/" + item.type + ".svg")} alt={item.type} />
              {item.name}
            </div>
          ) : item.id.includes("stop_") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/stop_area.svg")} alt={translate("stop")} />
              {item.name}
            </li>
          ) : state.board.thematicPlaces ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/places/" + item.code + ".svg")} alt={item.code} />
              {item.name}
            </li>
          ) : item.id.includes("line") ? (
            <div
              className={"lc-autocomplete-line" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              style={{ padding: 10 }}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.code}
            >
              {
                {
                  modeWithDirection: (
                    <div
                      key={item.id}
                      className="lc-line lc-line-with-direction lc-mode"
                      style={{
                        background: "#" + item.color,
                        color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {lineMode ? lineMode.name : ""}
                    </div>
                  ),
                  codeWithDirection: (
                    <div
                      key={item.id}
                      className="lc-line lc-line-with-direction lc-code"
                      style={{
                        background: "#" + item.color,
                        color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {item.code}
                    </div>
                  ),
                  image: (
                    <div className="lc-line">
                      <img src={assetsPath("/assets/images/lines/" + item.code + ".svg")} alt={item.code} />
                    </div>
                  ),
                  color: (
                    <div className="lc-line">
                      <span
                        className={"lc-line-code"}
                        style={{
                          background: "#" + item.color,
                          color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                        }}
                      >
                        {item.code}
                      </span>
                    </div>
                  ),
                }[item.styleLine]
              }
              <div
                style={{
                  flex: 5,
                  paddingLeft: 10,
                }}
              >
                {item.code !== item.name ? (
                  <div className="lc-autocompleteDirection" key={item.name}>
                    {item.name.replace("\n", " <> ")}
                  </div>
                ) : (
                  unique(item.routes, "name").map((route) => (
                    <div className="lc-autocompleteDirection" key={route.name}>
                      {route.name.replace("\n", " <> ")}
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : item.id.includes("town") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/administrative_region.svg")} alt="town" />
              {item.name}
            </li>
          ) : item.id.includes("aeroway") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/aeroway_terminal.svg")} alt="aeroway" />
              {item.name}
            </li>
          ) : (
            <div key="no_result" />
          );
        }}
      />
      {valueInput && (
        <Tippy
          theme={"latitude"}
          touch={["hold", 500]}
          placement={"top"}
          boundary="window"
          content={translate("autocomplete-clear-input")}
        >
          <span
            className="lc-autocompleteClear"
            onClick={() => {
              onChangeAutocompleteInput({ target: { value: "" } }, inputProps, state);
            }}
            onKeyPress={(e) =>
              handleKeyPress(e, () => {
                onChangeAutocompleteInput({ target: { value: "" } }, inputProps, state);
              })
            }
            role="button"
            tabIndex="0"
          />
        </Tippy>
      )}
    </form>
  );
};

export const buildPlacesByCatInList = (state, places = []) => {
  const placesCatToRender = Object.keys(places);
  const { openedCollapse } = state.board;
  const { component } = state.app;

  if (placesCatToRender.length === 0) {
    return <div className="lc-empty">{translate("no-places-around")}</div>;
  }

  return placesCatToRender.map((catToRender) => (
    <div
      key={catToRender}
      className="lc-group"
      onClick={() => {
        message({ clicked: "thematic", id: catToRender });
        appStore.dispatch(actionSetOpenedCollapse(catToRender));
      }}
      onKeyPress={(e) =>
        handleKeyPress(e, () => {
          message({ clicked: "thematic", id: catToRender });
          appStore.dispatch(actionSetOpenedCollapse(catToRender));
        })
      }
      role="button"
      tabIndex="0"
    >
      <div className="lc-group-name">
        <div className="lc-category">
          <img
            width={20}
            src={assetsPath("/assets/images/places/" + places[catToRender][0].code + ".svg")}
            alt={catToRender}
          />
          {translate(catToRender)}
        </div>
        {(state.board.thematicPlaces || (component.state.places && component.state.tab === 1)) && (
          <div className="lc-arrow-group">
            <img
              className={openedCollapse !== catToRender ? "lc-closed" : ""}
              src={assetsPath("/assets/images/v.svg")}
              alt={translate("collapse-arrow")}
            />
          </div>
        )}
      </div>
      {state.board.thematicPlaces || (component.state.places && component.state.tab === 1) ? (
        <Collapse isOpen={openedCollapse === catToRender}>{renderPlacesList(state, places[catToRender])}</Collapse>
      ) : (
        <div>{renderPlacesList(state, places[catToRender])}</div>
      )}
    </div>
  ));
};

export const buildRouteCalcPreferences = (state) => {
  const { app, map } = state;
  const { calculateItineraryNow, isMobile, language, modes, selectedDate, selectedTime, datePicker } = state.app;
  const { datesPanel, modesPanel, walkingSpeedsPanel } = state.board;
  const departure = modes.represents === "departure";
  const modesChoice = [];

  for (const mode of Object.keys(app.modes)) {
    if (typeof app.modes[mode] === "boolean") {
      modesChoice.push({
        id: mode,
        name: translate(`modes-${mode}`),
      });
    }
  }

  const pickerStyle = {
    calendar: {
      top: map && !isMobile ? "" : "",
      bottom: map && isMobile ? "10%" : "",
      left: map && isMobile ? "0" : "",
      right: map && isMobile ? "0" : "",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
      fontFamily: process.env.REACT_APP_FONT,
    },
    colon: {
      padding: "0 5px 0 0 !important",
    },
    control: {
      boxShadow: "none",
      cursor: "pointer",
    },
    first: "#005e86",
    menu: {
      marginLeft: -5,
      position: "fixed",
      bottom: map && isMobile ? "25%" : "",
      top: "",
    },
    weekDays: {
      padding: "5px 0",
    },
    monthSelected: {
      fontWeight: 600,
    },
    calendarButtonStyle: {
      fontSize: "1em",
    },
    inputsHours: {
      fontSize: "1em",
    },
    today: {
      background: "#f4f4f4",
      color: "#333",
      fontWeight: "500",
    },
  };

  pickerStyle.first = colors.primarycolor;
  pickerStyle.calendarButtonStyle = {
    ...pickerStyle.calendarButtonStyle,
    padding: 8,
    marginRight: 10,
  };
  pickerStyle.calendar = {
    ...pickerStyle.calendar,
    padding: 15,
    background: "#f1f5f5",
  };
  pickerStyle.week = {
    ...pickerStyle.week,
    background: "#fff",
  };

  const dates = (
    <>
      <div className="lc-represents">
        <div className="lc-radio">
          <label className={departure ? "lc-radio-checked" : ""}>
            <input
              type="radio"
              value="departure"
              checked={departure}
              tabIndex="0"
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("departure"))}
            />
            {translate("route-calculation-board-preferences-departure")}
          </label>
        </div>
        <div className="lc-radio">
          <label className={!departure ? "lc-radio-checked" : ""}>
            <input
              type="radio"
              value="arrival"
              checked={!departure}
              tabIndex="0"
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("arrival"))}
            />
            {translate("route-calculation-board-preferences-arrival")}
          </label>
        </div>
      </div>
      <div
        className="lc-pickers"
        tabIndex="0"
        role="button"
        aria-label={translate("aria-route-calculation-date", false, {
          key: "date",
          value: calculateItineraryNow
            ? translate("route-calculation-departure-now")
            : selectedDate.format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY"),
        })}
      >
        <DateCalendar
          lang={language}
          systemUS={isSystemUS(language)}
          style={pickerStyle}
          todayTxt={translate("calendar-today-word")}
          image={assetsPath("/assets/images/calendar.svg")}
          arrow={assetsPath("/assets/images/arrow-calendar.svg")}
          getSelectedDate={(date) => {
            appStore.dispatch(actionHandleSelectedDateChanged(date, moment(date).isSame(moment(), "day")));
          }}
          setDate={selectedDate && selectedDate.format("YYYYMMDD")}
          minDate={datePicker.minDate}
          maxDate={datePicker.maxDate}
        />
        <div className="lc-time-picker-group">
          <div
            onClick={() => document.querySelector(".lc-time-picker").click()}
            onKeyPress={(e) => handleKeyPress(e, () => document.querySelector(".lc-time-picker").click())}
            role="button"
            tabIndex="0"
            aria-label={translate("aria-route-calculation-time", false, {
              key: "hour",
              value: moment(selectedTime).format(isSystemUS(language) ? "hh:mm a" : "HH:mm"),
            })}
          >
            <img src={assetsPath("/assets/images/route-calculation-time.svg")} alt={"picto heure"} />
          </div>
          <TimePicker
            style={{
              position: "relative",
            }}
            className="lc-time-picker"
            popupClassName="lc-time-picker-popup"
            defaultValue={moment()}
            placement={"bottomLeft"}
            use12Hours={language === "en"}
            showSecond={false}
            minuteStep={5}
            allowEmpty={false}
            inputReadOnly
            value={selectedTime}
            onChange={(moment) => {
              appStore.dispatch(actionHandleSelectedTimeChanged(moment));
            }}
            onClose={() => document.querySelector(".rc-time-picker-input").blur()}
          />
        </div>
      </div>
    </>
  );

  const prefs = (
    <>
      <div className="lc-route-calculation-modes" role="group" aria-labelledby="lc-route-calculation-modes">
        {modesChoice
          .filter((mode) => mode.id !== "pmr")
          .map((mode) => (
            <div
              key={mode.id}
              className={"lc-route-calculation-mode" + (modes[mode.id] ? " lc-active" : "")}
              onClick={() => appStore.dispatch(actionHandleModesChanged(mode))}
              onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleModesChanged(mode)))}
              role="checkbox"
              tabIndex="0"
              aria-checked={modes[mode.id] ? "true" : "false"}
              aria-label={mode.name}
            >
              <img src={assetsPath("/assets/images/modes/" + mode.id + ".svg")} alt={mode.id} />
              {mode.name}
            </div>
          ))}
      </div>
    </>
  );

  const travelerProfil = (
    <>
      <div
        className="lc-route-calculation-walking-speeds"
        role="group"
        aria-labelledby="lc-route-calculation-walking-speeds"
      >
        {app.walkingSpeeds.map((walkingSpeed) => (
          <div
            key={walkingSpeed.type}
            className={"lc-route-calculation-walking-speed" + (walkingSpeed.value === true ? " lc-active" : "")}
            onClick={() => appStore.dispatch(actionHandleWalkingSpeedsChanged(walkingSpeed.type))}
            onKeyPress={(e) =>
              handleKeyPress(e, () => appStore.dispatch(actionHandleWalkingSpeedsChanged(walkingSpeed.type)))
            }
            role="checkbox"
            tabIndex="0"
            aria-checked={walkingSpeed.value ? "true" : "false"}
            aria-label={translate("route-calculation-walking-speed-" + walkingSpeed.type)}
          >
            <img
              src={assetsPath("/assets/images/walking-speed-" + walkingSpeed.type + ".svg")}
              alt={walkingSpeed.type}
            />
            {translate("route-calculation-walking-speed-" + walkingSpeed.type)}
          </div>
        ))}
        {modesChoice
          .filter((mode) => mode.id === "pmr")
          .map((mode) => (
            <div
              key={mode.id}
              className={"lc-route-calculation-mode" + (modes[mode.id] ? " lc-active" : "")}
              onClick={() => appStore.dispatch(actionHandleModesChanged(mode))}
              onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleModesChanged(mode)))}
              role="checkbox"
              tabIndex="0"
              aria-checked={modes[mode.id] ? "true" : "false"}
              aria-label={mode.name}
            >
              <img src={assetsPath("/assets/images/modes/" + mode.id + ".svg")} alt={mode.id} />
              {mode.name}
            </div>
          ))}
      </div>
    </>
  );

  const showTravelerProfil = app.walkingSpeeds.length > 0 || modesChoice.filter((m) => m.id === "pmr").length > 0;

  return (
    <div className="lc-prefs">
      <div className="lc-buttons">
        <div
          className={datesPanel ? "lc-collapse lc-date lc-active" : "lc-collapse lc-date"}
          onClick={() => {
            appStore.dispatch(actionSetRouteCalcDatesPanel(datesPanel));
            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              appStore.dispatch(actionSetRouteCalcDatesPanel(datesPanel));
              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={datesPanel ? "true" : "false"}
          aria-controls="lc-date"
          aria-label={translate(
            "aria-route-calculation-datetime",
            false,
            { key: "type", value: departure ? translate("departure") : translate("arrival") },
            {
              key: "date",
              value: calculateItineraryNow
                ? translate("route-calculation-departure-now")
                : selectedDate.format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY"),
            },
            { key: "hour", value: moment(selectedTime).format(isSystemUS(language) ? "hh:mm a" : "HH:mm") }
          )}
        >
          {departure ? translate("departure") : translate("arrival")}{" "}
          <span>
            {calculateItineraryNow
              ? translate("route-calculation-departure-now")
              : selectedDate.format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY") +
                " " +
                translate("route-calculation-departure-date-hours") +
                " " +
                moment(selectedTime).format(isSystemUS(language) ? "hh:mm a" : "HH:mm")}
          </span>
          <div className={"lc-menu-item-arrow" + (datesPanel ? " lc-active" : "")} />
        </div>
        <Collapse className="collapse-css-transition lc-date" id="lc-date" isOpen={datesPanel}>
          {(state) => <div className={"lc-" + state}>{dates}</div>}
        </Collapse>
        <div
          className={modesPanel ? "lc-collapse lc-modes lc-active" : "lc-collapse lc-modes"}
          id="lc-route-calculation-modes"
          onClick={() => {
            if (!modesPanel) {
              updateDataLayer({
                event: "map-openItineraryPanel",
                panel: translate("route-calculation-pref"),
              });
            }

            appStore.dispatch(actionSetRouteCalcModesPanel(modesPanel));
            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              appStore.dispatch(actionSetRouteCalcModesPanel(modesPanel));
              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={modesPanel ? "true" : "false"}
          aria-controls="lc-modes"
        >
          {translate("route-calculation-pref")}
          <span>
            {modesChoice
              .filter((mode) => mode.id !== "pmr")
              .filter((mode) => modes[mode.id])
              .map((mode) => mode.name)
              .join(", ")}
          </span>
          <div className={"lc-menu-item-arrow" + (modesPanel ? " lc-active" : "")} />
        </div>
        <Collapse className="collapse-css-transition lc-modes" id="lc-modes" isOpen={modesPanel}>
          {(state) => <div className={"lc-" + state}>{prefs}</div>}
        </Collapse>
        {showTravelerProfil && (
          <>
            <div
              className={walkingSpeedsPanel ? "lc-collapse lc-profile lc-active" : "lc-collapse lc-profile"}
              onClick={() => {
                if (!walkingSpeedsPanel) {
                  updateDataLayer({
                    event: "map-openItineraryPanel",
                    panel: translate("route-calculation-walking-speed"),
                  });
                }

                appStore.dispatch(actionSetRouteCalcWalkingSpeedsPanel(walkingSpeedsPanel));
                resize(isMobile);
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  appStore.dispatch(actionSetRouteCalcWalkingSpeedsPanel(walkingSpeedsPanel));
                  resize(isMobile);
                })
              }
              role="button"
              tabIndex="0"
              aria-expanded={walkingSpeedsPanel ? "true" : "false"}
              aria-controls="lc-profile"
            >
              {translate("route-calculation-walking-speed")}
              <span>
                {app.walkingSpeeds.find((walkingSpeed) => walkingSpeed.value === true) !== undefined
                  ? translate(
                      "route-calculation-walking-speed-" +
                        app.walkingSpeeds.find((walkingSpeed) => walkingSpeed.value === true).type
                    )
                  : modesChoice
                      .filter((mode) => mode.id === "pmr")
                      .filter((mode) => modes[mode.id])
                      .map((mode) => mode.name)}
              </span>
              <div className={"lc-menu-item-arrow" + (walkingSpeedsPanel ? " lc-active" : "")} />
            </div>
            <Collapse className="collapse-css-transition lc-profile" id="lc-profile" isOpen={walkingSpeedsPanel}>
              {(state) => <div className={"lc-" + state}>{travelerProfil}</div>}
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export const buildTabsPanel = (state) => {
  const { component, map } = state.app;
  const { pois, places, tab, town } = component.state;
  const { moduleData } = component.props;

  if (component.props.placeClicked && tab === 0) {
    const { openedCollapse, placeClicked } = component.props;

    if (placeClicked.cat_id === openedCollapse && !pois[placeClicked.cat_id]) {
      for (const i in pois) {
        if (pois[i][placeClicked.cat_id]) {
          appStore.dispatch(actionSetOpenedCollapse(i));
        }
      }
    }
  }

  if (!moduleData) {
    if (tab === 0) {
      appStore.dispatch(actionBuildTransportPlaces(pois));
      appStore.dispatch(actionBuildPublicPlaces([]));
    } else if (tab === 1) {
      appStore.dispatch(actionBuildTransportPlaces([]));
      appStore.dispatch(actionBuildPublicPlaces(places));
    }
  }

  resize(map.props.isMobile);

  return (
    <>
      <Tabs
        selectedIndex={component.state.tab || 0}
        onSelect={(index) => onTabSelected(component, index)}
        selectedTabClassName="lc-active"
        selectedTabPanelClassName="lc-active lc-scroll"
        className="lc-react-tabs"
      >
        <TabList className="lc-tab-list">
          <Tab className="lc-tab">
            {translate("transport-tab")}
            <br />
            <span>{town ? town.name.replace(/arrondissement/i, "") : translate("time-around-tab")}</span>
          </Tab>
          <Tab className="lc-tab">
            {translate("places-tab")}
            <br />
            <span>{town ? town.name.replace(/arrondissement/i, "") : translate("time-around-tab")}</span>
          </Tab>
        </TabList>
        <TabPanel className="lc-tab-panel" data-lc-scroll={component.state.tab === 0 ? "scroll" : ""}>
          {renderLinesGroup(component)}
        </TabPanel>
        <TabPanel className="lc-tab-panel" data-lc-scroll={component.state.tab === 1 ? "scroll" : ""}>
          {appStore.dispatch(actionBuildPlacesByCatInList(component.state.places))}
        </TabPanel>
      </Tabs>
    </>
  );
};

const clickPlace = (e, place, state) => {
  e.stopPropagation();

  const { pathname, search } = history.location;
  const { openedCollapse, thematicPlaces } = state.board;
  const { component, token } = state.app;
  const { pois } = component.state;

  message({ clicked: "thematic-item", id: place.id });

  if (pathname.includes("around")) {
    if (place.cat_id === "poi_type:stations") {
      clickOnPlaceInList(place, token, pois, thematicPlaces);
    } else {
      openedCollapse !== place.cat_id && appStore.dispatch(actionSetOpenedCollapse(place.cat_id));
      appStore.dispatch(actionOpenMarker(place));
    }
  } else {
    if (place.coord) {
      history.push({
        pathname,
        search: !search.includes(place.id) ? "?place=" + place.id : "",
      });
    } else {
      component.setState({ displayInformations: place.id });
    }
  }
};

const renderPlacesList = (state, places) => {
  const { language, component } = state.app;
  const { placeClicked, currentStationDepartureIndex, currentStationArrivalIndex } = state.board;
  const { displayInformations } = component.state;

  return places
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((place) => (
      <div
        key={place.id}
        className="lc-place"
        onClick={(e) => {
          clickPlace(e, place, state);
        }}
        onKeyPress={(e) => handleKeyPress(e, () => clickPlace(e, place, state))}
        role="button"
        tabIndex="0"
        onMouseEnter={() => appStore.dispatch(actionOverMarker(place))}
        onMouseLeave={() => setTimeout(() => appStore.dispatch(actionOutMarker(place)))}
      >
        &bull;
        <div className="lc-place-content">
          <span
            className={
              "lc-place-content-name" + (placeClicked && place && placeClicked.id === place.id ? " lc-selected" : "")
            }
          >
            {place.name}
          </span>

          {displayInformations === place.id && (
            <span className="lc-informations-display" dangerouslySetInnerHTML={{ __html: place.informations }} />
          )}
          {placeClicked && placeClicked.error && (
            <div className={"lc-" + placeClicked.error.severity}>
              <img src={assetsPath("/assets/images/error.svg")} alt={translate("severity-error")} />
              {placeClicked.error.message}
            </div>
          )}

          {placeClicked && !placeClicked.error && placeClicked.id === place.id && (
            <div className="lc-place-infos" data-lc-place-infos>
              {/* stand informations */}
              {placeClicked.stand ? (
                <>
                  {placeClicked.cat_id === "poi_type:amenity:bicycle_rental" && (
                    <div>
                      {placeClicked.stand.status === "open" ? (
                        <div className="lc-bss">
                          <span className="lc-bikes">
                            {placeClicked.stand.available_bikes}
                            <img
                              src={assetsPath("/assets/images/modes/bss.svg")}
                              alt={translate("bss-bikes-available")}
                            />
                          </span>
                          <span className="lc-seats">
                            {placeClicked.stand.available_places}
                            <img src={assetsPath("/assets/images/bss-seat.svg")} alt={translate("available-places")} />
                          </span>
                        </div>
                      ) : (
                        <div className="lc-place-infos" data-lc-place-infos>
                          {translate("bss-closed")}
                        </div>
                      )}
                    </div>
                  )}
                  {placeClicked.cat_id === "poi_type:amenity:park_ride" && (
                    <div>
                      <span className="lc-parcs">
                        {placeClicked.stand.total_places} {translate("total-places")}&nbsp;
                        {placeClicked.stand.occupied_PRM
                          ? "- " + placeClicked.stand.occupied_PRM + " " + translate("disabled-spaces")
                          : ""}
                      </span>
                    </div>
                  )}
                  {placeClicked.cat_id === "poi_type:amenity:parking" && (
                    <div className="lc-parking-seats-informations">
                      {placeClicked.stand.available && placeClicked.stand.available !== 0 ? (
                        <span className="lc-realtime-seats">
                          {placeClicked.stand.available} {translate("available-places")}&nbsp;
                          <Tippy
                            theme={"latitude"}
                            touch={["hold", 500]}
                            placement={"right"}
                            boundary="window"
                            content={translate("realtime-gif-title")}
                          >
                            <img src={assetsPath("/assets/images/realtime.gif")} alt={translate("realtime-gif-alt")} />
                          </Tippy>
                        </span>
                      ) : placeClicked.stand.available === 0 ? (
                        <span className="lc-realtime-seats">{translate("no-available-places")}</span>
                      ) : (
                        <span className="lc-realtime-seats">{translate("no-informations-for-available-places")}</span>
                      )}
                      {place.capacity && (
                        <div className="lc-parcs">
                          <div className="lc-parcs-title">{translate("total-places")}</div>
                          &nbsp;<span>{place.capacity}</span>
                          {placeClicked.stand.occupied_PRM
                            ? "- " + placeClicked.stand.occupied_PRM + " " + translate("disabled-spaces")
                            : ""}
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                (placeClicked.cat_id === "poi_type:amenity:park_ride" ||
                  placeClicked.cat_id === "poi_type:amenity:parking" ||
                  placeClicked.cat_id === "poi_type:amenity:bicycle_rental") && (
                  <div className="lc-loading" data-lc-loading>
                    <img src={assetsPath("/assets/images/loading.gif")} width={20} alt={translate("back")} />
                  </div>
                )
              )}

              {/* stations informations */}
              {placeClicked.cat_id === "poi_type:stations" && (
                <div>
                  <div className="lc-station-schedules">
                    <div className="lc-station-schedules-header">
                      <div>{translate("sncf-stations-departure")}</div>
                    </div>
                    <div
                      className={
                        "lc-station-schedules-content" +
                        (place.stand && place.stand.departures.length ? " lc-not-empty" : "")
                      }
                    >
                      {place.stand && place.stand.departures.length
                        ? place.stand.departures
                            .filter(
                              (_, index) =>
                                index > currentStationDepartureIndex * 3 - 1 &&
                                index <= currentStationDepartureIndex * 3 + 2
                            )
                            .map((departure, index) => (
                              <div key={index} className="lc-station-schedules-entry">
                                <div>
                                  {departure.realtime ? (
                                    <span>
                                      {departure.time} <del>{departure.basetime}</del>
                                    </span>
                                  ) : (
                                    departure.basetime
                                  )}
                                </div>
                                <div className="lc-station-schedules-entryDirection">
                                  {departure.direction}
                                  <div className="lc-station-schedules-entry-mode">{departure.mode}</div>
                                </div>
                              </div>
                            ))
                        : translate("no-stations-departures")}
                    </div>
                    {place.stand && place.stand.departures.length > 3 && (
                      <div className="lc-station-pagination">
                        <div
                          className="lc-station-pagination-previous"
                          onClick={(e) => {
                            e.stopPropagation();
                            currentStationDepartureIndex > 0 &&
                              appStore.dispatch(actionDecrementStationIndex("departure"));
                          }}
                          onKeyPress={(e) =>
                            handleKeyPress(e, () => {
                              currentStationDepartureIndex > 0 &&
                                appStore.dispatch(actionDecrementStationIndex("departure"));
                            })
                          }
                          role="button"
                          tabIndex="0"
                        >
                          <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} />
                        </div>
                        <div
                          className="lc-station-pagination-next"
                          onClick={(e) => {
                            e.stopPropagation();
                            currentStationDepartureIndex < 6 &&
                              appStore.dispatch(actionIncrementStationIndex("departure"));
                          }}
                          onKeyPress={(e) =>
                            handleKeyPress(e, () => {
                              currentStationDepartureIndex < 6 &&
                                appStore.dispatch(actionIncrementStationIndex("departure"));
                            })
                          }
                          role="button"
                          tabIndex="0"
                        >
                          <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lc-station-schedules">
                    <div className="lc-station-schedules-header">
                      <div>{translate("sncf-stations-arrival")}</div>
                    </div>
                    <div
                      className={
                        "lc-station-schedules-content" +
                        (place.stand && place.stand.arrivals.length ? " lc-not-empty" : "")
                      }
                    >
                      {place.stand && place.stand.arrivals.length
                        ? place.stand.arrivals
                            .filter(
                              (_, index) =>
                                index > currentStationArrivalIndex * 3 - 1 &&
                                index <= currentStationArrivalIndex * 3 + 2
                            )
                            .map((arrival, index) => (
                              <div key={index} className="lc-station-schedules-entry">
                                <div>
                                  {arrival.realtime ? (
                                    <span>
                                      {arrival.time} <del>{arrival.basetime}</del>
                                    </span>
                                  ) : (
                                    arrival.basetime
                                  )}
                                </div>
                                <div className="lc-station-schedules-entryDirection">
                                  {arrival.direction}
                                  <br />
                                  <span className="lc-station-schedules-entry-mode">{arrival.mode}</span>
                                </div>
                              </div>
                            ))
                        : translate("no-stations-arrivals")}
                    </div>
                    {place.stand && place.stand.arrivals.length > 3 && (
                      <div className="lc-station-pagination">
                        <div
                          className="lc-station-pagination-previous"
                          onClick={(e) => {
                            e.stopPropagation();
                            currentStationArrivalIndex > 0 && appStore.dispatch(actionDecrementStationIndex("arrival"));
                          }}
                          onKeyPress={(e) =>
                            handleKeyPress(e, () => {
                              currentStationArrivalIndex > 0 &&
                                appStore.dispatch(actionDecrementStationIndex("arrival"));
                            })
                          }
                          role="button"
                          tabIndex="0"
                        >
                          <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} />
                        </div>
                        <div
                          className="lc-station-pagination-next"
                          onClick={(e) => {
                            e.stopPropagation();
                            currentStationArrivalIndex < 6 && appStore.dispatch(actionIncrementStationIndex("arrival"));
                          }}
                          onKeyPress={(e) =>
                            handleKeyPress(e, () => {
                              currentStationArrivalIndex < 6 &&
                                appStore.dispatch(actionIncrementStationIndex("arrival"));
                            })
                          }
                          role="button"
                          tabIndex="0"
                        >
                          <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* bicycle_parking informations */}
              {placeClicked.cat_id === "poi_type:amenity:bicycle_parking" && (
                <div>
                  <span className="lc-parcs">
                    {place.capacity} {translate("total-places")}
                  </span>
                </div>
              )}

              {/* display opening hours */}
              {placeClicked.opening_hours && humanReadableOpeningHours(placeClicked.opening_hours, language)}

              {/* display address */}
              {placeClicked.address && (
                <div className="lc-address">
                  <div className="lc-address-title">{translate("address-title")}</div>
                  <div className="lc-address-details">
                    {placeClicked.address}
                    <br />
                    {placeClicked.cp} {placeClicked.city}
                  </div>
                </div>
              )}

              {/* display email */}
              {placeClicked.email && (
                <div className="lc-mail">
                  <div className="lc-mail-title">{translate("mail-title")}</div>
                  <div className="lc-mail-details">
                    <a
                      href={`mailto:${placeClicked.email}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {placeClicked.email}
                    </a>
                  </div>
                </div>
              )}

              {/* display phone number */}
              {placeClicked.phone && (
                <div className="lc-phone">
                  <div className="lc-phone-title">{translate("phone-title")}</div>
                  <div className="lc-phone-details">
                    <a
                      href={`tel:${placeClicked.phone}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {placeClicked.phone}
                    </a>
                  </div>
                </div>
              )}

              {/* display pmr */}
              {placeClicked.wheelchair && (
                <div className="lc-wheelchair">
                  <div className="lc-wheelchair-title">{translate("wheelchair-title")}</div>
                  &nbsp;
                  <span>
                    {placeClicked.wheelchair === "yes"
                      ? translate("wheelchair-yes")
                      : placeClicked.wheelchair === "no"
                      ? translate("wheelchair-no")
                      : translate("wheelchair-limited")}
                  </span>
                </div>
              )}

              {/* dispaly informations */}
              {placeClicked.info && <span className="lc-info">{placeClicked.info}</span>}
            </div>
          )}
        </div>
      </div>
    ));
};
