import { useEffect, useState } from "react";
import { useMedia } from "./useMedia";

export function useClick(ref, callback) {
  const [value, setValue] = useState(false);
  const [target, setTarget] = useState(null);
  const isDesktop = useMedia();

  const handleMouseOver = (event) => {
    const pageX = !isDesktop ? (event.touches ? event.touches[0].pageX : event.pageX) : event.pageX;

    setTarget(pageX);
    setValue(true);
  };

  const handleMouseOut = (e) => {
    setTarget(0);
    setValue(false);
  };

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener("mousedown", handleMouseOver);
      node.addEventListener("touchstart", handleMouseOver);
      document.body.addEventListener("mouseup", handleMouseOut);
      document.body.addEventListener("touchend", handleMouseOut);
      document.body.addEventListener("mouseleave", handleMouseOut);

      value && document.body.addEventListener("mousemove", callback);
      value && document.body.addEventListener("touchmove", callback);

      return () => {
        node.removeEventListener("mousedown", handleMouseOver);
        node.removeEventListener("touchstart", handleMouseOver);
        document.body.removeEventListener("mouseup", handleMouseOut);
        document.body.removeEventListener("touchend", handleMouseOut);
        document.body.removeEventListener("mouseleave", handleMouseOut);

        document.body.removeEventListener("mousemove", callback);
        document.body.removeEventListener("touchmove", callback);
      };
    }

    // eslint-disable-next-line
  }, [ref.current, value]); // Recall only if ref changes

  return [value, target];
}
