import L from "leaflet";
import { appStore } from "../store";
import React from "react";
import { actionBuildTransportPlaces, actionBuildMarker, actionOpenMarker } from "../actions/withRedux";
import {
  actionGoToValid,
  actionInputEndItemsChange,
  actionInputEndValueChange,
  actionInputItemsChange,
  actionInputStartItemsChange,
  actionInputStartValueChange,
  actionInputValueChange,
  actionResetStationIndex,
  actionSetGeolocationError,
  actionSetPlaceClicked,
  actionSetOpenedCollapse,
} from "../actions/board";
import {
  actionSetLineSelected,
  actionSetReduxMarkers,
  actionSetPublicPlaces,
  actionSetTransportPlaces,
} from "../actions/map";
import axios from "../middlewares/axios";
import history from "../history";
import { batch } from "../actions/app";
import { initModal, toggleModal } from "../actions/modal";
import moment from "moment";
import { updateDataLayer } from "../tracking";
import BikeInterface from "../interfaces/BikeInterface";
import exportedStyles from "../scss/app.scss";
import { message } from "./message";

const {
  REACT_APP_LIBRARY_URL,
  REACT_APP_PROJECT,
  REACT_APP_AUTOCOMPLETE_GEOLOCATION,
  REACT_APP_POLES,
  REACT_APP_AREAS_ZOOM_LEVEL,
  REACT_APP_DISRUPTION,
} = process.env;

export const addPolesExchanges = (areas, markers, zoom, onlyLine = false) => {
  const polesToDisplay = JSON.parse(REACT_APP_POLES);

  if (polesToDisplay && polesToDisplay.ids) {
    const poles = onlyLine
      ? areas.reduce((accumulator, currentArea) => {
          const area = currentArea.props.area.id;
          const stop = markers.find((m) => m.props.stop.stop_area === area);

          if (stop && polesToDisplay.ids.includes(area)) {
            accumulator.push(currentArea);
          }

          return accumulator;
        }, [])
      : areas.filter((area) => polesToDisplay.ids.includes(area.props.area.id));

    if (zoom > polesToDisplay.zoom) {
      poles.map((pole) => {
        return markers.push(
          appStore.dispatch(
            actionBuildMarker(
              { ...pole.props.area, id: pole.props.area.id + "-pole-exchange" },
              {
                icon: L.icon({
                  iconUrl: assetsPath("/assets/images/stops/pole.svg"),
                  iconSize: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [40, 40] : [52, 52],
                  iconAnchor: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [20, 30] : [26, 39],
                }),
                area: { ...pole.props.area },
                zIndexOffset: 10,
              }
            )
          )
        );
      });
    }
  }
};

/**
 * Return the correct assets path depends on base URL
 * @param {String} path assets path
 */
export const assetsPath = (path) => (REACT_APP_LIBRARY_URL ? REACT_APP_LIBRARY_URL + path : path);

/**
 * Build Leaflet icon size / anchor for a given category
 * @param category
 * @returns {{iconAnchor: number[], iconSize: number[]}}
 */
export const buildPlaceIconClassName = (category) => {
  let className = "lc-place-icon";

  switch (category) {
    case "poi_type:amenity:bicycle_rental":
      className += " lc-bicycle-rental";
      break;

    case "poi_type:stations":
      className += " lc-stations";
      break;

    default:
      className += " lc-default";
      break;
  }

  return className;
};

/**
 * Debug in local & dev env only
 * @param {Object} {message, data} message & data to log
 * @param {String} color color of the message. Prefilled values are "success", "info", "error", or "warning"
 * @param {String} title title of the message
 */
export const debug = ({ message = "debug :", data }, color, title = "DEBUG_FRONT") => {
  if (isLocalOrDev()) {
    switch (color) {
      case "success":
        color = "Green";
        break;
      case "info":
        color = "DodgerBlue";
        break;
      case "error":
        color = "Red";
        break;
      case "warning":
        color = "Orange";
        break;
      default:
        color = "info";
    }

    console.group(`%c ${title}`, `color:${color}`);

    if (data) {
      console.log(`%c ${message}`, `color:${color}`, data);
    } else {
      console.log(`%c ${message}`, `color:${color}`);
    }

    console.groupEnd();
  }
};

export const displayDisruptedMarkersOnMap = (disruptions, reduxMarkers, currentLine, stop) => {
  const now = moment();
  // Handle stop_point & section disruptions on map
  const impactedObjects = [];

  if (reduxMarkers.length > 0 && impactedObjects.length === 0) {
    for (const disruption of disruptions) {
      // Push only impacted_objects that are between begin & end dates today
      if (now.isBetween(moment(disruption.begin), moment(disruption.end))) {
        for (const object of disruption.impacted_objects) {
          if (["section", "stop_point", "stop_area"].includes(object.type)) {
            if ((object.line && object.line === currentLine.id) || !object.line) {
              object.severity = disruption.severity;
              impactedObjects.push(object);
            }
          }
        }
      }
    }

    // Loop through each impacted object and display it on map
    const markers = [];
    const impactedMarkers = [];

    for (const impacted of impactedObjects) {
      switch (impacted.type) {
        case "section":
          const route = currentLine.routes.find((r) => r.direction_id === currentLine.direction_id);

          const from = reduxMarkers.find((marker) => {
            if (impacted.from.includes("stop_area")) {
              return (
                marker.props.stop.stop_area === impacted.from && impacted.routes.find((r) => r.id === route.route_id)
              );
            } else {
              return marker.props.stop.id === impacted.from && impacted.routes.find((r) => r.id === route.route_id);
            }
          });

          const to = reduxMarkers.find((marker) => {
            if (impacted.from.includes("stop_area")) {
              return (
                marker.props.stop.stop_area === impacted.to && impacted.routes.find((r) => r.id === route.route_id)
              );
            } else {
              return marker.props.stop.id === impacted.to && impacted.routes.find((r) => r.id === route.route_id);
            }
          });

          if (from && to) {
            // Pass the severity
            from.props.stop.severity = to.props.stop.severity = impacted.severity;

            // Add all markers between the "from" and the "to"
            impactedMarkers.push(...reduxMarkers.slice(reduxMarkers.indexOf(from), reduxMarkers.indexOf(to) + 1));
          }

          break;
        case "stop_area":
        case "stop_point":
          const stop = reduxMarkers.find(
            (marker) => marker.props.stop[impacted.type === "stop_area" ? "stop_area" : "id"] === impacted.id
          );

          if (stop) {
            stop.props.stop.severity = impacted.severity;
            impactedMarkers.push(stop);
          }

          break;
        default:
          console.warn(impacted.type + " is not handled yet");
      }

      for (const impactedMarker of impactedMarkers) {
        // Populate with retrieved markers
        impactedMarker &&
          !markers.find((m) => m.props.stop.id === impactedMarker.props.stop.id) &&
          markers.push(
            appStore.dispatch(
              actionBuildMarker(impactedMarker.props.stop, {
                key: currentLine.code + "_" + impactedMarker.props.stop.index + "_disrupted",
                icon: new L.DivIcon({
                  className: `lc-circle-icon-marker ${impactedMarker.props.stop.terminus ? " lc-stop-terminus" : ""}`,
                  iconSize: impactedMarker.props.stop.terminus ? [10, 10] : [8, 8],
                  iconAnchor: new L.Point(4, 4),
                  html: `<div><span style="border: 2px solid #${currentLine.color}; background: ${
                    impactedMarker.props.stop.severity === "blocking" ? "red" : "orange"
                  };" /><div class=${
                    impactedMarker.props.stop.severity === "blocking"
                      ? REACT_APP_DISRUPTION
                        ? "lc-" + JSON.parse(REACT_APP_DISRUPTION).map
                        : ""
                      : ""
                  } style="border-color: #${currentLine.color}"></div></div>`,
                }),
                stop: impactedMarker.props.stop,
                zIndexOffset: 250,
              })
            )
          );
      }
    }

    // If we have open a stop, search if it's in impacted list to open with disruption's error
    if (stop) {
      const disrupted = impactedMarkers.find((m) => m.props.stop.id === stop);

      // Dispatch the open marker action
      disrupted && setTimeout(() => appStore.dispatch(actionOpenMarker(disrupted.props.stop)));
    }

    appStore.dispatch(actionSetReduxMarkers(reduxMarkers.concat(markers)));
  }
};

/**
 * Comparator to use on a sort Array function
 * Ex : array.sort(compareValues('key', 'desc'))
 *
 * @param {String} key props to sort by
 * @param {String} order 'asc' or 'desc'
 */
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];
    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
}

/**
 *
 * @param envVar
 */
export const envVarToBool = (envVar) => {
  if (envVar) {
    return envVar === "true";
  } else {
    return false;
  }
};

/**
 * Flatten the given object
 * @param object
 * @returns Array
 */
export const flattenObject = (object) => {
  const acc = [];

  for (const item of Object.keys(object)) {
    const items = Array.isArray(object[item])
      ? object[item]
      : Object.keys(object[item]).reduce((acc, subItem) => {
          acc.push(...object[item][subItem]);
          return acc;
        }, []);

    acc.push(...items);
  }

  return acc;
};

/**
 *
 * @param data
 * @param reduxMarkers
 * @returns {*}
 */
export const getRef = (data, reduxMarkers) => {
  // If we have the ref on the data (like in stop_areas or stop_points
  // without line selected) grab it, else, retrieve it from the
  // reduxMarkers from the map
  const marker = data.ref
    ? data
    : reduxMarkers.find((m) => {
        if (m.props.area) {
          if (data.id.includes("stop_area")) {
            return m.props.area.id === data.id;
          } else {
            return m.props.area.id === data.stop_area;
          }
        } else {
          if (data.id.includes("stop_area")) {
            return m.props.stop.stop_area === data.id;
          } else {
            return m.props.stop.id === data.id;
          }
        }
      });

  if (!marker) {
    return;
  }

  return (marker.props ? marker.props.area || marker.props.stop : marker).ref;
};

/**
 * Retrieve all line data for the given one
 * @param lines
 * @param line
 * @returns Object
 */
export const getLine = (lines, line) => {
  return {
    ...lines.find(({ id, code, network }) =>
      line.id ? id === line.id : code === line.code && network === line.network
    ),
    ...line,
  };
};

/**
 * Convert URL search params to object
 * @param url
 */
export const getURLSearchParams = (url) => {
  const search = new URLSearchParams(url.search);
  const params = {};

  for (const entry of search.entries()) {
    params[entry.shift()] = decodeURIComponent(entry.shift());
  }

  return params;
};

export const humanReadableOpeningHours = (opening, language) => {
  const openingTable = opening.split(";");

  return (
    <div className="lc-opening-hours">
      <div className="lc-opening-hours-title">{translate("open-hour-title")}</div>
      <div className="lc-opening-hours-list">
        {openingTable.map((rule) => {
          switch (rule) {
            case "24/7":
              return <span key={rule}>{translate("open-hour-24/7")}</span>;
            default:
              return <span key={rule}>{replaceAllDays(rule, language)}</span>;
          }
        })}
      </div>
    </div>
  );
};

const replaceAllDays = (string, language) => {
  const days = {
    Mo: translate("open-hour-mo"),
    Tu: translate("open-hour-tu"),
    We: translate("open-hour-we"),
    Th: translate("open-hour-th"),
    Fr: translate("open-hour-fr"),
    Sa: translate("open-hour-sa"),
    Su: translate("open-hour-su"),
    PH: translate("open-hour-ph"),
    SH: translate("open-hour-sh"),
  };

  let stringReplace = string;

  // remove first blank char
  if (stringReplace.charAt(0) === " ") {
    stringReplace = stringReplace.substr(1);
  }

  // replace days
  for (const day of Object.keys(days)) {
    stringReplace = stringReplace.replace(day, days[day]);
  }

  // add colon after day
  if (!string.includes("SH") && !string.includes("PH")) {
    stringReplace = stringReplace.replace(" ", language === "en" ? ": " : " : ");
  }

  // replace - between days
  if (stringReplace.split(": ")[0].includes("-")) {
    stringReplace = stringReplace.replace("-", ` ${translate("open-hour-days-separator")} `);
  }

  // add space between hours
  stringReplace = stringReplace.replace(/-/g, " - ");

  return stringReplace;
};

/**
 * Detect if we are on a local or dev env
 */
export const isLocalOrDev = () => ["local", "dev"].includes(process.env.REACT_APP_ENV);

/**
 * Test if we are on a thematics module
 */
export const isThematics = () => {
  const pathname = history.location.pathname;

  return (
    pathname.includes("/pt-vente") ||
    pathname.includes("/e-tecely") ||
    pathname.includes("/gab") ||
    pathname.includes("/p+r") ||
    pathname.includes("/agence") ||
    pathname.includes("/pt-service") ||
    pathname.includes("/park-ride") ||
    pathname.includes("/bike") ||
    pathname.includes("/autosharing")
  );
};

/**
 *  Detect if journey is on an other day
 * @param {*} journey
 * @param {Boolean} roadmap Message is displayed on roadmap
 */
export const itineraryOnOtherDay = (journey, roadmap = false) => {
  if (
    moment(journey.requested_date_time.split("T")[0]).diff(moment(journey.departure_date_time.split("T")[0]), "days") <
    0
  ) {
    return (
      <div className={"lc-journey-warning lc-itinerary" + (roadmap ? " lc-in-roadmap" : "")}>
        <div className="lc-icon" />
        {translate("route-calculation-departure-next-day")} {moment(journey.departure_date_time).format("D MMMM")}
      </div>
    );
  }
};

// returns the most important groups
export const mostImportantGroup = (groups, modes) => {
  for (const mode of modes) {
    if (Object.keys(groups).includes(mode.name)) {
      return mode.name;
    }
  }

  return null;
};

/**
 * Add resize event and return a function to remove it
 * @returns {function(): void}
 */
export const addResizeEvent = (isMobile) => {
  const resizeListener = () => {
    resize(isMobile);
  };

  window.addEventListener("resize", resizeListener);
  return () => window.removeEventListener("resize", resizeListener);
};

/**
 * Resize the fckin panel
 */
export const resize = (isMobile, div) => {
  if (isMobile) {
    return;
  }

  const { domElement } = appStore.getState().app;
  const { headerHeight } = exportedStyles;
  const boundingRects = document.querySelector(domElement).getBoundingClientRect();
  const board = document.querySelector(`${domElement} .lc-board`);
  const toBeResized = div || document.querySelector("[data-lc-scroll='scroll']");

  if (!toBeResized) {
    return;
  }

  const boundingRectsElement = toBeResized.getBoundingClientRect();

  const parentWithMargins = Array.prototype.find.call(
    toBeResized.offsetParent.children,
    (child) => child.classList.contains("lc-elevation") || child.classList.contains("lc-react-tabs")
  );

  let height =
    boundingRects.height +
    parseInt(headerHeight) -
    (boundingRectsElement.y - boundingRects.y) -
    parseInt(getComputedStyle(board).marginTop) -
    parseInt(getComputedStyle(toBeResized).paddingTop) -
    parseInt(getComputedStyle(toBeResized).paddingBottom);

  if (parentWithMargins) {
    height -= parseInt(getComputedStyle(parentWithMargins).marginBottom);
  }

  toBeResized.style.maxHeight = height + "px";
};

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => {
  array.sort((a, b) => a[property].localeCompare(b[property]));
};

/**
 * Update the position of a popup, to be right near the marker icon
 * @param leafletElement
 */
export const updatePopupPosition = (leafletElement) => {
  // Retrieve the popup element & update its position
  const icon = leafletElement.getElement();

  // TODO Why there is a posibility to get icon null ???
  if (!icon) {
    return;
  }

  const popup = leafletElement.getPopup();
  const element = popup.getElement();

  popup.options.offset = new L.Point(element.offsetWidth / 2 + icon.offsetWidth / 2 + 17, element.offsetHeight - 2.5);
  popup.update();
};

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(this, arguments);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, arguments);
    }
  };
};

/**
 * Launch the request debounced
 */
const debounceRequest = debounce((inputValue, type, state) => {
  const { pathname } = history.location;
  const { component } = state.app;
  let fileForThematics = null;

  if (component?.props?.thematicPlaces) {
    fileForThematics = component.props.moduleData.file;
  }

  if (inputValue.length === 0) {
    initInputAddresses(component);
  } else {
    const params = {
      params: {
        type,
        query: inputValue,
        file: fileForThematics,
      },
    };

    if (state.tourismPartners) {
      params.params.typePartner = state.tourismPartners.typePartner;
    }

    axios
      .get("/api/autocomplete", params)
      .then((result) => {
        const geolocInput = [
          {
            id: "geoloc",
            name: translate("autocomplete-geoloc"),
            geolocation: true,
          },
        ];

        // TODO Finish it for modules
        let autocompleteResults = result.data;
        const results = [];

        for (const r of result.data) {
          if (r.isPartner) {
            results.push(r);
          } else if (r.embedded_type === "stop_area" || r.id.includes("stop_area:")) {
            const stopArea = state.app.areas.find((a) => a.id === r.id);

            if (stopArea) {
              results.push(r);
            }
          } else if (r.id.includes("line:")) {
            results.push(r);
          } else {
            if (!r.embedded_type && r.id !== "no_result" && r.merge === true) {
              results.push({
                embedded_type: r.id.includes("aeroway") ? "aeroway" : "poi",
                addByMerge: true,
                id: r.id,
                name: r.label,
                poi: r,
              });
            } else {
              results.push(r);
            }
          }
        }

        autocompleteResults = results;

        let resultToDispatch = envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION)
          ? geolocInput.concat(autocompleteResults)
          : autocompleteResults;

        if (
          pathname.includes("/places-interest") ||
          pathname.includes("/lines") ||
          state.board.thematicPlaces ||
          pathname.includes("/towns")
        ) {
          resultToDispatch = resultToDispatch.filter((a) => a.id !== "geoloc");
        }

        if (!pathname.includes("/route-calculation")) {
          appStore.dispatch(actionInputItemsChange(resultToDispatch));
        } else {
          if (type === "inputStart") {
            appStore.dispatch(actionInputStartItemsChange(resultToDispatch));
          } else {
            appStore.dispatch(actionInputEndItemsChange(resultToDispatch));
          }
        }
      })
      .catch((e) => {
        const error = e.response && e.response.data ? e.response.data.id : e;

        console.warn(error);
      });
  }
}, 500);

/**
 * Display the disruptions datetime well formatted
 * @param {String} begin Navitia datetime of the disruption's beginning
 * @param {String} end Navitia datatime of the disruption's end
 */
export const disruptionsDatetime = (begin, end, language) => {
  const today = moment().format("YYYYMMDD");
  const beginDate = begin.split("T").shift();
  const endDate = end.split("T").shift();
  const beginMomentDate = moment(begin).format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY");
  const endMomentDate = moment(end).format(isSystemUS(language) ? "MM/DD/YYYY" : "DD/MM/YYYY");
  const beginMomentHours = moment(begin).format(isSystemUS(language) ? "hh:mm a" : "HH:mm");
  const endMomentHours = moment(end).format(isSystemUS(language) ? "hh:mm a" : "HH:mm");

  if (beginDate === today && endDate === today) {
    switch (language) {
      case "en":
        return "Today from " + beginMomentHours + " to " + endMomentHours;
      default:
        return "Aujourd'hui de " + beginMomentHours + " à " + endMomentHours;
    }
  } else if (beginDate === today) {
    switch (language) {
      case "en":
        return "Today at " + beginMomentHours + " to " + endMomentDate + " at " + endMomentHours;
      default:
        return "Aujourd'hui à " + beginMomentHours + " au " + endMomentDate + " à " + endMomentHours;
    }
  } else if (endDate === today) {
    switch (language) {
      case "en":
        return "From " + beginMomentDate + " at " + beginMomentHours + " until today at " + endMomentHours;
      default:
        return "Du " + beginMomentDate + " à " + beginMomentHours + " jusqu'à aujourd'hui à " + endMomentHours;
    }
  } else if (beginDate === endDate) {
    switch (language) {
      case "en":
        return beginMomentDate + " from " + beginMomentHours + " to " + endMomentHours;
      default:
        return "Le " + beginMomentDate + " de " + beginMomentHours + " à " + endMomentHours;
    }
  } else {
    switch (language) {
      case "en":
        return "From " + beginMomentDate + " at " + beginMomentHours + " to " + endMomentDate + " at " + endMomentHours;
      default:
        return "Du " + beginMomentDate + " à " + beginMomentHours + " au " + endMomentDate + " à " + endMomentHours;
    }
  }
};

/**
 * Init input for autocomplete with geoloc object and history
 */
export const initInputAddresses = async (component) => {
  const { pathname } = history.location;
  const params = getURLSearchParams(history.location);

  const geolocInput = [
    {
      id: "geoloc",
      name: translate("autocomplete-geoloc"),
      geolocation: true,
    },
  ];

  const historyRecovered = storageAvailable("localStorage")
    ? JSON.parse(window.localStorage.getItem(`history_${REACT_APP_PROJECT}`)) || []
    : [];

  const historyStored = [];

  for (const historicItem of historyRecovered) {
    const type = historicItem.type;

    switch (type) {
      case "line":
      case "poi":
      case "stop_area":
        const file = type === "line" ? "lines" : type === "poi" ? "places" : "areas";
        const findItem = component?.props[file]?.find((i) => i.id === historicItem.item_id);

        if (findItem) {
          historyStored.push(historicItem);
        }

        break;
      default:
        historyStored.push(historicItem);
    }
  }

  const init = [];

  if (pathname.includes("/lines")) {
    init.push(...historyStored.filter((h) => h.type === "stop_area" || h.type === "line"));
  } else if (pathname.includes("/around")) {
    envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION) && init.push(...geolocInput);
    init.push(...historyStored);
  } else if (pathname.includes("/route-calculation")) {
    envVarToBool(REACT_APP_AUTOCOMPLETE_GEOLOCATION) && init.push(...geolocInput);
    init.push(...historyStored.filter((h) => h.type !== "line"));
  } else if (pathname.includes("/station")) {
    init.push(...historyStored.filter((h) => h.type === "stop_area"));
  }

  if (!pathname.includes("/route-calculation")) {
    appStore.dispatch(actionInputItemsChange(init));
  } else {
    if (!params.from) {
      appStore.dispatch(actionInputStartItemsChange(init));
    }

    if (!params.to) {
      appStore.dispatch(actionInputEndItemsChange(init));
    }
  }
};

export const focusInput = (e, inputProps, state) => {
  const input = e.target;

  // Remove previous geolocation errors
  appStore.dispatch(actionSetGeolocationError(null));

  if (state.app.isMobile) {
    input.blur();
    appStore.dispatch(batch(initModal(inputProps), toggleModal()));
  }
};

/**
 * While user using an autocomplete input :
 * remove the pin // TODO
 * display chars in input
 * launch debounce function
 * @param event
 * @param type
 * @param state
 */
export const onChangeAutocompleteInput = (event, type, state) => {
  const inputValue = event.target.value;
  const { pathname } = history.location;

  appStore.dispatch(actionGoToValid(false));
  // TODO remove marker around/start/end

  if (pathname.includes("/route-calculation")) {
    const params = getURLSearchParams(history.location);

    if (type === "inputStart") {
      if (inputValue.length === 0 && params.from) {
        if (params.to) {
          history.push({
            pathname,
            search: "?to=" + params.to,
          });
        } else {
          history.push({
            pathname,
          });
        }
      } else {
        appStore.dispatch(actionInputStartValueChange({ name: inputValue }));
      }
    } else {
      if (inputValue.length === 0 && params.to) {
        if (params.from) {
          history.push({
            pathname,
            search: "?from=" + params.from,
          });
        } else {
          history.push({
            pathname,
          });
        }
      } else {
        appStore.dispatch(actionInputEndValueChange({ name: inputValue }));
      }
    }
  } else {
    appStore.dispatch(actionInputValueChange(inputValue));
  }

  debounceRequest(inputValue, type, state);
};

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

/**
 * Get the item selected by the user and adapt input about it
 * @param valueSelected
 * @param itemSelected
 * @param type
 * @param state
 * @param isModal
 */
export const onSelectAutocompleteValue = async (valueSelected, itemSelected, type, state, isModal) => {
  const { component, lines, stops, areas, touchscreenSelected, modules } = state.app;
  const { pathname } = history.location;
  const params = getURLSearchParams(history.location);

  // Add selected item in history
  if (!itemSelected.geolocation && !itemSelected.history && !itemSelected.region && storageAvailable("localStorage")) {
    addHistoricItem(itemSelected);
  }

  if (pathname.includes("/lines")) {
    if (itemSelected.id.includes("stop_area")) {
      component.onStopSelected(areas.find((a) => a.id === itemSelected.item_id || a.id === itemSelected.id));
      appStore.dispatch(actionGoToValid(true));
    } else if (itemSelected.id.includes("stop_point")) {
      itemSelected.item_id = stops.filter((s) => s.id === itemSelected.item_id)[0].stop_area;
      component.onStopSelected(areas.find((a) => a.id === itemSelected.item_id));
      appStore.dispatch(actionGoToValid(true));
    } else {
      component.onLineSelected(lines.find((l) => l.id === itemSelected.item_id || l.id === itemSelected.id));
    }
  } else if (pathname.includes("/around")) {
    let searchAround = "?from=";

    if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchAround += touchscreenSelected.coords;
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 3000,
            enableHighAccuracy: true,
          });

          const { longitude, latitude } = position.coords;

          searchAround += longitude + ";" + latitude;
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal());
          // Remove the focus of the current active element
          document.activeElement.blur();
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e));
          throw e;
        }
      }
    } else if (itemSelected.history) {
      searchAround = !itemSelected.item_id.includes("line:")
        ? searchAround + itemSelected.item_id
        : "?line=" + itemSelected.item_id;
    } else {
      searchAround = !itemSelected.id.includes("line:") ? searchAround + itemSelected.id : "?line=" + itemSelected.id;
    }

    if (!searchAround.includes("line:") || !searchAround.includes("admin:fr")) {
      appStore.dispatch(actionGoToValid(true));
    }

    history.push({ pathname, search: searchAround });
  } else if (pathname.includes("/route-calculation")) {
    let searchRouteCalc = "";

    if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchRouteCalc += touchscreenSelected.coords;
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 3000,
            enableHighAccuracy: true,
          });

          const { longitude, latitude } = position.coords;

          searchRouteCalc = longitude + ";" + latitude;
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal());
          // Remove the focus of the current active element
          document.activeElement.blur();
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e));
          throw e;
        }
      }
    } else if (itemSelected.history) {
      // TODO need around to get a stop_point
      searchRouteCalc = itemSelected.item_id;
    } else {
      searchRouteCalc = itemSelected.id;
    }

    if (type === "inputStart") {
      if (!params.to) {
        history.push({ pathname, search: "?from=" + searchRouteCalc });
      } else {
        history.push({
          pathname,
          search: "?from=" + searchRouteCalc + "&to=" + params.to,
        });
      }
    } else if (type === "inputEnd") {
      if (!params.from) {
        history.push({ pathname, search: "?to=" + searchRouteCalc });
      } else {
        history.push({
          pathname,
          search: "?from=" + params.from + "&to=" + searchRouteCalc,
        });
      }
    }
  } else if (pathname.includes("/network-lines")) {
    let tabToGo = 0;

    if (itemSelected.id.includes("line:")) {
      updateDataLayer({
        event: "map-openNetworkItem",
        type: "Ligne",
        name: itemSelected.name,
        from: "Autocompletion",
      });
      tabToGo =
        modules.find((module) => module.id === "network-lines").tabs.find((tab) => tab.id === "network-map").position -
        1;
      const searchParam = addGetParam(params, { line: itemSelected.id, tab: tabToGo });

      history.push({
        pathname,
        search: searchParam,
      });
    } else if (itemSelected.id.includes("town:")) {
      updateDataLayer({
        event: "map-openNetworkItem",
        type: "Ville",
        name: itemSelected.name,
        from: "Autocompletion",
      });
      tabToGo =
        modules.find((module) => module.id === "network-lines").tabs.find((tab) => tab.id === "network-towns")
          .position - 1;

      const searchParam = addGetParam(params, { town: itemSelected.insee, tab: tabToGo });

      history.push({
        pathname,
        search: searchParam,
      });
    } else {
    }
  } else if (pathname.includes("/tourism-partners")) {
    history.push({ pathname, search: `?type=${itemSelected.type}&partner=${itemSelected.id}` });
  } else if (state.board.thematicPlaces) {
    // TODO REVIEW
    history.push({
      pathname,
      search: "?place=" + itemSelected.id.replace("history-", ""),
    });
  }

  if (type === "inputStart") {
    appStore.dispatch(actionInputStartValueChange({ name: valueSelected }));
  } else if (type === "inputEnd") {
    appStore.dispatch(actionInputEndValueChange({ name: valueSelected }));
  } else {
    appStore.dispatch(actionInputValueChange(valueSelected));
  }

  // Toggle the modal if we are on mobile
  isModal && appStore.dispatch(toggleModal());
};

/**
 * add item in historic
 * @param item
 */
export const addHistoricItem = (item) => {
  const historyStored = JSON.parse(window.localStorage.getItem(`history_${REACT_APP_PROJECT}`)) || [];

  historyStored.reverse();

  if (historyStored.filter((p) => p.item_id === item.id).length === 0) {
    if (item.id.includes("poi:")) {
      historyStored.push({
        id: "history-" + item.id,
        type: "poi",
        name: item.name,
        item_id: item.id,
        history: true,
      });
    } else if (item.id.includes("line:")) {
      historyStored.push({
        id: "history-" + item.id,
        type: "line",
        name: item.name,
        mode: item.mode,
        color: item.color,
        item_id: item.id,
        code: item.code,
        history: true,
      });
    } else if (item.id.includes("stop_area:")) {
      historyStored.push({
        id: "history-" + item.id,
        type: "stop_area",
        name: item.name,
        item_id: item.id,
        history: true,
      });
    } else if (item.address) {
      historyStored.push({
        id: "history-" + item.id,
        type: "address",
        name: item.name,
        item_id: item.id,
        history: true,
      });
    } else if (item.id.includes("admin:")) {
      historyStored.push({
        id: "history-" + item.id,
        type: "administrative_region",
        name: item.name,
        item_id: item.id,
        coord: item.administrative_region.coord,
        history: true,
      });
    }
  }

  historyStored.reverse();

  if (historyStored.length > 3) {
    historyStored.length = 3;
  }

  window.localStorage.setItem(`history_${REACT_APP_PROJECT}`, JSON.stringify(historyStored));
};

export const goToRouteCalculation = (item) => {
  appStore.dispatch(actionSetTransportPlaces([]));
  appStore.dispatch(actionSetPublicPlaces([]));

  setTimeout(() => {
    const url = `/route-calculation?to=${
      item.cat_id === "poi_type:stations"
        ? "sncf_" + item.id
        : item instanceof BikeInterface
        ? item.coord.lon + ";" + item.coord.lat
        : item.id
        ? item.id
        : item.address
        ? item.address.lon + ";" + item.address.lat
        : item
    }`;

    history.push(url);
  });
};

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property) =>
  array.filter((e, i) => array.findIndex((a) => a[property] === e[property]) === i);

/**
 * Get data from poi selection in list
 * @param place
 * @param token
 * @returns {Promise<void>}
 */
export const clickOnPlaceInList = async (place, token, pois = null, thematic = null) => {
  const needRequest = [
    "poi_type:amenity:bicycle_rental",
    "poi_type:amenity:bicycle_parking",
    "poi_type:amenity:parking",
    "poi_type:stations",
  ];

  appStore.dispatch(actionResetStationIndex());
  appStore.dispatch(actionSetPlaceClicked(null));

  if (place && needRequest.includes(place.cat_id)) {
    if (place.cat_id !== "poi_type:stations") {
      const type = place.cat_id.includes("bicycle_rental")
        ? "bss"
        : place.cat_id.includes("bicycle_parking")
        ? "bike_parking"
        : "parking";

      axios
        .get(`/api/availability?type=${type}&id=${place.id}`)
        .then((result) => {
          place.stand = result.data;
        })
        .catch((e) => {
          place.stand = {};
          const error = e.response && e.response.data ? e.response.data.id : e;

          console.warn(error);
        })
        .finally(() => {
          appStore.dispatch(actionSetPlaceClicked(place));
          appStore.dispatch(actionBuildTransportPlaces(pois));
        });
    } else {
      axios
        .get(`/api/stations?id=${place.id}`)
        .then((result) => {
          place.stand = result.data;
          appStore.dispatch(actionSetPlaceClicked(place));
        })
        .catch((e) => {
          place.stand = {};
          const error = e.response && e.response.data ? e.response.data.id : e;

          console.warn(error);
        });
    }
  } else {
    // TODO Avoid duplicate with the else case
    appStore.dispatch(actionSetPlaceClicked(place));
  }

  // Scrollto element
  setTimeout(() => {
    const scroll = document.querySelector("[data-lc-scroll='scroll']");
    const placeInfos = document.querySelector("[data-lc-place-infos]");

    // Avoid crash if there is no scroll element
    scroll && scroll.scrollTo(0, 0);

    if (placeInfos) {
      const scrollRect = scroll.getBoundingClientRect();
      const placeRect = placeInfos.getBoundingClientRect();

      scroll.scrollTo(0, placeRect.top - scrollRect.top - 25);
    }
  }, 150);

  if (thematic) {
    appStore.dispatch(actionGoToValid(true));
    appStore.dispatch(actionInputValueChange(place.name));
  }
};

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng];
};

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = (coord) => {
  const lon = coord.split(";")[0];
  const lat = coord.split(";")[1];

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lon, -90, 90));
};

/**
 * Try if a place should't be clusterised
 * @param place
 * @returns {boolean}
 */
export const isNotToClusterised = (place) => {
  return (
    place.cat_id === "poi_type:amenity:bicycle_rental" ||
    place.cat_id === "poi_type:stations" || // No cluster for VELO'V OR stations
    (history.location.pathname.includes("/p+r") &&
      (place.cat_id === "poi_type:amenity:parking" || place.cat_id === "poi_type:amenity:bicycle_parking"))
  ); // No cluster for p+r in thematics
};

export const isNotPlacesTabAround = (places) => {
  return history.location.pathname.includes("/around") && places;
};

/**
 *
 * @param object
 * @param type
 * @returns {Promise<*>}
 */
// TODO cf version leaflet for route-calculation
export const getCoordsFromUrlObject = async (object, type = null, component) => {
  const { pathname } = history.location;

  if (object.includes("stop_") || object.includes("poi:")) {
    const name = object.includes("stop_area")
      ? "areas"
      : object.includes("stop_point")
      ? "stops"
      : object.includes("sncf_stop_area")
      ? "stations"
      : "places";

    let findObj = component.props[name].find((i) => i.id === object);

    if (!findObj && object.includes("stop_area") && component.props["stations"]) {
      findObj = component.props["stations"].find((i) => i.id === object);
    }

    if (findObj) {
      if (pathname.includes("/route-calculation")) {
        if (type === "inputStart") {
          appStore.dispatch(actionInputStartValueChange(findObj));
        } else if (type === "inputEnd") {
          appStore.dispatch(actionInputEndValueChange(findObj));
        }

        component.createMarker(createCoords(findObj.coord.lat, findObj.coord.lon), type);
      } else {
        appStore.dispatch(actionGoToValid(true));
        appStore.dispatch(actionInputValueChange(findObj.name));
        return createCoords(findObj.coord.lat, findObj.coord.lon);
      }
    } else {
      throw new Error(`${object} not found`);
    }
  } else if (object.includes("admin:")) {
    const adminRegion = component.props.adminRegions.find((admin) => admin.id === object);

    if (adminRegion && pathname.includes("/route-calculation")) {
      if (type === "inputStart") {
        appStore.dispatch(actionInputStartValueChange(adminRegion));
      } else if (type === "inputEnd") {
        appStore.dispatch(actionInputEndValueChange(adminRegion));
      }

      component.createMarker(createCoords(adminRegion.coord.lat, adminRegion.coord.lon), type);
    } else {
      throw new Error(`${object} not found`);
    }
  } else {
    if (isCoords(object)) {
      const lat = object.split(";")[1];
      const lng = object.split(";")[0];
      let apiResponse = null;

      await axios
        .get("/api/geocoding", {
          params: {
            lat: lat,
            lng: lng,
          },
        })
        .then((response) => {
          apiResponse = response;
        })
        .catch((e) => {
          console.warn("Error : ", e.response && e.response.data && e.response.data.id);
          component.setState({
            error:
              e.response && e.response.data.id === "no-places"
                ? translate("around-error-no-places")
                : translate("around-error-unknow"),
          });
        })
        .finally(() => {
          if (pathname.includes("/route-calculation")) {
            if (type === "inputStart") {
              appStore.dispatch(actionInputStartValueChange(apiResponse ? apiResponse.data[0] : { name: "" }));
            } else if (type === "inputEnd") {
              appStore.dispatch(actionInputEndValueChange(apiResponse ? apiResponse.data[0] : { name: "" }));
            }

            component.createMarker(
              createCoords(
                apiResponse ? apiResponse.data[0].address.coord.lat : lat,
                apiResponse ? apiResponse.data[0].address.coord.lon : lng
              ),
              type
            );
          } else {
            appStore.dispatch(actionGoToValid(true));
            appStore.dispatch(actionInputValueChange(apiResponse ? apiResponse.data[0].name : ""));
          }
        });
      return createCoords(lat, lng);
    } else {
      appStore.dispatch(actionGoToValid(false));
      appStore.dispatch(actionInputValueChange(""));
    }
  }
};

export const tagOnShare = (network, itineraries = false) => {
  updateDataLayer({
    event: itineraries ? "map-itinerariesShare" : "map-itineraryShare",
    socialNetwork: network,
  });
  message({ clicked: "share", share: network });
};

/**
 * Translate a sentence with variables in it. Caution : if args are passed, the output will contains HTML !
 * @param {String} key key of line to translate
 * @param {...any} args Strings to replace
 */
export const translate = (key, renderHtml = true, ...args) => {
  const data = appStore.getState().app.languageFile;

  if (args?.length === 0) {
    return data[key];
  } else {
    try {
      return data[key].match(/(\$\w+)/g).reduce((acc, value) => {
        const arg = args.find((arg) => arg.key === value.replace("$", ""));

        // If we have an arg from the current value, replace with HTML content
        if (arg) {
          if (renderHtml) {
            acc = acc.replace(value, `<span class="lc-translate-${key}-${arg.key}">${arg.value}</span>`);
          } else {
            acc = acc.replace(value, arg.value);
          }
        } else {
          debug({ message: `Can't retrieve an arg value from ${value}` }, "warning", "Missing translation");
        }

        return acc;
      }, data[key]);
    } catch (e) {
      console.warn(`Error while translating ${key}`, e.message);
    }
  }
};

/**
 * Define if we need to use us system
 * @param language
 * @returns {boolean}
 */
export const isSystemUS = (language) => {
  return ["en"].includes(language);
};

export const navitiaDateToHoursMin = (date, language) => {
  const time = moment(date.split("T").pop(), "HHmmss");

  return isSystemUS(language) ? time.format("h:mm a") : time.format("HH:mm");
};

export const onTabSelected = (component, index) => {
  const { isMobile, openedCollapse, linesModes } = component.props;

  if (!index && index !== 0) {
    index = component.state.tab;
  }

  // Retrieve the most important group displayed and select it if it's not already done
  const group = mostImportantGroup(component.state.groups, linesModes);

  index === 0 && openedCollapse !== group && appStore.dispatch(actionSetOpenedCollapse(group));

  component.setState({ tab: index }, () => {
    appStore.dispatch(actionSetLineSelected(null));

    // Put scroll data attr on the current active tab & remove it to the other
    if (document.querySelector("[data-lc-scroll='scroll']")) {
      document.querySelector("[data-lc-scroll='scroll']").removeAttribute("data-lc-scroll");
    }

    document.querySelector(".lc-tab-panel.lc-active").setAttribute("data-lc-scroll", "scroll");

    resize(isMobile);
  });
};

export const storageAvailable = (type) => {
  try {
    const storage = window[type];
    const x = "__storage_test__";

    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const handleKeyPress = (event, callback) => {
  if (event.key === "Enter") {
    callback();
  }
};

export const addGetParam = (params, paramsToAdd) => {
  const string = new URLSearchParams({ ...params, ...paramsToAdd }).toString();

  return "?" + string;
};

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max;
